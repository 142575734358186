import { useEffect, useRef, useState } from "react";
import PageTitle from "../../components/common/PageTitle";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Stack,
  Tooltip,
} from "@mui/material";
import styles from "../../assets/styles/common/addEditProfile.styles";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import useValidateImageSize from "../../hooks/useValidateImageSize";
import FormInput from "../../components/forms/FormInput";
import LoadingButton from "../../components/button/LoadingButton";
import { useAtom } from "jotai";
import { loggedUserAtom } from "../../data/store";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { post } from "../../server";
import {
  CUSTOMER_PROFILE_UPDATE,
  ADMIN_CHANGE_EMAIL,
} from "../../data/constants/apiRoutes";
import { uploadImageType } from "../../data/constants/uploadImageTypes";
import awsFileUpload from "../../utils/helpers/awsFileUpload";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { Close } from "@mui/icons-material";
import {
  ChangeEmailSchema,
  ProfileSchema,
} from "../../utils/schemas/AdminProfileSchema";

import { validator } from "../../utils/helpers/validator";

const modalStyles = {
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { md: 400, xs: 310 },
    // height: 280,
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 15,
    p: 2,
  },
  iconBox: {
    width: 60,
    height: 60,
    borderRadius: "50%",
    textAlign: "center",
    border: "3px solid #E53935",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  crossIcon: {
    width: 40,
    height: 40,
    color: "#E53935",
  },
  closeIconBtnWrapper: {
    textAlign: "end",
  },
  closeIconBtn: {
    textAlign: "end",
    width: "40px !important",
    height: 40,
  },
  headerText: {
    fontSize: 18,
    fontWeight: 600,
    flexGrow: 1,
    textAlign: "center",
    color: "#636363",
  },
  confirmTitle: {
    color: "#838383",
    marginInline: "10px",
    fontSize: 14,
  },
  cancelBtn: {
    bgcolor: "#E53935",
    color: "#ffffff",
    height: 45,
    width: 120,
    boxShadow: "none",
    fontWeight: 600,
    textTransform: "capitalize",
    "&:hover": { bgcolor: "#E53935" },
  },
  confirmBtn: {
    bgcolor: "#D3A23B",
    color: "#ffffff",
    height: 45,
    textTransform: "capitalize",
    width: 120,
    boxShadow: "none",
    fontWeight: 600,
    "&:hover": {
      bgcolor: "#D3A23B",
    },
  },

  btnsWrapper: {
    mt: 4,
    mb: 2,
  },
};

const initialState = {
  first_name: "",
  last_name: "",
  email: "",
  image: null,
  password: null,
};

const emailInitialState = {
  email: "",
  password: "",
};

const EditProfile = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);

  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showEmailPassword, setShowEmailPassword] = useState(false);
  const [user, setUser] = useState(initialState);
  const [changeEmail, setChangeEmail] = useState(emailInitialState);
  const [errors, setErrors] = useState({});
  const [emailErrors, setEmailErrors] = useState({});

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const avatarRef = useRef();

  const { validateImageSize } = useValidateImageSize();

  const validateEmailForm = () => {
    let newErrors = validator(changeEmail, ChangeEmailSchema);
    setEmailErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };
  const validateForm = () => {
    let newErrors = validator(user, ProfileSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowEmailPassword = () => {
    setShowEmailPassword(!showEmailPassword);
  };

  const handleChangeEmail = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setChangeEmail({ ...changeEmail, [name]: value });
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUser({ ...user, [name]: value });
  };

  const handleAvatarClick = () => {
    avatarRef.current.click();
  };
  const handleAvatarChange = (e) => {
    const selectedFile = e.target.files[0];

    if (!validateImageSize(selectedFile)) {
      return;
    }

    setUser({ ...user, image: selectedFile });
  };

  const fetchAdminData = () => {
    const dataObject = {
      ...user,
      first_name: loggedUser?.data?.first_name || "",
      last_name: loggedUser?.data?.last_name || "",
      email: loggedUser?.data?.email || "",
      image: loggedUser?.data?.image || null,
    };
    setUser(dataObject);
  };

  useEffect(() => {
    if (loggedUser) {
      fetchAdminData();
    }
  }, [loggedUser?.data?.id]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setEmailErrors({});
    setChangeEmail(emailInitialState);
    setOpen(false);
  };

  const handleFormSubmit = async () => {
    let payload = _.cloneDeep(user);

    if (payload?.image && typeof payload?.image === "object") {
      const data = await awsFileUpload(
        payload.image,
        uploadImageType.adminProfile
      );
      if (data.status) {
        payload.image = data.location;
      } else {
        enqueueSnackbar("Error Uploading Image", { variant: "error" });
      }
    }

    const { status, message, data } = await post(
      CUSTOMER_PROFILE_UPDATE,
      payload
    );

    if (status) {
      setLoggedUser((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          first_name: payload.first_name,
          last_name: payload.last_name,
          email: payload.email,
          image: payload.image,
        },
      }));
      enqueueSnackbar(message, { variant: "success" });
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const mutation = useMutation(handleFormSubmit);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    mutation.mutate();
  };

  const handleEmailFormSubmit = async () => {
    const { status, message, data } = await post(
      ADMIN_CHANGE_EMAIL,
      changeEmail
    );

    if (status) {
      setLoggedUser((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          email: changeEmail.email,
        },
      }));
      setUser({
        ...user,
        email: changeEmail.email,
      });

      handleClose();
      enqueueSnackbar(message, { variant: "success" });
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const emailMutation = useMutation(handleEmailFormSubmit);

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    if (!validateEmailForm()) return;
    emailMutation.mutate();
  };

  return (
    <>
      <PageTitle title="Edit Profile" />
      <Box position="relative">
        <Avatar
          sx={styles.avatar}
          onClick={handleAvatarClick}
          src={
            user?.image
              ? typeof user?.image === "string"
                ? `${user?.image}`
                : URL.createObjectURL(user?.image)
              : null
          }
        >
          <CameraAltOutlinedIcon />
        </Avatar>
        <input
          type="file"
          accept="image/*"
          hidden
          onChange={handleAvatarChange}
          id="avatarUpload"
          ref={avatarRef}
        />
      </Box>
      <Box component="div" sx={styles.container}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormInput
              placeholder="First name"
              label="First name"
              type="text"
              name="first_name"
              value={user?.first_name}
              onChange={handleChange}
              error={errors?.first_name}
              helperText={errors?.first_name || ""}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormInput
              placeholder="Last name"
              label="Last name"
              type="text"
              name="last_name"
              value={user?.last_name}
              onChange={handleChange}
              error={errors?.last_name}
              helperText={errors?.last_name || ""}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Box className="emailDisabled">
              <FormInput
                disabled
                placeholder="Email"
                label="Email"
                type="email"
                name="email"
                value={user?.email}
                onChange={handleChange}
                error={errors?.email}
                helperText={errors?.email || ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Change Email" placement="top">
                        <IconButton onClick={handleOpen} edge="end">
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormInput
              label="Password"
              placeholder="Enter password"
              type={showPassword ? "text" : "password"}
              name="password"
              value={user.password}
              onChange={handleChange}
              error={errors?.password ? true : false}
              helperText={errors?.password || ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <LoadingButton
          variant="contained"
          sx={styles.button}
          disableElevation
          onClick={handleSubmit}
          isLoading={mutation.isLoading}
        >
          Save
        </LoadingButton>
      </Box>
      <Modal
        open={open}
        aria-describedby="modal-modal-description"
        size="small"
        onClose={handleClose}
      >
        <Box sx={modalStyles.container}>
          <Box sx={modalStyles.closeIconBtnWrapper}>
            <IconButton onClick={handleClose} sx={modalStyles.closeIconBtn}>
              <Close />
            </IconButton>
          </Box>

          <FormInput
            placeholder="Enter new email"
            label="New Email"
            type="email"
            name="email"
            value={changeEmail?.email}
            onChange={handleChangeEmail}
            error={emailErrors?.email}
            helperText={emailErrors?.email || ""}
          />

          <Box mt={2}>
            <FormInput
              label="Current Password"
              placeholder="Enter current password"
              type={showEmailPassword ? "text" : "password"}
              name="password"
              value={changeEmail.password}
              onChange={handleChangeEmail}
              error={emailErrors?.password ? true : false}
              helperText={emailErrors?.password || ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowEmailPassword}
                      edge="end"
                    >
                      {showEmailPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Stack
            direction="row"
            sx={modalStyles.btnsWrapper}
            justifyContent="center"
            spacing={2}
            textAlign="justify"
          >
            <Button
              variant="contained"
              sx={modalStyles.cancelBtn}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              sx={modalStyles.confirmBtn}
              onClick={handleEmailSubmit}
              isLoading={emailMutation.isLoading}
            >
              Save
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default EditProfile;
