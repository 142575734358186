import { useState } from "react";
import PageTitle from "../../../components/common/PageTitle";
import { Box, Typography } from "@mui/material";
import { PAGES } from "../../../data/constants/apiRoutes";
import { get } from "../../../server";
import { useSnackbar } from "notistack";
import Loader from "../../../components/Loader";
import { useQuery } from "react-query";
import queryNames from "../../../data/constants/queryNames";

const styles = {
  mainWrapper: {
    backgroundColor: "#F9FAFC",
    p: 4,
    minHeight: "100vh",
  },
  wrapper: {
    bgcolor: "#fff",
    borderRadius: "20px",
    p: 3,
    mt: 2,
  },
};

const PublicAboutUs = () => {
  const [aboutUs, setAboutUs] = useState({});

  const { enqueueSnackbar } = useSnackbar();

  const fetchPages = async () => {
    let { status, message, data } = await get(PAGES);
    if (status) {
      const aboutData = data?.find((item) => item?.pageType === "about_us");

      setAboutUs({
        ...aboutUs,
        title: aboutData?.title || "About Us",
        pageType: aboutData?.pageType || "about_us",
        description: aboutData?.description,
      });
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const allPagesQuery = useQuery(queryNames.PAGE, fetchPages, {
    refetchOnWindowFocus: false,
  });

  if (allPagesQuery.isFetching) {
    return <Loader />;
  }

  return (
    <Box sx={styles.mainWrapper}>
      <PageTitle title="About Us" />
      <Box sx={styles.wrapper}>
        <Typography
          dangerouslySetInnerHTML={{ __html: aboutUs?.description }}
        />
      </Box>
    </Box>
  );
};

export default PublicAboutUs;
