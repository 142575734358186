import { Box, Typography } from "@mui/material";

const TabPanel = ({ value, index, children, ...other }) => {
  return (
    <Box
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`form-tabpanel-${index}`}
      aria-labelledby={`form-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default TabPanel;
