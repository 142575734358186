import { Box } from "@mui/material";
import styles from "../../assets/styles/forms/formLabel.styles";

const FormLabel = ({ label, withasterisk, inputId }) => {
  return (
    <>
      {label ? (
        <Box component="label" sx={styles.label} htmlFor={inputId}>
          {label} {withasterisk ? <Box component="span">*</Box> : null}
        </Box>
      ) : null}
    </>
  );
};

export default FormLabel;
