import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Stack,
  Typography,
  Select,
  Tooltip,
} from "@mui/material";
import { ReactComponent as Search } from "../../../assets/svg/Search.svg";
import DataGridListing from "../../../components/listing/DataGridListing";
import { ReactComponent as Edit } from "../../../assets/svg/Edit.svg";
import { ReactComponent as Delete } from "../../../assets/svg/Delete.svg";
import { ReactComponent as Filter } from "../../../assets/svg/Filter.svg";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useState, useMemo } from "react";
import { get, destroy, put } from "../../../server";
import {
  ADMIN_COUSTOMER_LISTING,
  ADMIN_COUSTOMER_DELETE,
  ADMIN_COUSTOMER_CHANGE_STATUS,
  ADMIN_COUSTOMER_EXPORT,
} from "../../../data/constants/apiRoutes";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useMutation, useQuery, useQueryClient } from "react-query";
import queryNames from "../../../data/constants/queryNames";
import routes from "../../../data/constants/routes";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import moment from "moment";
import BlockIcon from "@mui/icons-material/Block";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BoxModal from "../../../components/modal/BoxModal";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import { Clear } from "@mui/icons-material";
import PageTitle from "../../../components/common/PageTitle";
import LoadingButton from "../../../components/button/LoadingButton";
import { loggedUserAtom } from "../../../data/store";
import { useAtom } from "jotai";
import axios from "axios";

const styles = {
  topStack: {
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "15px",
    "@media (max-width: 950px)": {
      flexWrap: "wrap",
    },
  },
  title: {
    fontSize: { md: 22, xs: 18 },
    fontWeight: 600,
    color: "#1E1E1F",
    flex: 1,
    textTransform: "capitalize",
  },
  searchInput: {
    maxWidth: 340,
    width: "100%",
    "& .MuiOutlinedInput-root": {
      px: "21px",
      backgroundColor: "#fff",
      minHeight: 35,
      borderRadius: "10px",
      "& fieldset": {
        border: "1px solid #EEE",
      },
      "&:hover fieldset": {
        borderColor: "#EEE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#656DFC",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #AEAEAE",
      borderRadius: "10px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#656DFC",
      borderWidth: "1px !important",
    },
    "& input": {
      backgroundColor: "#fff",
      height: "35px",
      borderRadius: "10px",
      fontWeight: 500,
      "&::placeholder": {
        color: "#B4B1B6",
        fontWeight: 400,
        opacity: 1,
        fontSize: 16,
      },
    },

    "& .MuiFormHelperText-root": {
      marginLeft: 0,
    },
  },

  filterBtn: {
    textTransform: "capitalize",
    bgcolor: "#135099",
    color: "#FFF",
    height: "50px",
    fontSize: 16,
    fontWeight: 600,
    minWidth: 150,
    px: 2,
    boxShadow: 0,
    borderRadius: "10px",
    "&:hover": {
      bgcolor: "#135099",
      color: "#FFF",
    },
  },

  exportBtn: {
    textTransform: "capitalize",
    bgcolor: "#135099",
    color: "#FFF",
    height: "50px",
    fontSize: 16,
    fontWeight: 600,
    minWidth: 180,
    px: 2,
    boxShadow: 0,
    borderRadius: "10px",
    "&:hover": {
      bgcolor: "#135099",
      color: "#FFF",
    },
  },

  filterContainer: {
    mt: "20px",
    position: "relative",
    bgcolor: "#FFF",
    pt: 3,
    pl: "27px",
    pb: "30px",
    pr: "53px",
    borderRadius: "20px",
    mb: "20px",
  },
  typesContainer: {
    bgcolor: "#F9FBFC",
    width: 280,
    px: 2,
    py: 1,
    borderRadius: "24px",
    mt: "14px",
    "& legend": {
      color: "#1E1E1F",
      textAlign: "center",
      fontSize: 18,
      py: 1,
      px: "20px",
      cursor: "pointer",
      borderRadius: "50px",
      width: "100%",
    },
  },
  activeType: {
    color: "#135099",
    bgcolor: "#FFF",
  },
  filterSerach: {
    mt: "20px",
    "& .MuiInputBase-root": {
      height: 54,
      borderRadius: "10px",
    },
  },

  exportMenu: {
    mt: "60px",
    "& .MuiPaper-root": {
      width: 200,
      borderRadius: "10px",
      boxShadow: "0px 0px 30px 0px rgba(0, 0, 0, 0.20)",
      color: "#313131",
      lineHeight: "26.53px",
    },
    "& .MuiList-root": {
      pt: "6px",
      pb: 0,
    },
  },
  dropDownMenu: {
    elevation: 0,
    sx: {
      overflow: "visible",
      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
      mt: 1.5,
      width: 200,
      "& .MuiAvatar-root": {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
      },
      "&:before": {
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: "background.paper",
        transform: "translateY(-50%) rotate(45deg)",
        zIndex: 0,
      },
    },
  },
  activeIcon: {
    fill: "#43b055",
  },
  deactiveIcon: {
    fill: "#edbf04",
  },
  select: {
    minWidth: { md: 200, xs: "100%" },
    borderRadius: "10px",
    height: 50,
    backgroundColor: "#fff",
    "& .MuiSelect-select": {
      backgroundColor: "transparent",
      fontWeight: 400,
      opacity: 1,
      fontSize: 14,
      color: "#202020",
      borderRadius: "10px",
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #656DFC",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
      borderColor: "#EEE ",
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
    },

    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#656DFC",
      borderWidth: "1px",
    },
    "& span": {
      color: "#9c9c9c",
    },
  },
};

const packageTypes = [
  {
    id: 1,
    name: "All Subscriptions",
    value: "",
  },
  {
    id: 2,
    name: "No Subscriptions",
    value: "noSubscriptions",
  },
  {
    id: 3,
    name: "Basic",
    value: "basic",
  },
  {
    id: 4,
    name: "Premium",
    value: "premium",
  },
  {
    id: 4,
    name: "Corporate",
    value: "corporate",
  },
];

const CustomersListing = () => {
  const [customers, setCustomers] = useState([]);
  const [id, setId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [packageType, setPackageType] = useState("");
  const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  const handleSelectPackageType = (e) => {
    setPackageType(e.target.value);
  };

  const filteredItems = useMemo(() => {
    let filtered = customers;

    if (filterText) {
      filtered = filtered?.filter((item) =>
        `${item?.first_name || ""} ${item?.last_name || ""} ${
          item?.email || ""
        }`
          .toLowerCase()
          .includes(filterText.toLowerCase())
      );
    }

    if (packageType != "") {
      if (packageType == "noSubscriptions") {
        filtered = filtered?.filter((item) => item?.package == null);
      } else {
        filtered = filtered?.filter(
          (item) => item?.package?.package_name.toLowerCase() === packageType
        );
      }
    }

    return filtered;
  }, [filterText, packageType, customers]);

  const fetchCustomers = async () => {
    let { status, message, data } = await get(ADMIN_COUSTOMER_LISTING);
    if (status) {
      setCustomers(data);
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const allCustomersQuery = useQuery(queryNames.CUSTOMER, fetchCustomers, {
    refetchOnWindowFocus: false,
  });

  const handleOpenDeleteModal = (id) => {
    setShowModal("delete");
    setId(id);
  };

  const handleStatusChange = (id, status) => {
    setShowModal(status);
    setId(id);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setId(null);
  };

  const client = useQueryClient();

  const handleDelete = async () => {
    const { status, message } = await destroy(
      `${ADMIN_COUSTOMER_DELETE}/${id}`
    );

    if (status) {
      enqueueSnackbar(message, { variant: "success" });

      handleCloseModal();
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const mutation = useMutation(handleDelete, {
    onSuccess: () => {
      client.invalidateQueries([queryNames.CUSTOMER]);
    },
  });

  const changeStatus = async () => {
    const { status, message } = await put(
      `${ADMIN_COUSTOMER_CHANGE_STATUS}/${id}`,
      {
        status: showModal,
      }
    );

    if (status) {
      enqueueSnackbar(message, { variant: "success" });

      handleCloseModal();
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const statusMutation = useMutation(changeStatus, {
    onSuccess: () => {
      client.invalidateQueries([queryNames.CUSTOMER]);
    },
  });

  const handleConfirm = () => {
    if (showModal === "delete") mutation.mutate();
    else statusMutation.mutate();
  };

  const handleDownloadFile = async () => {
    const url = ADMIN_COUSTOMER_EXPORT;
    const token = loggedUser.token;

    try {
      const response = await axios.get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const blob = new Blob([response.data], { type: response.data.type });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Customers.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      enqueueSnackbar("Excel file has been downloaded successfully", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("There was a problem with the axios operation:", {
        variant: "error",
      });
    }
  };

  const exportMutation = useMutation(handleDownloadFile);

  const handleClickExport = (e) => {
    e.preventDefault();
    exportMutation.mutate();
  };

  const columns = [
    {
      field: "name",
      headerName: "Member Name",
      sortable: false,
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <>
            <Avatar
              src={params.row.image}
              sx={{ mr: 1.5, width: 40, height: 40 }}
            >
              {params.row.first_name.charAt(0)}
            </Avatar>
            {`${params.row.first_name} ${params.row.last_name}`}
          </>
        );
      },
    },

    {
      field: "email",
      headerName: "Email",
      sortable: false,
      flex: 1,
    },
    {
      field: "subscription",
      headerName: "Subscription",
      sortable: false,
      flex: 1,
      valueGetter: (params) => params?.row?.package?.package_name || "",
    },

    {
      field: "updated_at",
      headerName: "Updated At",
      sortable: false,
      flex: 1,
      valueGetter: (params) =>
        moment(params.row.updated_at).format("D MMM YYYY, h:mm:ss A"),
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      flex: 1,
      valueGetter: (params) =>
        params.row.status === "active" ? "Active" : "Inactive",
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Stack direction="row" alignItems="center" gap={1}>
              <Tooltip title="Edit" placement="top">
                <IconButton
                  disableRipple
                  onClick={() =>
                    navigate(`${routes.ADMIN_CUSTOMERS_EDIT}/${params.row.id}`)
                  }
                  size="small"
                >
                  <Edit />
                </IconButton>
              </Tooltip>

              <Tooltip title="Delete" placement="top">
                <IconButton
                  disableRipple
                  onClick={() => handleOpenDeleteModal(params.row.id)}
                  size="small"
                >
                  <Delete />
                </IconButton>
              </Tooltip>

              {params.row.status === "active" ? (
                <Tooltip title="Deactivate" placement="top">
                  <IconButton
                    disableRipple
                    onClick={() =>
                      handleStatusChange(params.row.id, "inactive")
                    }
                    size="small"
                  >
                    <ToggleOffIcon sx={styles.deactiveIcon} />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Activate" placement="top">
                  <IconButton
                    disableRipple
                    onClick={() => handleStatusChange(params.row.id, "active")}
                    size="small"
                  >
                    <ToggleOnIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </>
        );
      },
    },
  ];

  if (allCustomersQuery.isFetching) {
    return <Loader />;
  }

  return (
    <>
      <Stack direction="row" flexWrap="wrap" sx={styles.topStack} mb={2}>
        <Typography component="h4" sx={styles.title} flex={1}>
          Customers
        </Typography>

        <Select
          value={packageType}
          sx={styles.select}
          onChange={handleSelectPackageType}
          renderValue={(selected) => {
            if (!selected) {
              return <span>All Subscriptions</span>;
            }

            const found = packageTypes.find(
              (single) => single.value === selected
            );

            return found?.name;
          }}
          displayEmpty
        >
          {packageTypes.map((type) => {
            return (
              <MenuItem value={type.value} key={type.id}>
                {type.name}
              </MenuItem>
            );
          })}
        </Select>

        <FormControl
          variant="outlined"
          value={filterText}
          onChange={handleFilter}
          sx={{ ...styles.searchInput, ...styles.topSearch }}
        >
          <OutlinedInput
            size="small"
            type="text"
            value={filterText}
            endAdornment={
              <InputAdornment position="end">
                {filterText.length > 0 ? (
                  <Tooltip title="Clear Search">
                    <IconButton onClick={() => setFilterText("")}>
                      <Clear />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Search />
                )}
              </InputAdornment>
            }
            placeholder="Search..."
          />
        </FormControl>

        <Stack direction="row" gap="15px">
          <Button
            variant="contained"
            sx={styles.filterBtn}
            onClick={() => navigate(routes.ADMIN_CUSTOMERS_ADD)}
          >
            Add
          </Button>
          <Button
            variant="contained"
            sx={[styles.filterBtn, { whiteSpace: "nowrap" }]}
            onClick={() => navigate(routes.ADMIN_CUSTOMERS_IMPORT)}
          >
            Import Customers
          </Button>
          <LoadingButton
            variant="contained"
            disableElevation
            sx={styles.exportBtn}
            onClick={handleClickExport}
            isLoading={exportMutation.isLoading}
          >
            Export Customers
          </LoadingButton>
        </Stack>
      </Stack>

      <DataGridListing rows={filteredItems} columns={columns} mode="client" />
      <BoxModal
        header="Are you sure?"
        title={
          showModal === "delete"
            ? "Do you really want to delete this record. This process cannot be undone."
            : showModal === "active"
            ? "Do you really want to activate this record."
            : "Do you really want to deactivate this record."
        }
        open={showModal === "active" ? "confirm" : showModal}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirm}
        confirmButton={showModal === "delete" ? "Delete" : "Confirm"}
        confirmButtonColor={showModal == "active" ? "#43a047" : "#E53935"}
        isLoading={statusMutation.isLoading}
      />
    </>
  );
};

export default CustomersListing;
