const styles = {
  newAccount: {
    color: "#1E1E1F",
    pt: "20px",
    textAlign: "center",
    "& a": {
      textDecoration: "none",
      color: "#1E1E1F",
      fontWeight: 600,
    },
  },
  forgetPassword: {
    alignItems: "flex-end",
    mt: { sm: "-10px", xs: "-10px" },
    "& .MuiFormControlLabel-root .MuiFormControlLabel-label": {
      fontWeight: 500,
      color: "#1E1E1F",
      fontSize: { md: 16, sm: 13, xs: 13 },
    },

    "& a": {
      color: "#1E1E1F",
      textDecoration: "none",
      fontWeight: 500,
      fontSize: { md: 16, sm: 13, xs: 13 },
    },
  },
  error: {
    fontSize: 14
  }
};

export default styles