import { useAtom } from "jotai";
import { loggedUserAtom } from "../../data/store";
import { useNavigate, Outlet } from "react-router-dom";
import routes from "../../data/constants/routes";

const CheckAdminAuth = () => {
	const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);
	const navigate = useNavigate();
    

	if (
		!loggedUser?.token &&
		(!loggedUser?.data || loggedUser.data?.is_superadmin === "0")
	) {
		navigate(routes.CUSTOMER_LOGIN);
	} else if(loggedUser?.token && loggedUser?.data?.is_superadmin === "0") {
        navigate(routes.UNAUTHORIZE)
    }
	return <Outlet />;
};

export default CheckAdminAuth;
