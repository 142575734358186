import { Box, Button, Modal, Slider, Stack } from "@mui/material";
import { useState } from "react";
import Cropper from "react-easy-crop";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { AddCircleOutlineOutlined } from "@mui/icons-material";

const styles = {
  modal: {
    bgcolor: "#FFF",
    borderRadius: "30px",
    position: "absolute",
    left: "50%",
    top: "50%",
    width: { md: 600, sm: "calc(100% - 20px)" },

    transform: "translate(-50%,-50%)",
    py: 8.5,
    px: { md: 9.75, xs: 2 },
    "& button": {
      borderRadius: "10px",
      bgcolor: "#135099",
      fontSize: 16,
      fontWeight: 600,
      width: "100%",
      py: 1,
      mt: 5,
      textTransform: "capitalize",
      "&:hover": {
        bgcolor: "#135099",
      },
    },
  },
  cropContainer: {
    height: "48vh",
    width: { md: 450, xs: 300 },
    position: "relative",
    overflow: "hidden",
  },
  sliderBox: {
    mb: 1,
    mt: 3,
    "& svg": {
      width: 26,
      height: 26,
      color: "#135099",
    },
  },
  slider: {
    color: "#135099",
  },
};

const CropAvatar = ({
  modal,
  setModal,
  image,
  user,
  setUser,
  cropType = "",
  aspectRatio,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const [croppedArea, setCroppedArea] = useState();

  const avatarAspectRatio = 4 / 4;
  const coverAspectRatio = 800 / 200;

  const handleClose = () => setModal(false);

  const handleCrop = async () => {
    const newImage = new Image();
    newImage.src = image;

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      return null;
    }

    const { width: bBoxWidth, height: bBoxHeight } = newImage;

    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    ctx.fillStyle = "rgba(0, 0, 0, 0)";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.scale(1, 1);
    ctx.translate(-newImage.width / 2, -newImage.height / 2);

    ctx.fillStyle = "rgba(0, 0, 0, 0)";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.drawImage(newImage, 0, 0);

    const data = ctx.getImageData(
      croppedArea.x,
      croppedArea.y,
      croppedArea.width,
      croppedArea.height
    );

    const croppedCanvas = document.createElement("canvas");
    const croppedCtx = croppedCanvas.getContext("2d");

    if (!croppedCtx) {
      return null;
    }

    croppedCanvas.width = croppedArea.width;
    croppedCanvas.height = croppedArea.height;

    croppedCtx.putImageData(data, 0, 0);

    const croppedImageDataURL = croppedCanvas.toDataURL("image/jpg");

    // const randomNum = Math.floor(1000 + Math.random() * 9000);
    let timestamp = new Date().getTime().toString();
    if (user?.uuid) {
      setUser({
        ...user,
        [modal]: base64ToFile(croppedImageDataURL, `${user?.uuid}.jpg`),
      });
    }
    setUser({
      ...user,
      [modal]: base64ToFile(croppedImageDataURL, `${timestamp}.jpg`),
    });

    setModal(false);
  };

  function base64ToFile(base64Image, fileName) {
    const byteString = atob(base64Image.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([ab], { type: "image/jpeg" });

    return new File([blob], fileName, { type: "image/jpeg" });
  }

  return (
    <Modal open={modal} onClose={handleClose} sx={styles}>
      <Box sx={styles.modal}>
        <Box sx={styles.cropContainer}>
          <Cropper
            // image="https://cdn.pixabay.com/photo/2016/07/07/16/46/dice-1502706__340.jpg"
            image={image}
            crop={crop}
            zoom={zoom}
            zoomSpeed={4}
            maxZoom={4}
            zoomWithScroll={true}
            showGrid={false}
            aspect={
              aspectRatio
                ? aspectRatio
                : cropType === "round"
                ? avatarAspectRatio
                : coverAspectRatio
            }
            onCropChange={setCrop}
            onZoomChange={setZoom}
            cropShape={cropType}
            onCropComplete={(croppedArea, croppedAreaPixels) => {
              setCroppedArea(croppedAreaPixels);
            }}
          />
        </Box>

        <Stack
          spacing={2}
          direction="row"
          sx={styles.sliderBox}
          alignItems="center"
        >
          <RemoveCircleOutlineOutlinedIcon />
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="zoom"
            onChange={(e, zoom) => setZoom(zoom)}
            sx={styles.slider}
          />
          <AddCircleOutlineOutlined />
          <Box component="div">{Math.round(zoom * 100)}%</Box>
        </Stack>

        <Button variant="contained" onClick={handleCrop}>
          Save
        </Button>
      </Box>
    </Modal>
  );
};

export default CropAvatar;
