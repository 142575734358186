import { useMemo, useState } from "react";
import styles from "../../../assets/styles/customer/teamsListing.styles";
import PageTitle from "../../../components/common/PageTitle";
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import FormInput from "../../../components/forms/FormInput";
import { Clear, MoreVertOutlined } from "@mui/icons-material";
import {
  CARDS_DELETE,
  TEAM_DETAIL,
  TEAM_REMOVE_MEMBER,
} from "../../../data/constants/apiRoutes";
import { ReactComponent as PlaceholderImage } from "../../../assets/svg/createCard/PlaceholderImage.svg";
import { useNavigate, useParams } from "react-router-dom";
import routes from "../../../data/constants/routes";
import RightBusinessDrawer from "../../../components/customer/RightBusinessDrawer";
import { styled } from "@mui/material/styles";
import { useAtom } from "jotai";
import { loggedUserAtom, mobileViewAtom } from "../../../data/store";
import SearchIcon from "@mui/icons-material/Search";
import { destroy, get, post } from "../../../server";
import { useQuery, useQueryClient } from "react-query";
import queryNames from "../../../data/constants/queryNames";
import Loader from "../../../components/Loader";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import { useSnackbar } from "notistack";
import { ReactComponent as RightArrow } from "../../../assets/svg/RightArrow.svg";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import QrCodeModel from "../../../components/modal/QrCodeModel";
import useFeature from "../../../hooks/useFeature";

const drawerWidth = 350;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open, mobileView }) => ({
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: mobileView ? 0 : -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

const TeamDetail = () => {
  const [members, setMembers] = useState([]);
  const [team, setTeam] = useState({});
  const [mobileView, setMobileView] = useAtom(mobileViewAtom);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentCard, setCurrentCard] = useState({});
  const [id, setId] = useState(null);
  const [modal, setModal] = useState(null);
  const { teamId } = useParams();
  const [filterText, setFilterText] = useState("");
  const [openQrModal, setOpenQrModal] = useState(false);
  const navigate = useNavigate();
  const { updateCount } = useFeature();

  const [loggedUser] = useAtom(loggedUserAtom);

  const { enqueueSnackbar } = useSnackbar();
  const client = useQueryClient();

  const menuOpen = Boolean(anchorEl);

  const handleClick = (e, card) => {
    setAnchorEl(e.currentTarget);
    setId(card.id);
    if (card.card) setCurrentCard(card.card);
  };

  const handleCardClick = (card) => {
    if (card.card) {
      setCurrentCard(card.card);
      handleDrawerOpen();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setId(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    setAnchorEl(null);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handlDeleteClick = () => {
    setModal("delete");
  };

  const handleLeaveTeamClick = () => {
    setModal("leave");
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  const handleInvalidate = () => {
    client.invalidateQueries(queryNames.TEAM_MEMBERS);
  };

  const handleDelete = async () => {
    const { status, message } = await destroy(
      `${CARDS_DELETE}/${currentCard.id}`
    );
    if (status === true) {
      enqueueSnackbar(message, { variant: "success" });
      handleInvalidate();
      // manage decrement card
      updateCount("delete");
      setId(null);
      setAnchorEl(null);
    }
    handleCloseModal();
    handleClose();
    setCurrentCard(null);
  };

  const handleLeave = async () => {
    const { status, message } = await post(TEAM_REMOVE_MEMBER, {
      team_id: teamId,
      user_id: id,
    });
    if (status === true) {
      enqueueSnackbar(message, { variant: "success" });
      handleInvalidate();
      setId(null);
    }
    handleCloseModal();
    handleClose();
  };

  const getListing = async () => {
    const { status, data } = await get(`${TEAM_DETAIL}/${teamId}`);
    if (status) {
      setTeam(data);
      const newMembers = data?.members?.map((member) => ({
        ...member,
        card: member?.card
          ? {
              ...member.card,
              cardPhones: member.card?.cardPhones?.map((phone) => ({
                ...phone,
                phone: phone.countryCode + phone.phone,
              })),
            }
          : null,
      }));

      setMembers(newMembers);
    }
  };

  const listingQuery = useQuery([queryNames.TEAM_MEMBERS], getListing, {
    refetchOnWindowFocus: false,
  });

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  const handleCancleSearch = () => {
    setFilterText("");
  };

  const filteredMembers = useMemo(() => {
    const filtered = members?.filter((item) =>
      `${item.first_name || ""} ${item.last_name || ""} ${item.email || ""}`
        .toLowerCase()
        .includes(filterText.toLowerCase())
    );

    return filtered;
  }, [filterText, members]);

  if (listingQuery.isLoading || listingQuery.isFetching) {
    return <Loader />;
  }

  return (
    <>
      <Stack direction="row">
        <Main open={open} mobileView={mobileView}>
          <Box component="div">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <Stack
                direction="row"
                alignItems="center"
                flexWrap={{ md: "nowrap", xs: "wrap" }}
                gap={2}
              >
                <Box sx={{ ...styles.profileWrapper, mt: 0 }}>
                  {team.logo ? (
                    <Box component="img" src={team.logo} />
                  ) : (
                    <PlaceholderImage />
                  )}
                </Box>
                <PageTitle title={team.name} />
              </Stack>

              <Box>
                <Stack
                  direction="row"
                  alignItems="center"
                  flexWrap={{ md: "nowrap", xs: "wrap" }}
                  gap={2}
                >
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <FormInput
                      placeholder="Search..."
                      value={filterText}
                      onChange={handleFilter}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {filterText.length > 0 ? (
                              <IconButton onClick={handleCancleSearch}>
                                <Clear />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <SearchIcon />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                </Stack>
              </Box>
            </Stack>
            <Box sx={styles.totalMemberWrapper}>
              <Typography>Members: {filteredMembers?.length}</Typography>
            </Box>
            <Box sx={styles.gridWrapper}>
              {members &&
                filteredMembers?.map((card) => (
                  <Box
                    sx={styles.card}
                    key={card.id}
                    // onClick={() => handleCardClick(card)}
                  >
                    <Box>
                      <Box sx={styles.profileWrapper}>
                        {card.logo ? (
                          <Box component="img" src={card.logo} />
                        ) : (
                          <PlaceholderImage />
                        )}
                      </Box>
                    </Box>
                    {card?.card ? (
                      <>
                        <IconButton
                          sx={styles.menuBtn}
                          onClick={(e) => handleClick(e, card)}
                          aria-controls={menuOpen ? "menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={menuOpen ? "true" : undefined}
                        >
                          <MoreVertOutlined />
                        </IconButton>

                        <Menu
                          anchorEl={anchorEl}
                          id="menu"
                          open={menuOpen}
                          onClose={handleClose}
                          // onClick={handleClose}
                          PaperProps={{
                            elevation: 0,
                            sx: styles.menu,
                          }}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <MenuItem onClick={handleDrawerOpen}>
                            View Card
                          </MenuItem>

                          <Divider />
                          <MenuItem onClick={() => setOpenQrModal(true)}>
                            Download QR
                          </MenuItem>
                        </Menu>
                      </>
                    ) : null}

                    <Typography component="h3">
                      {card?.first_name} {card?.last_name}
                    </Typography>
                    <Typography component="p">{card.email}</Typography>
                    {!card?.card && (
                      <Tooltip title="No Card">
                        <NoAccountsIcon sx={styles.noCard} />
                      </Tooltip>
                    )}
                    {card.id === team.ownerData.id && (
                      <Typography component="p">Owner</Typography>
                    )}
                  </Box>
                ))}
            </Box>
          </Box>
          <Button
            variant="contained"
            sx={[styles.btn, styles.removeBtn]}
            disableElevation
            onClick={() => navigate(routes.ADMIN_TEAMS)}
          >
            <RightArrow className="mr-1" />
            Back
          </Button>
        </Main>

        <RightBusinessDrawer
          open={open}
          inViewMode={true}
          handleDrawerClose={handleDrawerClose}
          handleOpenDelete={handlDeleteClick}
          type="member"
          {...currentCard}
          showActionBtn={false}
        />
      </Stack>

      <QrCodeModel
        open={openQrModal}
        handleClose={() => setOpenQrModal(false)}
        uuid={currentCard?.uuid}
        personName={currentCard?.fullName}
      />

      <ConfirmationModal
        header="Are you sure?"
        title={
          modal === "delete"
            ? "Do you really want to delete member's card? This process cannot be undone."
            : "Do you really want to this member from the team? This process cannot be undone."
        }
        confirmButton={modal === "delete" ? "Delete" : "Remove"}
        confirmButtonColor="#E53935"
        open={modal}
        handleClose={handleCloseModal}
        handleConfirm={modal === "delete" ? handleDelete : handleLeave}
      />
    </>
  );
};
export default TeamDetail;
