import React, { useImperativeHandle, useRef } from "react";
import PageTitle from "../../../components/common/PageTitle";
import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { ReactComponent as MasterCard } from "../../../assets/svg/Cards/Master Card.svg";
import { ReactComponent as Paypal } from "../../../assets/svg/Cards/Paypal.svg";
import AmericanExpress from "../../../assets/svg/Cards/American Express.png";
import FormLabelInput from "../../../components/forms/FormLabelInput";
import { ReactComponent as Stripe } from "../../../assets/svg/Cards/Stripe.svg";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  PaymentElement,
  useElements,
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";

const stripePromise = loadStripe(
  "pk_test_51NFxN3IK5YoPZWUo4kDdz6yTdDs7ewFFSGrIYUiuOIH4rIGLhylWm9qhcD2JJwhc9ELmVW0BqKBxrLRQnj2mSKMr00x3QuMd2X"
);

const cardElementOptions = {
  // hidePostalCode: true,
};

const styles = {
  container: {
    bgcolor: "#FFF",
    borderRadius: "15px",
    py: 7,
    px: 5,
    "& button": {
      bgcolor: "#135099",
      color: "#FFF",
      borderRadius: 2.5,
      fontSize: 16,
      width: "100%",
      py: 1.5,
      textTransform: "capitalize",
      "&:hover": {
        bgcolor: "#135099",
        color: "#FFF",
      },
    },
  },
  masterCard: {
    bgcolor: "#f6ecd8",
    borderRadius: "10px",
    py: 2,
    px: 2.5,
    maxWidth: 500,
    alignItems: "center",
    cursor: "pointer",
    "& label": {
      color: "#000",
      fontSize: 15,
      fontWeight: 600,
    },
  },
  paypalCard: {
    bgcolor: "#F5FAFF",
    borderRadius: "10px",
    py: 2,
    px: "20px",
    maxWidth: 500,
    alignItems: "center",
    cursor: "pointer",
    "& label": {
      color: "#000",
      fontSize: 15,
      fontWeight: 600,
    },
    "& img": {
      width: 40,
      height: 40,
    },
    "& svg": {
      mr: 1.5,
    },
  },
};

const AddCard = () => {
  return (
    <>
      <PageTitle title="Add Card" />

      <Divider sx={{ borderColor: "#EBEBEE", my: 3 }} />

      <Box sx={styles.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} sx={{ borderRight: "1px solid #CCC" }}>
            {/* <Box sx={styles.masterCard}>
							<Stack direction="row" gap="12px">
								<MasterCard />
								<Typography component="label">Master Card</Typography>
							</Stack>
						</Box>
 */}
            {/* <Box sx={styles.paypalCard} my={"20px"}>
							<Stack direction="row" gap="12px">
								<Box component="img" src={AmericanExpress} />
								<Typography component="label">American Express</Typography>
							</Stack>
						</Box> */}

            <Box sx={styles.paypalCard} mr={2}>
              <Stack direction="row" gap="12px">
                <Stripe />
                <Typography component="label">Stripe</Typography>
              </Stack>
            </Box>
          </Grid>

          {/* <Grid item xs={12} md={6}>
						<Grid container spacing="20px" mb="30px">
							<Grid item md={12} xs={12}>
								<FormLabelInput label="Card Holder Name*" type="text" />
							</Grid>

							<Grid item md={12} xs={12}>
								<FormLabelInput label="Card Number*" type="number" />
							</Grid>

							<Grid item md={6} xs={12}>
								<FormLabelInput label="Expiry Date" />
							</Grid>

							<Grid item md={6} xs={12}>
								<FormLabelInput label="CVV" type="number" />
							</Grid>
						</Grid>

					</Grid> */}

          <Grid item xs={12} md={6}>
            <Elements stripe={stripePromise}>
              <CheckoutForm />
            </Elements>
            <Button variant="contained">Continue</Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const StripeInput = ({ component: Component, inputRef, ...props }) => {
  const elementRef = useRef();
  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current.focus,
  }));
  return (
    <Component
      onReady={(element) => (elementRef.current = element)}
      {...props}
    />
  );
};

const StripeCardNumberElement = () => {
  return (
    <FormLabelInput
      // label="Card Number"
      InputProps={{
        inputComponent: StripeInput,
        inputProps: {
          component: CardNumberElement,
        },
      }}
    />
  );
};

const StripeCardExpiryElement = () => {
  return (
    <FormLabelInput
      // label="Expiration"
      InputProps={{
        inputComponent: StripeInput,
        inputProps: {
          component: CardExpiryElement,
        },
      }}
    />
  );
};

const StripeCardCvcElement = () => {
  return (
    <FormLabelInput
      // label="CVC"
      InputProps={{
        inputComponent: StripeInput,
        inputProps: {
          component: CardCvcElement,
        },
      }}
    />
  );
};

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  return (
    <>
      <Box sx={{ width: "100%" }}>
        {/* <CardElement options={cardElementOptions} /> */}
        <Grid container spacing="20px" mb="30px">
          <Grid item md={12} xs={12}>
            <StripeCardNumberElement />
          </Grid>
          <Grid item md={6} xs={12}>
            <StripeCardExpiryElement />
          </Grid>
          <Grid item md={6} xs={12}>
            <StripeCardCvcElement />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AddCard;
