import { useState } from "react";
import SplashForm from "../../../components/auth/SplashForm";
import routes from "../../../data/constants/routes";
import { useMutation } from "react-query";
import { validator } from "../../../utils/helpers/validator";
import { ForgetPasswordSchema } from "../../../utils/schemas/AuthSchema";
import { CUSTOMER_FORGET_PASSWORD } from "../../../data/constants/apiRoutes";
import { post } from "../../../server";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { loggedUserAtom } from "../../../data/store";
import FormInput from "../../../components/forms/FormInput";

const ResetPassword = () => {
	const [resetPasswordForm, setResetPasswordForm] = useState({
		email: "",
	});
	const [errors, setErrors] = useState({});

	const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;

		setResetPasswordForm({ ...resetPasswordForm, [name]: value });
	};

	const validateForm = () => {
		let newErrors = validator(resetPasswordForm, ForgetPasswordSchema);
		setErrors(newErrors);
		if (Object.keys(newErrors).length) return false;
		return true;
	};

	const handleResetPassword = async () => {
		const { status, message, data } = await post(
			CUSTOMER_FORGET_PASSWORD,
			resetPasswordForm
		);
		if (status === true) {
			setLoggedUser(data);
			enqueueSnackbar(message, { variant: "success" });
			navigate(routes.CUSTOMER_VERIFY_OTP);
		} else {
			enqueueSnackbar("There is no account associated with this email", { variant: "error" });
		}
	};

	const mutation = useMutation(handleResetPassword);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!validateForm()) return;
		mutation.mutate();
	};

	return (
		<>
			<SplashForm
				title="Forgot Password"
				tagline="Enter the email address associated with your account and we'll send you a OTP code to reset your password!"
				btnText="Send"
				handleSubmit={handleSubmit}
				isLoading={mutation.isLoading}
				linkTaglinePlainText="Not registered yet? "
				linkTaglineText="Create An Account"
				linkTaglineHref={routes.CUSTOMER_SIGNUP}
			>
				<>
					<FormInput
						type="email"
						name="email"
						value={resetPasswordForm.email}
						onChange={handleChange}
						placeholder="Email"
						error={errors?.email}
						helperText={errors?.email || ""}
						onKeyDown={(e) => {
							if (e.key === "Enter") handleSubmit(e);
						}}
					/>
				</>
			</SplashForm>
		</>
	);
};

export default ResetPassword;
