import MuiPagination from "@mui/material/Pagination";

const styles = {
	pagination: {
		mt: 4,
		"& .MuiPagination-ul": {
			justifyContent: "center",
		},
		"& .MuiPaginationItem-root": {
			backgroundColor: "transparent", // Add background color to all page numbers
			border: "none",
			margin: "0 10	px",
			color: "#000",
			// fontSize: 17,
			"&:hover": {
				backgroundColor: "transparent",
			},
		},
		"&  .MuiPaginationItem-previousNext": {
			// width: 40,
			// height: 40,
			backgroundColor: "#2B2A69",
			color: "#FFF",
			"&:hover": {
				backgroundColor: "#2B2A69",
				color: "#FFF",
			},
			"&.Mui-disabled": {
				backgroundColor: "#FFF",
				color: "#B8B8B8",
				border: "1px solid #AEAEAE",
			},
			"& svg": {
				// height: 25,
				// width: 25,
			},
		},
		"& .MuiPaginationItem-page.Mui-selected": {
			color: "#293277",
		},
	},
};

const Pagination = ({
	paginationModel,
	setPaginationModel,
	totalCount,
}) => {
	function PaginationComp({ page, onPageChange, count, limit }) {
		return (
			<MuiPagination
				page={page + 1}
				count={Math.ceil(count / limit)}
				onChange={(event, newPage) => {
					onPageChange(event, newPage - 1);
				}}
				variant="outlined"
				shape="rounded"
				sx={styles.pagination}
			/>
		);
	}

	return (
		<PaginationComp
			page={paginationModel.page}
			onPageChange={(event, newPage) =>
				setPaginationModel({ ...paginationModel, page: newPage })
			}
			count={totalCount}
			limit={paginationModel.pageSize}
		/>
	);
};

export default Pagination;
