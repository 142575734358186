import React from "react";
import { FormHelperText, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const styles = {
  error: {
    "& p": {
      fontSize: 14,
      marginLeft: 0,
    },

    "& .MuiFilledInput-root": {
      border: "1px solid #d32f2f",
      borderRadius: "10px",
    },
  },
};

const InputField = styled((props) => (
  <TextField
    fullWidth
    autoComplete="off"
    autoSave="off"
    InputProps={{ disableUnderline: true }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiInputLabel-root": {
    color: "#B4B1B6",
    textTransform: "capitalize",
  },
  "& .MuiFilledInput-root:before": {
    border: "0px !important",
  },
  "& .MuiFilledInput-root:after": {
    border: "0px !important",
  },
  "& .MuiFilledInput-root.Mui-disabled": {
    backgroundColor: "#fff",
    fontWeight: 800,
  },
  "& .MuiFilledInput-input.Mui-disabled": {
    "-webkit-text-fill-color": "rgba(0, 0, 0, 0.70)",
  },

  "& .MuiFilledInput-root": {
    overflow: "hidden",
    borderRadius: 10,
    backgroundColor: "transparent",
    color: "#1E1E1F",
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#EEE" : "#2D3843",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&.Mui-focused": {
      backgroundColor: "transparent",
      boxShadow: "none",
      borderColor: theme.palette.primary.main,
    },
  },
}));

function CustomInputField({ ...other }) {
  return (
    <>
      <InputField
        {...other}
        variant="filled"
        sx={{
          ...(other.error && {
            ...styles.error,
          }),
        }}
      />
    </>
  );
}

export default CustomInputField;
