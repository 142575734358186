import Joi from "joi";

export const CategorySchema = Joi.object({
    name: Joi.string().required().messages({
        "string.empty": "Category name is required",
        "any.required": "Category name is required",
    }),
    description: Joi.string().max(2000).optional().allow("").messages({
        "string.max": "Category description should be less than 2000 characters",
    }),
});