import { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
// import Footer from "./Footer";
import { Box } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import FeatureModal from "../components/modal/FeatureModal";
import { useAtom } from "jotai";
import { featureModal, loggedUserAtom } from "../data/store";
import routes from "../data/constants/routes";

const drawerWidth = 280;
const styles = {
  main: {
    backgroundColor: "#F9FAFC",
    minHeight: "calc(100vh - 64px)",
    p: { md: 3, xs: "25px 16px" },
    mt: { md: 6.5, xs: 5.5 },
    flexGrow: 1,
  },
};

function DashboardLayout() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [globalFeatureModal, setGlobalFeatureModal] = useAtom(featureModal);
  const [openModal, setModalOpen] = useState(false);
  const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleClose = () => {
    setGlobalFeatureModal(false);
  };

  useEffect(() => {
    if (
      Object?.keys(loggedUser)?.length > 0 &&
      loggedUser?.data?.is_password_otp_verified > 0
    ) {
      window.onpopstate = () => {
        navigate(routes.CUSTOMER_PROFILE);
      };
    }
  }, [pathname, navigate, loggedUser?.data?.id]);

  useEffect(() => {
    if (globalFeatureModal == true) {
      // enqueueSnackbar("The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features", { variant: "error" });
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  }, [globalFeatureModal]);

  return (
    <>
      <Sidebar
        drawerWidth={drawerWidth}
        mobileOpen={mobileOpen}
        setMobileOpen={setMobileOpen}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: "auto",
          // "@media (max-width: 720px)": {
          //   width:  `calc(100% - ${drawerWidth}px)` ,
          // }
        }}
      >
        <Header
          drawerWidth={drawerWidth}
          mobileOpen={mobileOpen}
          setMobileOpen={setMobileOpen}
        />
        <Box sx={styles.main}>
          <Outlet />
        </Box>
        {/* <Footer drawerWidth={drawerWidth} /> */}
      </Box>
      <FeatureModal
        header="Feature Not Available"
        title={`The requested feature is not compatible with your current plan. Upgrade your plan to Corporate Package to continue using these features`}
        confirmButton="Confirm"
        confirmButtonColor="#E53935"
        open={openModal}
        handleClose={handleClose}
        // handleConfirm={""}
      />
    </>
  );
}

export default DashboardLayout;
