import { Avatar, Box, Button, Grid, Stack } from "@mui/material";
import { useRef, useState, useEffect } from "react";
import ProfileBanner from "../../../assets/svg/ProfileBanner.svg";
import { ReactComponent as UploadPic } from "../../../assets/svg/UploadPic.svg";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import FormInput from "../../../components/forms/FormInput";
import PageTitle from "../../../components/common/PageTitle";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import queryNames from "../../../data/constants/queryNames";
import LoadingOverlay from "../../../components/overlay/LoadingOverlay";
import CropAvatar from "../../../components/modal/CropAvatar";
import { get, post } from "../../../server";
import {
  CUSTOMER_PROFILE_GET,
  CUSTOMER_PROFILE_UPDATE,
  GET_IMAGE,
} from "../../../data/constants/apiRoutes";
import routes from "../../../data/constants/routes";
import { validator } from "../../../utils/helpers/validator";
import UserSchema from "../../../utils/schemas/UserSchema";
import LoadingButton from "../../../components/button/LoadingButton";
import _ from "lodash";
import uploadFile from "../../../utils/helpers/uploadFile";
import styles from "../../../assets/styles/common/addEditProfile.styles";
import PhoneNumberInput from "../../../components/forms/PhoneNumberInput";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import { loggedUserAtom } from "../../../data/store";
import { useAtom } from "jotai";
import awsFileUpload from "../../../utils/helpers/awsFileUpload";
import { uploadImageType } from "../../../data/constants/uploadImageTypes";
import useValidateImageSize from "../../../hooks/useValidateImageSize";

const initialState = {
  first_name: "",
  last_name: "",
  email: "",
  image: null,
  //   phone: "",
  //   company: "",
  //   position: "",
  //   address: "",
  //   about: "",
  //   linkedin: "",

  //   cover_image: null,
};

const AddEditProfile = () => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(initialState);
  const { id } = useParams();
  const { validateImageSize } = useValidateImageSize();

  const [profilePhoto, setProfilePhoto] = useState(null);
  const [coverPhoto, setCoverPhoto] = useState(null);

  const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);

  const avatarRef = useRef();
  const coverRef = useRef();

  const [errors, setErrors] = useState({});

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const handleCoverClick = () => {
    coverRef.current.click();
  };

  const handleAvatarClick = () => {
    avatarRef.current.click();
  };

  const handleAvatarChange = (e) => {
    const selectedFile = e.target.files[0];

    if (!validateImageSize(selectedFile)) {
      return;
    }
    // const selectedFile = URL.createObjectURL(e.target.files[0]);
    // setProfilePhoto(selectedFile);
    // setModal("image");
    setUser({ ...user, image: selectedFile });
  };

  const handleCoverChange = (e) => {
    // const selectedFile = URL.createObjectURL(e.target.files[0]);
    // setCoverPhoto(selectedFile);
    // setModal("cover_image");
    setUser({ ...user, cover_image: e.target.files[0] });
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUser({ ...user, [name]: value });
  };

  const fetchProfile = async () => {
    setLoading(true);
    const { status, message, data } = await get(CUSTOMER_PROFILE_GET);
    if (status === true) {
      setUser(data);
    } else enqueueSnackbar(message, { variant: "error" });
    setLoading(false);
  };

  const userQuery = useQuery([queryNames.PROFILE], fetchProfile, {
    refetchOnWindowFocus: false,
  });

  const validateForm = () => {
    let newErrors = validator(user, UserSchema);
    setErrors(newErrors);

    if (Object.keys(newErrors).length) return false;

    return true;
  };

  const handleFormSubmit = async () => {
    let payload = _.cloneDeep(user);

    if (payload?.image && typeof payload?.image === "object") {
      const data = await awsFileUpload(payload.image, uploadImageType.profile);
      if (data.status) {
        payload.image = data.location;
      } else {
        enqueueSnackbar("Error Uploading Image", { variant: "error" });
      }
    }

    // if (typeof payload.image !== "string") {
    //   const { status, message } = await uploadFile(payload.image, "image");
    //   if (status !== true) enqueueSnackbar(message, { variant: "error" });
    // }
    // if (typeof payload.cover_image !== "string") {
    //   const { status, message } = await uploadFile(
    //     payload.cover_image,
    //     "cover"
    //   );
    //   if (status !== true) enqueueSnackbar(message, { variant: "error" });
    // }

    const newPayload = {
      first_name: payload.first_name,
      last_name: payload.last_name,
      email: payload.email,
      image: payload.image,
    };
    const { status, message, data } = await post(
      CUSTOMER_PROFILE_UPDATE,
      newPayload
    );

    if (status) {
      setLoggedUser({
        ...loggedUser,
        data,
      });
      enqueueSnackbar(message, { variant: "success" });
      navigate(routes.CUSTOMER_PROFILE);
    } else {
      // enqueueSnackbar(message, { variant: "error" });
    }
  };

  const mutation = useMutation(handleFormSubmit);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    mutation.mutate();
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle title={id ? "Edit Profile" : "Create Profile"} />
      {/* <LoadingOverlay loading={userQuery.isLoading} /> */}

      <Box position="relative">
        {/* <Box sx={styles.banner} rowGap="16px" onClick={handleCoverClick}>
          <Box
            component="img"
            src={
              user?.cover_image
                ? typeof user?.cover_image === "string"
                  ? `${GET_IMAGE}/${user?.cover_image}`
                  : URL.createObjectURL(user?.cover_image)
                : ProfileBanner
            }
            sx={styles.bannerImg}
          />
          {!user?.cover_image && (
            <Stack sx={styles.uploadStack}>
              <UploadPic />
              <Box component="label">Upload cover photo</Box>
            </Stack>
          )}
        </Box> */}

        <Avatar
          sx={styles.avatar}
          onClick={handleAvatarClick}
          src={
            user?.image
              ? typeof user?.image === "string"
                ? `${user?.image}`
                : URL.createObjectURL(user?.image)
              : null
          }
        >
          <CameraAltOutlinedIcon />
        </Avatar>
        <input
          type="file"
          accept="image/*"
          hidden
          onChange={handleAvatarChange}
          id="avatarUpload"
          ref={avatarRef}
        />
      </Box>

      <Box component="div" sx={styles.container}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormInput
              placeholder="First name"
              label="First name"
              type="text"
              name="first_name"
              value={user?.first_name}
              onChange={handleChange}
              error={errors?.first_name}
              helperText={errors?.first_name || ""}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormInput
              placeholder="Last name"
              label="Last name"
              type="text"
              name="last_name"
              value={user?.last_name}
              onChange={handleChange}
              error={errors?.last_name}
              helperText={errors?.last_name || ""}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormInput
              placeholder="Email"
              label="Email"
              type="email"
              name="email"
              value={user?.email}
              onChange={handleChange}
              error={errors?.email}
              helperText={errors?.email || ""}
            />
          </Grid>
          {/* 
          <Grid item xs={12} md={4}>
            <PhoneNumberInput
              error={errors?.mobileNumber ? true : false}
              value={user.phone}
              helperText={errors?.phone || ""}
              onChange={(value) => {
                setUser({
                  ...user,
                  phone: value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <FormInput
              placeholder="Company"
              type="text"
              name="company"
              value={user?.company}
              onChange={handleChange}
              error={errors?.company}
              helperText={errors?.company || ""}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <FormInput
              placeholder="Position"
              type="text"
              name="position"
              value={user?.position}
              onChange={handleChange}
              error={errors?.position}
              helperText={errors?.position || ""}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <FormInput
              placeholder="Linked In"
              type="text"
              name="linkedin"
              value={user?.linkedin}
              onChange={handleChange}
              error={errors?.linkedin}
              helperText={errors?.linkedin || ""}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <FormInput
              placeholder="Address"
              type="text"
              name="address"
              value={user?.address}
              onChange={handleChange}
              error={errors?.address}
              helperText={errors?.address || ""}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <FormInput
              placeholder="Bio"
              type="textarea"
              multiline
              rows={3}
              name="about"
              value={user?.about}
              onChange={handleChange}
              error={errors?.about}
              helperText={errors?.about || ""}
            />
          </Grid> */}
        </Grid>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <LoadingButton
          variant="contained"
          sx={styles.button}
          disableElevation
          onClick={handleSubmit}
        >
          {id ? "Save" : "Create Profile"}
        </LoadingButton>
      </Box>

      <CropAvatar
        modal={modal}
        setModal={setModal}
        image={modal === "image" ? profilePhoto : coverPhoto}
        cropType={modal === "image" ? "round" : ""}
        user={user}
        setUser={setUser}
      />
    </>
  );
};

export default AddEditProfile;
