import { TextField } from "@mui/material";
import styles from "../../assets/styles/forms/placeholderinput.styles";
import { styled } from "@mui/material/styles";

const NewTextField = styled((props) => (
	<TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
	"& label": {
		transform: "translate(18px, 22px) scale(1)",
	},
	"& .MuiInputLabel-shrink": {
		transform: "translate(18px, 5px) scale(1)",
		fontSize: 14,
	},
	"& .MuiFilledInput-root": {
		overflow: "hidden",
		borderRadius: 10,
		backgroundColor: "#FFF",
		// border: "1px solid",
		// borderColor: "#EEE",
		minHeight: 66,
		transition: theme.transitions.create([
			"border-color",
			"background-color",
			"box-shadow",
		]),
		"& input": {
			px: "18px",
		},
		"&:hover": {
			backgroundColor: "transparent",
		},
		"&.Mui-focused": {
			backgroundColor: "transparent",
			borderColor: "#656DFC",
		},
		"&:after": {
			border: "1px solid #656DFC",
		},
	},
}));

const FormLabelInput = ({ ...other }) => {
	const inputId = `input-${other.name}`;

	return (
		<>
			<NewTextField
				id={inputId}
				fullWidth
				{...other}
				size="small"
				autoComplete="off"
				autoSave="off"
				variant="filled"
				InputLabelProps={{
					style: {
						color: "#B4B1B6",
					},
				}}
				sx={{
					...styles.input,
					...(other.error && {
						...styles.error,
					}),
					...other.sx,
				}}
			/>
		</>
	);
};

export default FormLabelInput;
