import { useMemo, useState } from "react";
import { ReactComponent as RightArrow } from "../../../assets/svg/RightArrow.svg";
import PageTitle from "../../../components/common/PageTitle";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { ReactComponent as Delete } from "../../../assets/svg/Delete.svg";
import styles from "../../../assets/styles/importCustomer.syles";
import * as XLSX from "xlsx";
import { useSnackbar } from "notistack";
import LoadingButton from "../../../components/button/LoadingButton";
import { ADMIN_COUSTOMER_IMPORT } from "../../../data/constants/apiRoutes";
import { post } from "../../../server";
import { useNavigate } from "react-router-dom";
import routes from "../../../data/constants/routes";
import { useMutation } from "react-query";
import { FileUploader } from "react-drag-drop-files";
import ExcelJS from "exceljs";
import LoadingBackDrop from "../../../components/overlay/LoadingBackDrop";

const fileTypes = ["XLSX", "XLS", "CSV"];

const rowsPerPage = 50;

const dummyUsers = [
  {
    id: 1,
    firstName: "John",
    lastName: "Doe",
    email: "john@example.com",
  },
  {
    id: 2,
    firstName: "Jane",
    lastName: "Smith",
    email: "jan.smith@example.com",
  },
];

const ImportCustomersFromExcel = () => {
  const [usersData, setUsersData] = useState([]);
  const [page, setPage] = useState(1);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleFileChange = (newFile) => {
    readExcel(newFile);
  };

  const readExcel = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const binaryStr = event.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      // Check for required columns
      const requiredColumns = ["First Name", "Last Name", "Email"];
      const sheetColumns = jsonData[0];

      const missingColumns = requiredColumns.filter(
        (column) => !sheetColumns.includes(column)
      );

      const extraColumns = sheetColumns.filter(
        (column) => !requiredColumns.includes(column)
      );

      if (missingColumns.length > 0) {
        enqueueSnackbar(
          `Missing required columns: ${missingColumns.join(", ")}`,
          {
            variant: "error",
          }
        );
        return;
      }

      if (extraColumns.length > 0) {
        enqueueSnackbar(`Extra columns found: ${extraColumns.join(", ")}`, {
          variant: "error",
        });
        return;
      }

      // Remove header row
      jsonData.shift();

      // Convert jsonData to objects
      const formattedData = jsonData.map((row) => {
        return {
          "First Name": row[sheetColumns.indexOf("First Name")] || "",
          "Last Name": row[sheetColumns.indexOf("Last Name")] || "",
          Email: row[sheetColumns.indexOf("Email")] || "",
        };
      });

      const {
        data,
        duplicates,
        missingValues,
        invalidEmails,
        nonStringValues,
      } = findIssues(formattedData);

      if (missingValues.length > 0) {
        enqueueSnackbar(
          `Your Excel file contains some missing information at row: ${missingValues.join(
            ", "
          )}`,
          {
            variant: "error",
          }
        );
        setUsersData([]);
        return;
      }

      if (duplicates.length > 0) {
        enqueueSnackbar(
          `Your Excel file contains same email at row: ${duplicates.join(
            ", "
          )}`,
          {
            variant: "error",
          }
        );
        setUsersData([]);
        return;
      }

      if (nonStringValues.length > 0) {
        enqueueSnackbar(
          `Your Excel file contains non-string values in First Name or Last Name at row: ${nonStringValues.join(
            ", "
          )}`,
          {
            variant: "error",
          }
        );
        setUsersData([]);
        return;
      }

      if (invalidEmails.length > 0) {
        enqueueSnackbar(
          `Your Excel file contains invalid email addresses at row: ${invalidEmails.join(
            ", "
          )}`,
          {
            variant: "error",
          }
        );
        setUsersData([]);
        return;
      }

      setUsersData(data);
    };
    reader.readAsBinaryString(file);
  };

  const findIssues = (data) => {
    const seen = new Map();
    const duplicates = [];
    const missingValues = [];
    const invalidEmails = [];
    const nonStringValues = [];
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    data.forEach((row, index) => {
      const rowNumber = index + 2; // Excel row number (accounting for header)
      const email = row.Email;
      const missingFields = [];

      if (!row["First Name"]) missingFields.push("First Name");
      if (!row["Last Name"]) missingFields.push("Last Name");
      if (!row.Email) missingFields.push("Email");

      if (missingFields.length > 0) {
        missingValues.push(rowNumber);
      }

      if (!emailRegex.test(email)) {
        invalidEmails.push(rowNumber);
      }

      if (
        typeof row["First Name"] !== "string" ||
        typeof row["Last Name"] !== "string"
      ) {
        nonStringValues.push(rowNumber);
      }

      if (seen.has(email)) {
        const duplicateRows = seen.get(email);
        duplicateRows.push(rowNumber);
        seen.set(email, duplicateRows);
      } else {
        seen.set(email, [rowNumber]);
      }
    });

    seen.forEach((rowNumbers, email) => {
      if (rowNumbers.length > 1) {
        const originalRow = rowNumbers[0];
        const duplicateRows = rowNumbers.slice(1).join(", ");
        duplicates.push(`(${duplicateRows} - ${originalRow})`);
      }
    });

    return { data, duplicates, missingValues, invalidEmails, nonStringValues };
  };

  const handleDeleteRow = (index) => {
    const newUsers = usersData.filter((item, idx) => index !== idx);
    setUsersData(newUsers);
  };

  const handleFormRequest = async () => {
    const formattedData = usersData.map((user) => ({
      first_name: user["First Name"],
      last_name: user["Last Name"],
      email: user.Email,
    }));
    const { status, message } = await post(
      ADMIN_COUSTOMER_IMPORT,
      formattedData
    );

    if (status) {
      enqueueSnackbar(message, { variant: "success" });
      setUsersData([]);
      navigate(routes.ADMIN_CUSTOMERS);
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const mutation = useMutation(handleFormRequest);

  const handleSubmit = (e) => {
    e.preventDefault();
    mutation.mutate();
  };

  const currentUsers = useMemo(() => {
    // Calculate the start and end index for the current page
    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return usersData.slice(startIndex, endIndex);
  }, [usersData, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleExportExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Sample");
    sheet.properties.defaultRowHeight = 190;

    sheet.getRow(1).height = 50;
    sheet.getRow(1).values = ["First Name", "Last Name", "Email"];

    sheet.columns = [
      { header: "First Name", key: "firstName", width: 30 },
      { header: "Last Name", key: "lastName", width: 30 },
      { header: "Email", key: "email", width: 30 },
    ];

    sheet.getRow(1).border = {
      top: { style: "thin", color: { argb: "FF808080" } },
      left: { style: "thin", color: { argb: "FF808080" } },
      bottom: { style: "thin", color: { argb: "FF808080" } },
      right: { style: "thin", color: { argb: "FF808080" } },
    };

    sheet.getRow(1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFFFFFFF" },
    };

    sheet.getRow(1).font = {
      name: "Calibri",
      family: 4,
      size: 13,
      bold: true,
    };
    sheet.eachRow((row) => {
      row.eachCell((cell) => {
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
      });
    });

    const promise = Promise.all(
      dummyUsers.map(async (newUser, index) => {
        const rowNumber = index + 2;
        sheet.getRow(rowNumber).height = 50;

        sheet.getRow(rowNumber).values = [
          `${newUser?.firstName || "-"}`,
          `${newUser?.lastName || "-"}`,
          `${newUser?.email || "-"}`,
        ];

        sheet.eachRow((row) => {
          row.eachCell((cell) => {
            cell.alignment = {
              vertical: "middle",
              horizontal: "center",
              wrapText: true,
            };
          });
        });
      })
    );

    promise.then(() => {
      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "SampleCustomers.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    });
  };

  return (
    <>
      <LoadingBackDrop loading={mutation.isLoading}>
        <Typography color="#fff">
          Validating customer data... Checking for existing emails. Please wait.
        </Typography>
      </LoadingBackDrop>
      <Button
        variant="contained"
        sx={styles.cancelBtn}
        onClick={() => navigate(routes.ADMIN_CUSTOMERS)}
        disableElevation
      >
        <RightArrow className="mr-1" />
        Back
      </Button>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <PageTitle title="Import Customers" />
        <LoadingButton
          variant="contained"
          sx={styles.downloadButton}
          disableElevation
          onClick={handleExportExcelFile}
        >
          Download Template File
        </LoadingButton>
      </Stack>
      <Box mt={3}>
        <FileUploader
          handleChange={handleFileChange}
          name="file"
          types={fileTypes}
          // eslint-disable-next-line react/no-children-prop
          children={
            <Box width="100%" sx={styles.fileBox} flex={1}>
              <Typography component="p" sx={styles.fileText}>
                Click to upload or drag and drop XLSX, XLS, CSV file
              </Typography>
              <Button
                variant="contained"
                type="button"
                disableElevation
                sx={styles.imgBtn}
              >
                Browse File
              </Button>
            </Box>
          }
        />
      </Box>
      {currentUsers.length > 0 && (
        <>
          <Box mt={3}>
            <Stack direction="row" justifyContent="flex-end">
              <LoadingButton
                variant="contained"
                sx={styles.button}
                disableElevation
                onClick={handleSubmit}
                // isLoading={mutation.isLoading}
              >
                Approve & Create Customers
              </LoadingButton>
            </Stack>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>No.</TableCell>
                    {Object.keys(currentUsers[0]).map((key) => (
                      <TableCell key={key}>{key}</TableCell>
                    ))}
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentUsers.map((row, index) => {
                    const rowNumber = (page - 1) * rowsPerPage + index + 1;
                    const indexNumber = (page - 1) * rowsPerPage + index;
                    return (
                      <TableRow key={index}>
                        <TableCell key={index}>{rowNumber}</TableCell>
                        {Object.values(row).map((value, i) => (
                          <TableCell key={i}>{value}</TableCell>
                        ))}
                        <TableCell>
                          <Tooltip title="Delete" placement="top">
                            <IconButton
                              disableRipple
                              onClick={() => handleDeleteRow(indexNumber)}
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <Pagination
              count={Math.ceil(usersData.length / rowsPerPage)}
              page={page}
              onChange={handleChangePage}
              sx={styles.pagination}
              variant="outlined"
              shape="rounded"
            />
          </Box>
          <Stack direction="row" justifyContent="flex-end">
            <LoadingButton
              variant="contained"
              sx={styles.submitButton}
              disableElevation
              onClick={handleSubmit}
              // isLoading={mutation.isLoading}
            >
              Approve & Create Customers
            </LoadingButton>
          </Stack>
        </>
      )}
    </>
  );
};

export default ImportCustomersFromExcel;
