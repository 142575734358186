import { useState } from "react";
import PageTitle from "../../../components/common/PageTitle";
import { Box, Grid, Typography } from "@mui/material";
import CustomInputField from "../../../components/forms/CustomInputField";
import ContacUsSchema from "../../../utils/schemas/ContacUsSchema";
import { validator } from "../../../utils/helpers/validator";
import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { CONTACT_US } from "../../../data/constants/apiRoutes";
import routes from "../../../data/constants/routes";
import { post } from "../../../server";
import LoadingButton from "../../../components/button/LoadingButton";

const styles = {
  mainWrapper: {
    backgroundColor: "#F9FAFC",
    p: 4,
    minHeight: "100vh",
  },
  wrapper: {
    bgcolor: "#fff",
    borderRadius: "20px",
    p: 3,
    mt: 2,
  },
  submitBtn: {
    bgcolor: "#135099",
    borderRadius: 2.5,
    mt: 2,
    minWidth: 130,
    height: 45,
  },

  characters: {
    color: "#B4B1B6",
    textAlign: "end",
    mt: 1,
  },
};
const initialData = {
  name: "",
  email: "",
  message: "",
};

const PublicContactUs = () => {
  const [contactUs, setContactUs] = useState(initialData);
  const [errors, setErrors] = useState({});

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === "email") {
      if (value.length > 70) {
        value = value.slice(0, 70);
      }
    }
    setContactUs({ ...contactUs, [name]: value });
  };

  const validateForm = () => {
    let newErrors = validator(contactUs, ContacUsSchema);
    setErrors(newErrors);

    if (Object.keys(newErrors).length) return false;

    return true;
  };

  const handleFormRequest = async () => {
    const { status, message } = await post(CONTACT_US, contactUs);
    if (status) {
      enqueueSnackbar(message, { variant: "success" });
      setContactUs(initialData);
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const mutation = useMutation(handleFormRequest);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    mutation.mutate();
  };

  return (
    <Box sx={styles.mainWrapper}>
      <PageTitle title="Contact Us" />
      <Box component="form" noValidate autoComplete="off">
        <Box sx={styles.wrapper}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <CustomInputField
                label="Full Name"
                name="name"
                value={contactUs.name}
                onChange={handleChange}
                error={errors.name ? true : false}
                helperText={errors.name || ""}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInputField
                label="Email"
                name="email"
                value={contactUs.email}
                onChange={handleChange}
                error={errors.email ? true : false}
                helperText={errors.email || ""}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Box>
                <CustomInputField
                  label="Message"
                  name="message"
                  value={contactUs.message}
                  onChange={handleChange}
                  multiline
                  rows={5}
                  error={errors.message ? true : false}
                  helperText={errors.message || ""}
                />
                <Typography
                  sx={styles.characters}
                >{`${contactUs.message.length}/4000`}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <LoadingButton
        variant="contained"
        disableElevation
        sx={styles.submitBtn}
        type="submit"
        onClick={handleSubmit}
        isLoading={mutation.isLoading}
      >
        Send
      </LoadingButton>
    </Box>
  );
};

export default PublicContactUs;
