const styles = {
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 380,
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 15,
    py: 1,
    px: 2,
    "& button": {
      width: 120,
      boxShadow: "none",
      fontWeight: 600,
    },
  },
  iconBox: {
    width: 60,
    height: 60,
    borderRadius: "50%",
    textAlign: "center",
    border: "3px solid #E53935",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  crossIcon: {
    width: 40,
    height: 40,
    color: "#E53935",
  },
  closeIconBtnWrapper: {
    textAlign: "end",
  },
  closeIconBtn: {
    textAlign: "end",
    width: "40px !important",
    height: 40,
  },
  headerText: {
    fontSize: 18,
    fontWeight: 600,
    flexGrow: 1,
    textAlign: "center",
    color: "#636363",
  },
  confirmTitle: {
    color: "#838383",
    marginInline: "10px",
    fontSize: 14,
  },
  cancelBtn: {
    bgcolor: "#c1c1c1",
    color: "#ffffff",
    height: 40,
    textTransform: "capitalize",
    "&:hover": { bgcolor: "#c1c1c1" },
  },
  subscribCancelBtn: {
    bgcolor: "#E53935",
    color: "#ffffff",
    height: 45,
    width: "100% !important",
    textTransform: "capitalize",
    "&:hover": { bgcolor: "#E53935" },
    mb: 3,
  },
  btnsWrapper: {
    mt: 1,
    mb: 3,
  },

  subscribBtn: {
    width: "100% !important",
    bgcolor: "#D3A23B",
    color: "#ffffff",
    height: 40,

    textTransform: "capitalize",
    "&:hover": { bgcolor: "#D3A23B" },
  },
};

export default styles;
