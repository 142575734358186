import Joi from "joi";

const ChangeEmailSchema = Joi.object({
  email: Joi.string()
    .max(70)
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.empty": `Please enter email address`,
      "string.email": `Please enter valid email address`,
      "string.max": `Email must be maximum 70 characters!`,
    }),
  password: Joi.string().required().messages({
    "string.empty": `Please enter password`,
    "string.min": `Password must be minimum 8 characters!`,
  }),
});

const ProfileSchema = Joi.object({
  first_name: Joi.string().max(25).required().messages({
    "string.empty": `Please enter first name`,
    "string.max": `First name must be maximum 25 characters!`,
  }),
  last_name: Joi.string().max(25).required().messages({
    "string.empty": `Please enter last name`,
    "string.max": `Last name must be maximum 25 characters!`,
  }),
  email: Joi.string()
    .max(70)
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.empty": `Please enter email address`,
      "string.email": `Please enter a valid email address`,
      "string.max": `Email must be maximum 70 characters!`,
    }),
  password: Joi.string().min(8).allow("", null).messages({
    "string.empty": "Please enter password",
    "string.base": "Please enter password",
    "string.min": "Password must be at least 8 characters long",
  }),
});

export { ChangeEmailSchema, ProfileSchema };
