import { get } from "../server";
import { CUSTOMER_PROFILE_GET } from "../data/constants/apiRoutes";
import { loggedUserAtom } from "../data/store";
import { useSnackbar } from "notistack";
import _ from "lodash";
import { useAtom } from "jotai";

const useRefreshUser = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);

  const { enqueueSnackbar } = useSnackbar();

  const refreshUser = async () => {
    const { status, message, data } = await get(CUSTOMER_PROFILE_GET);
    if (status) {
      let user = _.cloneDeep(loggedUser);
      user.data = data;
      user.data.login_type = loggedUser?.data?.login_type;
      setLoggedUser(user);
    } else {
      enqueueSnackbar(message, { variant: "error", message });
    }
  };

  return { refreshUser };
};

export default useRefreshUser;
