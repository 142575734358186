import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Box, Stack, Button, Drawer, IconButton, Fab } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CloseRounded, DeleteOutline } from "@mui/icons-material";
import BusinessCardPreview from "./BusinessCardPreview";
import { ReactComponent as Delete } from "../../assets/svg/Delete.svg";

import routes from "../../data/constants/routes";
import { useAtom } from "jotai";
import { mobileViewAtom } from "../../data/store";

const drawerWidth = 350;

const styles = {
  addBtn: {
    whiteSpace: "nowrap",
    background: "#135099",
    borderRadius: "10px",
    textTransform: "capitalize",
    width: 130,
    height: 50,
    "&:hover": {
      background: "#135099",
    },
  },
  header: {
    "& .MuiIconButton-root": {
      p: "4px",
      width: 32,
      height: 32,
    },
  },
  editWrapper: {
    bgcolor: "#F9FBFC",
    p: "10px 15px",
    borderRadius: 6,
    my: 2,
    columnGap: 1,
  },
  editBtn: {
    bgcolor: "#fff",
    boxShadow: "0px 0px 10px 0px rgba(171, 171, 171, 0.10)",
    color: "#135099",
    width: "100%",
    borderRadius: 10,
    height: 35,
    fontWeight: 500,
    textTransform: "capitalize",
  },
  viewBtn: {
    bgcolor: "transparent",
    color: "#1E1E1F",
    width: "100%",
    borderRadius: 10,
    height: 35,
    textTransform: "capitalize",
  },
};

export default function RightBusinessPreview({
  open,
  handleDrawerOpen,
  handleOpenDelete,
  handleDrawerClose,
  inViewMode,
  showActionBtn = true,
  type = false,
  isComunity = false,
  ...card
}) {
  const [mobileView, setMobileView] = useAtom(mobileViewAtom);

  const navigate = useNavigate();

  const handleEditNavigation = () => {
    if (type) navigate(`${routes.CUSTOMER_EDIT_CARD}/${card.id}?type=${type}`);
    else navigate(`${routes.CUSTOMER_EDIT_CARD}/${card.id}`);
  };

  return (
    <>
      {mobileView ? (
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: { sm: drawerWidth, xs: "100%" },
              borderLeft: 0,
              p: 2.5,
            },
          }}
          variant="temporary"
          anchor="right"
          open={open}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={styles.header}
          >
            <IconButton onClick={handleDrawerClose}>
              <CloseRounded sx={{ color: "#000" }} />
            </IconButton>

            {showActionBtn ? (
              <IconButton onClick={handleOpenDelete}>
                <Delete />
              </IconButton>
            ) : null}
          </Stack>
          {showActionBtn ? (
            <Stack flexDirection="row" sx={styles.editWrapper}>
              <Button
                sx={styles.editBtn}
                disableElevation
                onClick={handleEditNavigation}
              >
                Edit
              </Button>
            </Stack>
          ) : null}

          <BusinessCardPreview {...card} inViewMode={inViewMode} />
        </Drawer>
      ) : (
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: { sm: drawerWidth, xs: "100%" },
              borderLeft: 0,
              p: 2.5,
            },
          }}
          variant="persistent"
          anchor="right"
          open={open}
        >
          <Stack
            direction="row"
            justifyContent={showActionBtn ? "space-between" : "flex-end"}
            mb={!showActionBtn || isComunity ? 2 : 0}
            sx={styles.header}
          >
            <IconButton onClick={handleDrawerClose}>
              <CloseRounded sx={{ color: "#000" }} />
            </IconButton>

            {showActionBtn ? (
              <IconButton onClick={handleOpenDelete}>
                <Delete />
              </IconButton>
            ) : null}
          </Stack>
          {showActionBtn && !isComunity ? (
            <Stack flexDirection="row" sx={styles.editWrapper}>
              <Button
                sx={styles.editBtn}
                disableElevation
                onClick={handleEditNavigation}
              >
                Edit
              </Button>
            </Stack>
          ) : null}

          <BusinessCardPreview {...card} inViewMode={inViewMode} />
        </Drawer>
      )}
    </>
  );
}
