import Listing from "../../../components/listing/DataGridListing";
import { ReactComponent as Edit } from "../../../assets/svg/Edit.svg";
import { ReactComponent as Delete } from "../../../assets/svg/Delete.svg";
import PageTitle from "../../../components/common/PageTitle";
import routes from "../../../data/constants/routes";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import {
  CATEGORY_DELETE,
  CATEGORY_LISTING,
} from "../../../data/constants/apiRoutes";
import { get, destroy } from "../../../server";
import queryNames from "../../../data/constants/queryNames";
import { useNavigate } from "react-router-dom";
import BoxModal from "../../../components/modal/BoxModal";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import Overlay from "../../../components/overlay/Overlay";

const CategoryListing = () => {
  const [modal, setModal] = useState(false);
  const [category, setCategory] = useState();

  const navigate = useNavigate();
  const client = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const columns = [
    {
      field: "name",
      headerName: "Category Name",
      flex: 1,
    },
    {
      field: "items",
      headerName: "Items",
      flex: 1,
      valueGetter: (params) => params.row?.items || 0,
    },
    {
      field: "isShow",
      headerName: "Visibility",
      width: 200,
      renderCell: (params) => {
        return (
          <Typography
            variant="body2"
            sx={{
              color: params.row.isShow ? "#6FE381" : "#D45353",
              textTransform: "uppercase",
            }}
          >
            {params.row.isShow ? "Published" : "Hidden"}
          </Typography>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0.5}>
            <IconButton
              onClick={() =>
                navigate(`${routes.ADMIN_CATEGORY_EDIT}/${params.row.id}`)
              }
            >
              <Edit />
            </IconButton>
            <IconButton
              onClick={() => {
                setCategory(params.row);
                setModal(true);
              }}
            >
              <Delete />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const fetchCategories = async (pageNo, limit, searchParam, isSearchParam) => {
    let url;
    // if (isSearchParam === false) {
    // 	url = new URL(CATEGORY_LISTING);
    // }
    // else if (isSearchParam && searchParam.length) {
    // 	url = new URL(ADMIN_COURSES_LISTING_SEARCH);
    // 	url.searchParams.append("search", searchParam);
    // }
    url = new URL(CATEGORY_LISTING);
    url.searchParams.append("page", pageNo + 1);
    url.searchParams.append("limit", limit);

    return await get(url.toString());
  };

  const handleInvalidate = () => {
    client.invalidateQueries(queryNames.COURSES);
  };

  const handleClose = () => {
    setModal(false);
    setCategory(null);
    handleInvalidate();
  };

  const handleDelete = async () => {
    const { status, message } = await destroy(
      `${CATEGORY_DELETE}/${category?.id}`
    );

    if (status) {
      enqueueSnackbar(message, { variant: "success" });
      handleClose();
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const mutation = useMutation(handleDelete, {
    onSuccess: () => {
      client.invalidateQueries([queryNames]);
    },
  });

  const handleConfirm = (e) => {
    e.preventDefault();
    mutation.mutate();
  };

  return (
    <>
      <PageTitle
        title="Categories"
        searchInput
        placeholder="Search Categories"
        // search={search}
        // setSearch={setSearch}
        // isSearch={isSearch}
        // handleCancleSearch={handleCancleSearch}
        // handleSearchClick={handleSearchClick}
        createBtn
        createBtnText="+Category"
        url={routes.ADMIN_CATEGORY_ADD}
      />

      <Listing
        columns={columns}
        fetchRecords={fetchCategories}
        queryName={queryNames.CATEGORIES}
      />

      <BoxModal
        header="Are you sure?"
        title={`Do you really want to delete ${category?.name} category?`}
        open={modal}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        confirmButton="Delete"
        confirmButtonColor="#E53935"
        isLoading={mutation.isLoading}
      />
    </>
  );
};

export default CategoryListing;
