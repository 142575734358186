import Joi from "joi";

const LoginSchema = Joi.object({
  email: Joi.string()
    .max(70)
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.empty": `Please enter email address`,
      "string.email": `Please enter valid email address`,
      "string.max": `Email must be maximum 70 characters!`,
    }),
  password: Joi.string().required().messages({
    "string.empty": `Please enter password`,
    "string.min": `Password must be minimum 8 characters!`,
  }),
});

const SignupSchema = Joi.object({
  first_name: Joi.string().max(25).required().messages({
    "string.empty": `Please enter first name`,
    "string.max": `First name must be maximum 25 characters!`,
  }),
  last_name: Joi.string().max(25).required().messages({
    "string.empty": `Please enter last name`,
    "string.max": `Last name must be maximum 25 characters!`,
  }),
  email: Joi.string()
    .max(70)
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.empty": `Please enter email address`,
      "string.email": `Please enter a valid email address`,
      "string.max": `Email must be maximum 70 characters!`,
    }),
  password: Joi.string().min(8).required().messages({
    "string.empty": "Please enter password",
    "string.base": "Please enter password",
    "string.min": "Password must be at least 8 characters long",
  }),
  confirm_password: Joi.string()
    .required()
    .valid(Joi.ref("password"))
    .messages({
      "string.empty": "Please enter confirm password",
      "any.only": "Password and confirm password does not match",
      "string.base": "Please enter confirm password",
      "any.required": "Please enter confirm password",
    }),
});

const ForgetPasswordSchema = Joi.object({
  email: Joi.string()
    .max(70)
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.empty": `Please enter email address`,
      "string.email": `Please enter valid email address`,
      "string.max": `Email must be maximum 70 characters!`,
    }),
});

const OTPSchema = Joi.object({
  otp: Joi.string().min(4).max(4).required().messages({
    "string.empty": `Please enter OTP code`,
    "string.min": `OTP must be minimum 4 characters`,
    "string.max": `OTP must be maximum 4 characters`,
  }),
});

const ResetPasswordSchema = Joi.object({
  password: Joi.string().min(8).required().messages({
    "string.empty": `Please enter password`,
    "string.min": `Password must be minimum 8 characters!`,
  }),
  confirm_password: Joi.string()
    .required()
    .valid(Joi.ref("password"))
    .messages({
      "string.empty": `Please enter confirm password `,
      "any.only": `Confirm password must match password`,
    }),
});

const ChangePasswordSchema = Joi.object({
  current_password: Joi.string().required().messages({
    "string.empty": `Please enter current password`,
  }),
  password: Joi.string().min(8).required().messages({
    "string.empty": `Please enter new password`,
    "string.min": `Password must be minimum 8 characters!`,
  }),
  confirm_password: Joi.string()
    .required()
    .valid(Joi.ref("password"))
    .messages({
      "string.empty": `Please enter confirm password `,
      "any.only": `Password and confirm password does not match`,
    }),
});

export {
  LoginSchema,
  SignupSchema,
  ForgetPasswordSchema,
  OTPSchema,
  ResetPasswordSchema,
  ChangePasswordSchema,
};
