import { useRef, useState } from "react";
import PageTitle from "../../../components/common/PageTitle";
import styles from "../../../assets/styles/customer/addEditTeam.styles";
import { ReactComponent as RightArrow } from "../../../assets/svg/RightArrow.svg";
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import FormInput from "../../../components/forms/FormInput";
import { useNavigate, useParams } from "react-router-dom";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import {
  TEAM_ADD,
  TEAM_DETAIL,
  TEAM_MEMBERS,
  TEAM_UPDATE,
} from "../../../data/constants/apiRoutes";
import { useMutation, useQuery } from "react-query";
import LoadingButton from "../../../components/button/LoadingButton";
import awsFileUpload from "../../../utils/helpers/awsFileUpload";
import { uploadImageType } from "../../../data/constants/uploadImageTypes";
import { useSnackbar } from "notistack";
import { get, post } from "../../../server";
import routes from "../../../data/constants/routes";
import { validator } from "../../../utils/helpers/validator";
import { TeamEmailSchema, TeamSchema } from "../../../utils/schemas/TeamSchema";
import FormAutoComplete from "../../../components/forms/FormAutoComplete";
import { ReactComponent as PlaceholderImage } from "../../../assets/svg/createCard/PlaceholderImage.svg";
import { ReactComponent as Cross } from "../../../assets/svg/businesscard/Cross.svg";
import _ from "lodash";
import queryNames from "../../../data/constants/queryNames";
import Loader from "../../../components/Loader";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import CropAvatar from "../../../components/modal/CropAvatar";
import { useAtom } from "jotai";
import { loggedUserAtom } from "../../../data/store";
import useValidateImageSize from "../../../hooks/useValidateImageSize";

const initialState = {
  name: "",
  logo: "",
  members: [],
  inviteMembers: [],
};

const EditTeam = () => {
  const [team, setTeam] = useState(initialState);
  const [ownerId, setOwnerId] = useState(null);

  const [selectedMembers, setSelectedMembers] = useState([]);
  const [newSelectedMembers, setNewSelectedMembers] = useState([]);
  const [modal, setModal] = useState(false);
  const [cropModal, setCropModal] = useState(false);
  const [image, setImage] = useState(null);

  const { validateImageSize } = useValidateImageSize();

  const { teamId } = useParams();
  const isId = teamId !== null && typeof teamId !== "undefined";
  const imageRef = useRef();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "image") {
      // setTeam({ ...team, logo: e.target.files[0] });
      const selectedFile = e.target.files[0];

      if (!validateImageSize(selectedFile)) {
        return;
      }

      setTeam({ ...team, logo: selectedFile });

      // setImage(URL.createObjectURL(selectedFile));
      // setCropModal("logo");
    } else {
      setTeam({ ...team, [name]: value });
    }
  };

  const handleAvatarClick = () => {
    imageRef.current.click();
  };

  const fetchTeam = async () => {
    const { status, message, data } = await get(`${TEAM_DETAIL}/${teamId}`);
    if (status === true) {
      setTeam({
        id: data.id,
        logo: data.logo,
        name: data.name,
      });

      setOwnerId(data.ownerData.id);
      setSelectedMembers(data.members);
      setNewSelectedMembers(data.invited_members);
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const teamQuery = useQuery([queryNames.TEAM, teamId], fetchTeam, {
    enabled: isId,
    refetchOnWindowFocus: false,
  });

  const validateForm = () => {
    let newErrors = validator(team, TeamSchema);

    if (!team.logo) {
      newErrors = {
        ...newErrors,
        logo: "Team logo is required",
      };
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) return true;
    return false;
  };

  const handleSaveTeam = async () => {
    let payload = _.cloneDeep(team);

    let newStatus = true;
    if (typeof payload.logo === "object") {
      const data = await awsFileUpload(
        payload.logo,
        uploadImageType.createTeam
      );
      if (data.status === true) {
        payload.logo = data.location;
      } else {
        enqueueSnackbar("Error uploading Logo", { variant: "error" });
        return (newStatus = false);
      }
    }

    if (newStatus) {
      const { status, message } = await post(
        isId ? `${TEAM_UPDATE}/${teamId}` : TEAM_ADD,

        {
          ...payload,
          members: selectedMembers
            .filter((item) => item.id !== ownerId)
            .map((item) => item.id),

          inviteMembers: newSelectedMembers.map((item) => ({
            email: item.email,
            ...(item.id && { id: item.id }),
          })),
        }
      );
      if (status === true) {
        enqueueSnackbar(message, { variant: "success" });
        navigate(routes.ADMIN_TEAMS);
      } else {
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  };

  const mutation = useMutation(handleSaveTeam);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    mutation.mutate();
  };

  if (teamQuery.isLoading || teamQuery.isFetching) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle title="Edit Team" />

      <Box sx={styles.mainWrapper}>
        <Box mt={2}>
          <Avatar
            sx={styles.avatar}
            onClick={handleAvatarClick}
            src={
              team?.logo
                ? typeof team?.logo === "string"
                  ? team?.logo
                  : URL.createObjectURL(team?.logo)
                : null
            }
          >
            <CameraAltOutlinedIcon />
          </Avatar>
          <input
            type="file"
            accept="image/*"
            hidden
            name="image"
            onChange={handleChange}
            id="avatarUpload"
            ref={imageRef}
            key={team?.logo}
          />
          <FormHelperText sx={styles.errorText}>{errors.logo}</FormHelperText>
        </Box>

        <Box sx={styles.wrapper}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormInput
                value={team.name}
                label="Team Name"
                withasterisk
                placeholder="Team Name"
                name="name"
                onChange={handleChange}
                error={errors.name}
                helperText={errors.name}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Stack
        direction="row"
        justifyContent="space-between"
        flexWrap={{ md: "nowrap", xs: "wrap" }}
        rowGap={2}
        spacing={{ md: 2, xs: 0 }}
        pb={2}
      >
        <Button
          variant="contained"
          sx={[styles.btn, styles.removeBtn]}
          disableElevation
          onClick={() => navigate(routes.ADMIN_TEAMS)}
        >
          <RightArrow className="mr-1" />
          Back
        </Button>

        <Stack direction="row" columnGap={2}>
          <LoadingButton
            variant="contained"
            sx={{ ...styles.btn, ...styles.addBtn }}
            disableElevation
            onClick={handleSubmit}
            isLoading={mutation.isLoading}
          >
            Save
          </LoadingButton>
        </Stack>
      </Stack>

      <CropAvatar
        modal={cropModal}
        setModal={setCropModal}
        image={image}
        user={team}
        setUser={setTeam}
        cropType=""
        aspectRatio={4 / 4}
      />

      <ConfirmationModal
        header="Are you sure?"
        title={`Do you really want to cancel? This process cannot be undone.`}
        confirmButton="OK"
        confirmButtonColor="#E53935"
        open={modal}
        handleClose={() => setModal(false)}
        handleConfirm={() => navigate(routes.routes.ADMIN_TEAMS)}
      />
    </>
  );
};

export default EditTeam;
