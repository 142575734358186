import Joi from "joi";

const UserSchema = Joi.object({
  first_name: Joi.string().max(25).required().messages({
    "string.empty": `Please enter first name`,
    "string.max": `First name must be maximum 25 characters!`,
  }),
  last_name: Joi.string().max(25).required().messages({
    "string.empty": `Please enter last name`,
    "string.max": `Last name must be maximum 25 characters!`,
  }),
  email: Joi.string()
    .max(70)
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.empty": `Please enter email address`,
      "string.email": `Please enter a valid email address`,
      "string.max": `Email must be maximum 70 characters!`,
    }),
  //   phone: Joi.string().allow("", null).messages({
  //     "string.empty": `Please enter Phone number `,
  //   }),

  phone: Joi.string()
    .pattern(
      new RegExp(
        /^(?!\b(0)\1+\b)(\+?\d{1,3}[. -]?)?\(?\d{3}\)?([. -]?)\d{3}\3\d{4}$/
      )
    )
    .allow("")
    .allow("1")
    .messages({
      "string.empty": `Please enter your phone number`,
      "string.pattern.base": `Please enter a valid phone number`,
    }),

  company: Joi.string().allow("", null).messages({
    "string.empty": `Please enter Company Name`,
  }),
  position: Joi.string().allow("", null).messages({
    "string.empty": `Please enter Position`,
  }),
  address: Joi.string().allow("", null).messages({
    "string.empty": `Please enter Address`,
  }),
  about: Joi.string().allow("", null).max(800).messages({
    "string.empty": `Please enter your Bio`,
    "string.max": `Bio must be maximum 800 characters!`,
  }),
  linkedin: Joi.string().allow("", null).messages({
    "string.empty": `Please enter your Linkdin Address`,
  }),
});

export default UserSchema;
