import Joi from "joi";

export const RegisterFormSchema = Joi.object({
  first_name: Joi.string().max(25).required().messages({
    "string.empty": `Please enter first name`,
    "string.max": `First name must be maximum 25 characters!`,
  }),
  last_name: Joi.string().max(25).required().messages({
    "string.empty": `Please enter last name`,
    "string.max": `Last name must be maximum 25 characters!`,
  }),
  email: Joi.string()
    .max(70)
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.empty": `Please enter email address`,
      "string.email": `Please enter a valid email address`,
      "string.max": `Email must be maximum 70 characters!`,
    }),
  phone: Joi.string().min(8).required().messages({
    "string.empty": `Please enter your phone number`,
    "string.min": `Please enter valid phone number`,
  }),
});
