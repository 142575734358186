import {
  Box,
  Button,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Rating,
  Select,
  Stack,
  Typography,
  Paper,
} from "@mui/material";
import Product1 from "../../../assets/images/Product 1.png";
import Product2 from "../../../assets/images/Product 2.png";
import Product3 from "../../../assets/images/Product 3.png";
import { useState } from "react";
import { Add, Remove } from "@mui/icons-material";
import PaymentModal from "../../../components/modal/PaymentModal";
import { useAtom } from "jotai";
import { cartAtom } from "../../../data/store";
import Carousel from "react-material-ui-carousel";
import { useParams } from "react-router";
import { get } from "../../../server";
import { PRODUCT_DETAIL } from "../../../data/constants/apiRoutes";
import { enqueueSnackbar } from "notistack";
import { useQuery } from "react-query";
import queryNames from "../../../data/constants/queryNames";
import Loader from "../../../components/Loader";

const styles = {
  image: {
    width: "100%",
    height: "100%",
    maxHeight: { xl: 600, lg: 450, md: 300 },
    borderRadius: "20px",
    objectFit: "contain",
  },
  title: {
    mb: "10px",
    color: "#000",
    fontSize: 30,
    fontWeight: 500,
    lineHeight: "36px",
    textTransform: "capitalize",
  },
  price: {
    color: "#135099 !important",
    fontWeight: "700 !important",
    mt: "10px",
  },
  category: {
    fontSize: 16,
    color: "#767D90",
    textTransform: "capitalize",
  },
  colorLegend: {
    color: "#000",
    fontSize: 18,
    fontWeight: 500,
    lineHeight: "36px",
  },
  color: {
    width: 46,
    height: 46,
    borderRadius: "50%",
    cursor: "pointer",
    position: "relative",
  },
  selectedColor: {
    width: 35,
    height: 35,
    borderRadius: "50%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    boxShadow: "0 0 0 2px white, 0 0 0 4px white",
  },
  qtyBtnContainer: {
    bgcolor: "#FFF",
    border: "1px solid #E8EEF5",
    borderRadius: "10px",
    width: 150,
    justifyContent: "center",
    alignItems: "center",
    "& label": {
      color: "#000",
      fontSize: 16,
      fontWeight: 500,
      width: 70,
      textAlign: "center",
    },
  },
  qtyBtn: {
    bgcolor: "#E8EEF5",
    borderRadius: "10px",
    minWidth: 40,
    minHeight: 40,
    border: 0,
  },
  discLegend: {
    color: "#000",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "36px",
  },
  discription: {
    color: "#4B4B4B",
    fontSize: 13,
  },
  addOns: {
    color: "#000",
    fontSize: 20,
    fontWeight: 500,
    lineHeight: "36px",
  },
  addOnsContainer: {
    bgcolor: "#FFF",
    borderRadius: "14px",
    pl: "9px",
    py: "9px",
    pr: 2,
    justifyContent: "space-between",
    alignItems: "center",
    mb: "10px",
    "& img": {
      height: 80,
      width: 80,
      borderRadius: "14px",
    },
    "& h5": {
      color: "#000",
      fontSize: 20,
      fontWeight: 500,
      lineHeight: "20px",
      mt: "3px",
    },
    "& label": {
      color: "#767D90",
      fontSize: 18,
      fontWeight: 600,
      lineHeight: "20px",
      mt: "6px",
    },
    "& button": {
      bgcolor: "#135099",
      color: "#FFF",
      maxWidth: 150,
      minWidth: 100,
      maxHeight: 50,
      fontSize: 12,
      fontWeight: 600,
      py: 1,
      textTransform: "capitalize",
    },
  },
  orderBtn: {
    bgcolor: "#135099",
    color: "#FFF",
    borderRadius: "10px",
    fontSize: 16,
    width: "100%",
    textTransform: "capitalize",
    mt: 3,
    "&:hover": {
      bgcolor: "#135099",
      color: "#FFF",
    },
  },
  cartBtn: {
    bgcolor: "#E8EEF5",
    color: "#000",
    borderRadius: "10px",
    fontSize: 16,
    width: "100%",
    mt: "10px",
    textTransform: "capitalize",
    "&:hover": {
      bgcolor: "#E8EEF5",
      color: "#000",
    },
  },
  select: {
    width: 120,
    height: 40,
    pr: 1.25,
    pl: 2.25,
    borderRadius: "10px",
    bgcolor: "#FFF",
    "& fieldset": {
      border: "1px solid #E8EEF5",
      borderColor: "#E8EEF5",
    },
    "&:hover fieldset": {
      borderColor: "#656DFC",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#656DFC",
    },
    "& .MuiOutlinedInput-input": {
      py: 1.25,
      px: 2,
    },
  },
  carouselIndicators: {
    display: "flex",
    justifyContent: "center",
    mt: "10px",
  },
  indicator: {
    width: 40,
    height: 40,
    mr: 1,
    cursor: "pointer",
    objectFit: "contain",
  },
  activeIndicator: {
    border: "2px solid #135099",
  },
};

const VeiwProduct = () => {
  const [product, setProduct] = useState();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [carouselItems, setCarouselItems] = useState([]);

  const { productId } = useParams();
  const isId = productId !== null && typeof productId !== "undefined";

  // const [cart, setCart] = useAtom(cartAtom);

  // const [selectedColor, setSelectedColor] = useState("#135099");
  // const [quantity, setQuantity] = useState(1);

  // const [modal, setModal] = useState(false);

  // const handleColorClick = (color) => {
  // 	setSelectedColor(color);
  // };

  // const handleAddToCart = () => {
  // 	const item = {
  // 		id: 1,
  // 		name: "Product Title",
  // 		price: 3.72,
  // 		image: Product1,
  // 		color: selectedColor,
  // 		quantity: quantity,
  // 	};

  // 	setCart([...cart, item]);
  // };

  const fetchProduct = async () => {
    const { status, message, data } = await get(
      `${PRODUCT_DETAIL}/${productId}`
    );
    if (status === true) {
      setProduct(data);
      setCarouselItems(
        data.images.map((media) => ({
          src: media.path,
          type: media.type,
          thumbnail: media.thumbnail,
        })) || []
      );
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const productQuery = useQuery(
    [queryNames.CUSTOMER_PRODUCTS, productId],
    fetchProduct,
    {
      enabled: isId,
      refetchOnWindowFocus: false,
    }
  );

  const handleSlideChange = (index) => {
    setCurrentSlide(index);
  };

  if (productQuery.isLoading || productQuery.isFetching) {
    return <Loader />;
  }

  return (
    <>
      <Grid container spacing={8}>
        <Grid item sm={12} md={6}>
          {/* <Box component="img" src={Product1} sx={styles.image} /> */}
          <Carousel
            onChange={handleSlideChange}
            indicators={false}
            index={currentSlide}
            interval={6000}
          >
            {carouselItems.map((item, index) =>
              item.type === "image" ? (
                <Box
                  component="img"
                  key={index}
                  src={item.src}
                  alt={item.src}
                  sx={styles.image}
                />
              ) : (
                <>
                  {item.src && (
                    <Box
                      component="video"
                      key={index}
                      autoPlay
                      loop
                      sx={styles.image}
                    >
                      <source src={item.src} type="video/mp4" />
                      <source src={item.src} type="video/ogg" />
                      Your browser does not support HTML video.
                    </Box>
                  )}
                </>
              )
            )}
          </Carousel>
          <Box sx={styles.carouselIndicators}>
            {carouselItems.map((_, index) => (
              <Box
                component="img"
                key={index}
                src={
                  carouselItems[index].type === "image"
                    ? carouselItems[index].src
                    : carouselItems[index].thumbnail
                }
                alt={
                  carouselItems[index].type === "image"
                    ? carouselItems[index].src
                    : carouselItems[index].thumbnail
                }
                // className={`indicator ${
                // 	currentSlide === index ? "active" : ""
                // }`}
                sx={{
                  ...styles.indicator,
                  ...(currentSlide === index ? styles.activeIndicator : {}),
                }}
                onClick={() => handleSlideChange(index)}
              />
            ))}
          </Box>
        </Grid>

        <Grid item sm={12} md={6}>
          <Box>
            <Typography component="h4" sx={styles.title}>
              {product?.name}
            </Typography>

            <Typography component="label" sx={styles.category}>
              {product?.brand}
            </Typography>

            <Typography
              component="h4"
              sx={{ ...styles.title, ...styles.price }}
            >
              ${product?.price}
            </Typography>

            {/* <Stack direction="row" spacing={1} alignItems="center" mb={"20px"}>
							<Rating name="rating" value={4} size="small" />
							<Typography component="label" sx={styles.category}>
								50 Reviews
							</Typography>
						</Stack> */}

            {/* <Typography component="legend" style={styles.colorLegend}>
							Colors:
						</Typography>
						<Stack direction="row" spacing="12px" mb={"20px"}>
							<Box
								sx={{
									...styles.color,
									bgcolor: "#135099",
									border:
										selectedColor === "#135099" ? "2px solid #767D90" : "",
								}}
								onClick={() => handleColorClick("#135099")}
							>
								{selectedColor === "#135099" && (
									<div style={styles.selectedColor} />
								)}
							</Box>

							<Box
								sx={{
									...styles.color,
									bgcolor: "#123267",
									border:
										selectedColor === "#123267" ? "2px solid #767D90" : "",
								}}
								onClick={() => handleColorClick("#123267")}
							>
								{selectedColor === "#123267" && (
									<div style={styles.selectedColor} />
								)}
							</Box>

							<Box
								sx={{
									...styles.color,
									bgcolor: "#585858",
									border:
										selectedColor === "#585858" ? "2px solid #767D90" : "",
								}}
								onClick={() => handleColorClick("#585858")}
							>
								{selectedColor === "#585858" && (
									<div style={styles.selectedColor} />
								)}
							</Box>

							<Box
								sx={{
									...styles.color,
									bgcolor: "#D3A23B",
									border:
										selectedColor === "#D3A23B" ? "2px solid #767D90" : "",
								}}
								onClick={() => handleColorClick("#D3A23B")}
							>
								{selectedColor === "#D3A23B" && (
									<div style={styles.selectedColor} />
								)}
							</Box>

							<Box
								sx={{
									...styles.color,
									bgcolor: "#D0011C",
									border:
										selectedColor === "#D0011C" ? "2px solid #767D90" : "",
								}}
								onClick={() => handleColorClick("#D0011C")}
							>
								{selectedColor === "#D0011C" && (
									<div style={styles.selectedColor} />
								)}
							</Box>
						</Stack> */}

            {/* <Typography component="legend" style={styles.colorLegend}>
							Quantity:
						</Typography> */}
            {/* <Stack sx={styles.qtyBtnContainer} direction="row" mb={"20px"}>
							<Button
								disabled={quantity <= 1}
								onClick={() => {
									setQuantity((quantity) => quantity - 1);
								}}
								sx={styles.qtyBtn}
							>
								<Remove sx={{ fill: "#000" }} />
							</Button>

							<Typography component="label">{quantity}</Typography>

							<Button
								// disabled={counter >= product["countInStock"]}
								onClick={() => {
									setQuantity((quantity) => quantity + 1);
								}}
								sx={styles.qtyBtn}
							>
								<Add sx={{ fill: "#135099" }} />
							</Button>
						</Stack> */}
            {/* <Select
							sx={styles.select}
							value={quantity}
							onChange={(e) => setQuantity(e.target.value)}
						>
							<MenuItem value={1}>1</MenuItem>
							<MenuItem value={2}>2</MenuItem>
							<MenuItem value={3}>3</MenuItem>
						</Select> */}

            <Typography component="legend" style={styles.discLegend}>
              Description:
            </Typography>

            <Typography
              component="p"
              style={styles.discription}
              dangerouslySetInnerHTML={{ __html: product?.description }}
            />
            {/* {product?.description}
						</Typography> */}

            {/* <Divider sx={{ borderColor: "#EBEBEE", mt: 5, mb: "20px" }} />

						<Typography component="legend" style={styles.addOns}>
							Add-Ons Products
						</Typography>

						<Stack direction="row" sx={styles.addOnsContainer}>
							<Stack direction="row">
								<Box component="img" src={Product2} />
								<Stack ml={"20px"}>
									<Typography component="h5">Product Title</Typography>
									<Typography component="label">+$ 5</Typography>
								</Stack>
							</Stack>
							<Button variant="contained" onClick={handleAddToCart}>
								Add to Cart
							</Button>
						</Stack>

						<Stack direction="row" sx={styles.addOnsContainer}>
							<Stack direction="row">
								<Box component="img" src={Product3} />
								<Stack ml={"20px"}>
									<Typography component="h5">Product Title</Typography>
									<Typography component="label">+$ 5</Typography>
								</Stack>
							</Stack>
							<Button variant="contained" onClick={handleAddToCart}>
								Add to Cart
							</Button>
						</Stack> */}

            {/* <Stack mt="30px"> */}
            <Button
              variant="contained"
              sx={styles.orderBtn}
              onClick={() => {
                let url = product?.product_url;
                if (!url.includes("http")) url = `http://${url}`;
                window.open(url, "_blank");
              }}
              disableElevation
            >
              Order Now
            </Button>
            {/* <Button
								variant="contained"
								sx={styles.cartBtn}
								disableElevation
								onClick={handleAddToCart}
							>
								Add To Cart
							</Button> */}
            {/* </Stack> */}
          </Box>
        </Grid>
      </Grid>
      {/* <PaymentModal modal={modal} setModal={setModal} /> */}
    </>
  );
};

export default VeiwProduct;
