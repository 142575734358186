import React from "react";
import styles from "../../assets/styles/overlay/loadingOverlay.styles";
import { Box, Typography } from "@mui/material";

const Overlay = () => {
  return (
    <Box
      sx={[
        styles.container,
        { bgcolor: "rgba(0, 0, 0, 0.8) !important", borderRadius: 2 },
      ]}
    >
      <Typography variant="h4" sx={{ color: "#fff" }}>
        Coming Soon
      </Typography>
    </Box>
  );
};

export default Overlay;
