import { useState } from "react";
import SplashForm from "../../../components/auth/SplashForm";
import routes from "../../../data/constants/routes";
import { InputAdornment, IconButton, Stack } from "@mui/material";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import styles from "../../../assets/styles/login.styles";
import { validator } from "../../../utils/helpers/validator";
import { LoginSchema } from "../../../utils/schemas/AuthSchema";
import { useSnackbar } from "notistack";
import { useAtom } from "jotai";
import { loggedUserAtom } from "../../../data/store";
import { post } from "../../../server";
import { ADMIN_LOGIN } from "../../../data/constants/apiRoutes";
import FormInput from "../../../components/forms/FormInput";

const Login = () => {
	const [loginForm, setLoginForm] = useState({
		email: "",
		password: "",
	});
	const [errors, setErrors] = useState({});

	const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);
	const { enqueueSnackbar } = useSnackbar();

	const navigate = useNavigate();

	const validateForm = () => {
		let newErrors = validator(loginForm, LoginSchema);
		setErrors(newErrors);
		if (Object.keys(newErrors).length) return false;
		return true;
	};

	const handleLogin = async () => {
		const { status, message, data } = await post(ADMIN_LOGIN, {
			email: loginForm.email,
			password: loginForm.password,
		});
		if (status === true) {
			const token = data.token;
			delete data.token;
			delete data.password;
			const newData = {
				token: token,
				data: data,
			};
			setLoggedUser(newData);
			enqueueSnackbar(message, { variant: "success" });
			navigate(routes.ADMIN_CUSTOMERS);
		} else {
			if (message === "No Authenticated User")
				enqueueSnackbar("There is no account associated with this email", { variant: "error" });
			else enqueueSnackbar(message, { variant: "error" });
		}
	};

	const mutation = useMutation(handleLogin);

	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;

		setLoginForm({ ...loginForm, [name]: value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!validateForm()) return;
		mutation.mutate();
	};

	const [showPassword, setShowPassword] = useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	return (
		<>
			<SplashForm
				title="Login"
				btnText="Login"
				linkTaglineHref={routes.SIGNUP}
				handleSubmit={handleSubmit}
				isLoading={mutation.isLoading}
			>
				<>
					<FormInput
						variant="outlined"
						type="email"
						name="email"
						value={loginForm.email}
						onChange={handleChange}
						placeholder="Email"
						error={errors?.email}
						helperText={errors?.email || ""}
						onKeyDown={(e) => {
							if (e.key === "Enter") handleSubmit(e);
						}}
					/>

					<FormInput
						variant="outlined"
						type={showPassword ? "text" : "password"}
						name="password"
						placeholder="Password"
						value={loginForm.password}
						onChange={handleChange}
						error={errors?.password}
						helperText={errors?.password || ""}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={handleClickShowPassword}>
										{showPassword ? (
											<VisibilityOffOutlinedIcon />
										) : (
											<VisibilityOutlinedIcon />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
						onKeyDown={(e) => {
							if (e.key === "Enter") handleSubmit(e);
						}}
					/>

					{/* <Stack sx={styles.forgetPassword}>
						<Link to={routes.ADMIN_RESET_PASSWORD}>Forgot your password?</Link>
					</Stack> */}
				</>
			</SplashForm>
		</>
	);
};

export default Login;
