import { useState, useEffect } from "react";
import CustomInputField from "./CustomInputField";

import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Chip,
  Stack,
  CircularProgress,
} from "@mui/material";

import getCityStateAndCountry from "../../utils/helpers/getCityStateAndCountry";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import styles from "../../assets/styles/forms/locationAutoComplete.styles";

const LocationAutoComplete = ({
  label,
  placeholder,
  locationType,
  data,
  setData,
  address,
  setAddress,
  selectedLocation,
  setSelectedLocation,
  error,
  helperText,
  isFilledInput,
  types,
  isCheckOut = false,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [latLong, setLatLong] = useState({});

  const handleChange = (newAddress) => {
    setOpen(true);
    setAddress(newAddress);

    if (locationType === "address" && newAddress.length === 0) {
      if (isCheckOut) {
        setData({
          ...data,
          shippingAddress: newAddress,
        });
      } else {
        setData({
          ...data,
          address: newAddress,
        });
      }
    }
  };

  const getCityAndStateAndCountry = async () => {
    setLoading(true);
    geocodeByAddress(address)
      .then((results) => {
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        if (isCheckOut) {
          setData({
            ...data,
            shippingAddress: address,
            shippingLatitude: latLng?.lat || null,
            shippingLongitude: latLng?.lng || null,
          });
        } else {
          const addressObj = {
            address: address,
            lat: latLng?.lat || null,
            long: latLng?.lng || null,
          };

          setData({
            ...data,
            address: addressObj,
          });
        }
      })
      .catch((error) => {
        console.error("Error in getting lat and lng", error);
      });
    setLoading(false);
  };

  const handleSelect = (address) => {
    setSelectedLocation(address);
    setAddress(address);
    setOpen(false);
  };

  useEffect(() => {
    if (selectedLocation) {
      getCityAndStateAndCountry();
    }
  }, [selectedLocation]);

  const renderInput = ({
    getInputProps,
    getSuggestionItemProps,
    suggestions,
  }) => {
    return (
      <>
        <Box sx={styles.inputWrapper}>
          <CustomInputField
            {...getInputProps({
              withasterisk: true,
              label: label ? label : null,
              type: "text",
              placeholder: placeholder ? placeholder : "Enter Location",
              error: error,
              helperText: helperText,
              isFilledInput: isFilledInput,
            })}
          />
          {open && (
            <List sx={styles.list} disablePadding>
              {suggestions?.map((suggestion) => {
                // Add a style of "suggestion" to the suggested locations
                return (
                  <ListItemButton
                    {...getSuggestionItemProps(suggestion)}
                    key={suggestion.placeId}
                    onClick={() => {
                      setSelectedLocation(suggestion);

                      setAddress(suggestion.description);

                      setOpen(false);
                    }}
                  >
                    <ListItem disablePadding>
                      <ListItemText>{suggestion.description}</ListItemText>
                    </ListItem>
                  </ListItemButton>
                );
              })}
            </List>
          )}
        </Box>
      </>
    );
  };

  // Limit the suggestions to show only cities,  province/state/region, country, and area
  const searchOptions = {
    types: types,
    // componentRestrictions: { country: "us" },
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <PlacesAutocomplete
          value={address}
          onChange={handleChange}
          searchOptions={searchOptions}
          onSelect={handleSelect}
        >
          {renderInput}
        </PlacesAutocomplete>

        {locationType === "address" && loading && (
          <Box sx={[styles.loaderWrapper]} mt={loading ? 2 : 0}>
            <CircularProgress sx={styles.loader} />
          </Box>
        )}
      </Box>
    </>
  );
};

export default LocationAutoComplete;
