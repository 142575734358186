import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Collapse,
  FormControl,
  RadioGroup,
  Radio,
  Avatar,
} from "@mui/material";
import { ReactComponent as MasterCard } from "../../../assets/svg/MasterCard.svg";
import AddToCartStep from "../checkout/checkoutSteps/AddtoCartStep";
import { Add, ExpandLess, ExpandMore, Remove } from "@mui/icons-material";
import SelectInput from "../../../components/forms/SelectInput";
import Logo from "../../../assets/images/LOGO.png";
import PlaceHolderImg from "../../../assets/images/PlaceholderImage.jpg";
import BaicCardImage from "../../../assets/images/basicCard.svg";
import PremiumCardImage from "../../../assets/images/premiumCard.svg";

import {
  ColorlibConnector,
  ColorlibStepIcon,
} from "../../../components/common/CustomStepper";
import CheckOutStep from "../checkout/checkoutSteps/CheckoutStep";
import OrderCompleteStep from "../checkout/checkoutSteps/OrderCompleteStep";
import { get } from "../../../server";
import {
  PRINT_ORDER_CARD_PRICING_LISTING,
  CARDS_DETAIL,
} from "../../../data/constants/apiRoutes";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import queryNames from "../../../data/constants/queryNames";
import { useSnackbar } from "notistack";
import Loader from "../../../components/Loader";
import styles from "../../../assets/styles/customer/orderCard.styles";
import FormLabel from "../../../components/forms/FormLabel";
import { QRCode } from "react-qrcode-logo";

import awsFileUpload from "../../../utils/helpers/awsFileUpload";
import generateUniqueFileName from "../../../utils/helpers/generateUniqueFileName";
import LoadingButton from "../../../components/button/LoadingButton";
import { toPng } from "html-to-image";
import LoadingBackDrop from "../../../components/overlay/LoadingBackDrop";
import useValidateImageSize from "../../../hooks/useValidateImageSize";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";

const steps = ["Add to cart", "Checkout", "Order Complete"];
const colors = ["#135099", "#303030", "#307169", "#D3A23B", "#E23E67"];

const PersonalOrderCard = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [address, setAddress] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [total, setTotal] = useState(0);
  const [cart, setCart] = useState([]);
  const [pricingQueryDone, setPricingQueryDone] = useState(false);
  const [prices, setPrices] = useState({});
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState({});
  const [cardType, setCardType] = useState("basic");
  const [quantity, setQuantity] = useState(1);
  const [selectedColor, setSelectedColor] = useState(colors[0]);
  const [logoImage, setLogoImage] = useState(null);

  const [orderCardsData, setOrderCardsData] = useState({
    stripeId: null,
    membersCard: [],
    shippingLatitude: null,
    shippingLongitude: null,
    totalPrice: 0,
    shippingAddress: "",
    paymentType: "",
    paymentMethod: "",
    color: null,
    logo: null,
    memberCount: null,
    plan: {},
    qty: null,
  });
  const avatarRef = useRef();
  const { cardId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { validateImageSize } = useValidateImageSize();

  const isId = cardId !== null && typeof cardId !== "undefined";

  const handleColorClick = (color) => {
    setSelectedColor(color);
  };

  const handleAvatarClick = () => {
    avatarRef.current.click();
  };

  const handleAvatarChange = (e) => {
    const selectedFile = e.target.files[0];

    if (!validateImageSize(selectedFile)) {
      return;
    }
    setLogoImage(selectedFile);
    // const selectedFile = URL.createObjectURL(e.target.files[0]);
    // setProfilePhoto(selectedFile);
    // setModal("image");
    // setUser({ ...user, image: selectedFile });
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const fetchSingleCard = async () => {
    const { status, message, data } = await get(`${CARDS_DETAIL}/${cardId}`);

    if (status) {
      let preparedData = [];

      preparedData.push(data);

      setCart(preparedData);
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const fetchPricing = async () => {
    let { status, message, data } = await get(PRINT_ORDER_CARD_PRICING_LISTING);

    if (status) {
      const basicPricing = data.find((pricing) => pricing.name === "basic");
      const premiumPricing = data.find((pricing) => pricing.name !== "basic");
      setPrices({
        basicPricing: parseFloat(basicPricing.price),
        premiumPricing: parseFloat(premiumPricing.price),
      });
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
    setPricingQueryDone(true);
  };

  const pricingQuery = useQuery(queryNames.PRICING, fetchPricing, {
    enabled: isId,
    refetchOnWindowFocus: false,
  });

  const singleCardQuery = useQuery([queryNames.CARD, cardId], fetchSingleCard, {
    enabled: isId && pricingQueryDone,
    refetchOnWindowFocus: false,
  });

  const calculateSubtotal = () => {
    const subtotal = cart?.reduce((total, item) => {
      return (
        total +
        (cardType === "basic"
          ? prices?.basicPricing
          : prices?.premiumPricing * quantity)
      );
    }, 0);

    return subtotal.toFixed(2);
  };

  useEffect(() => {
    const subtotal = calculateSubtotal();

    setTotal(subtotal);

    setOrderCardsData({
      ...orderCardsData,
      color: selectedColor,
      logo: cart[0]?.logoImage
        ? cart[0]?.logoImage
        : "https://knocknoccards.s3.us-east-1.amazonaws.com/knocknock/createCard/blueLogo.png",
      memberCount: cart?.length,
      plan: {
        id: cardType === "basic" ? 1 : 2,
        name: cardType === "basic" ? "Basic" : "Premium",
      },
      qty: quantity,
      totalPrice: parseFloat(subtotal),
      membersCard: cart.map(
        ({ id, fullName, qrCodeUrl, cardEmails, profileImage, uuid }) => ({
          cardId: id,
          qrCode: qrCodeUrl,
          name: fullName,
          email: cardEmails?.[0]?.email,
          profile: profileImage,
          uuid: uuid,
          pricingId: cardType === "basic" ? 1 : 2,
        })
      ),
    });
  }, [cart, cardType, quantity, selectedColor]);

  if (pricingQuery.isFetching || singleCardQuery.isFetching) {
    return <Loader />;
  }

  return (
    <Box sx={{ position: "relative", p: 1.5 }}>
      <LoadingBackDrop loading={loading} />
      <>
        <Box my={2}>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel
                  StepIconComponent={ColorlibStepIcon}
                  sx={{
                    "& .MuiStepLabel-label": {
                      color: activeStep === index ? "#135099" : "#1E1E1F",
                      fontSize: "16px",
                      fontWeight: 600,
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        {activeStep === 0 ? (
          <Grid container mt={5} spacing={2}>
            <Grid item md={8} xs={12}>
              {cart.length > 0 ? (
                <>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={cardType}
                      name="radio-buttons-group"
                      onChange={(e) => setCardType(e.target.value)}
                      sx={{
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Box>
                        <Box
                          sx={styles.basicImageWrapper}
                          onClick={() => setCardType("basic")}
                        >
                          <Box
                            component="img"
                            src={BaicCardImage}
                            alt="Basic Card"
                            width="100%"
                          />
                        </Box>

                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          my={1}
                        >
                          <FormControlLabel
                            value="basic"
                            control={<Radio />}
                            label="Basic"
                            sx={styles.radioBtn}
                          />
                          <Typography sx={styles.price}>
                            ${prices?.basicPricing}
                          </Typography>
                        </Stack>
                      </Box>
                      <Box>
                        <Box
                          sx={styles.basicImageWrapper}
                          onClick={() => setCardType("premium")}
                        >
                          <Box
                            component="img"
                            src={PremiumCardImage}
                            alt="Basic Card"
                          />
                        </Box>

                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          my={1}
                        >
                          <FormControlLabel
                            value="premium"
                            control={<Radio />}
                            label="Premium"
                            sx={styles.radioBtn}
                          />
                          <Typography sx={styles.price}>
                            ${prices?.premiumPricing}
                          </Typography>
                        </Stack>
                      </Box>
                    </RadioGroup>
                  </FormControl>
                  {cart.map((item, index) => {
                    return (
                      <Stack
                        direction="column"
                        sx={styles.cartItems}
                        key={index}
                        // alignItems="center"
                        // gap={2}
                        flexWrap="wrap"
                        mb={1}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          flexWrap="wrap"
                        >
                          <Typography sx={styles.cardName}>
                            <Box component="span">Card Name:</Box>
                            {` ${
                              item?.cardName ? item?.cardName : item?.fullName
                            }`}
                          </Typography>
                        </Stack>

                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          flexWrap="wrap"
                        >
                          <Typography sx={styles.cardName}>
                            <Box component="span">Owner Name:</Box>

                            {` ${item?.userData?.first_name} ${item?.userData?.last_name}`}
                          </Typography>
                        </Stack>
                      </Stack>
                    );
                  })}
                </>
              ) : (
                <Box sx={styles.noData}>
                  <Typography component="h1">No card found</Typography>
                </Box>
              )}
            </Grid>
            <Grid item md={4} xs={12}>
              <Box sx={styles.rightWrapper}>
                <Typography component="h4">Summary</Typography>
                <Box sx={styles.forMeWrapper} mb={2}>
                  <Box sx={styles.activeForWrapper}>For Me</Box>
                  <Box sx={styles.nonActiveForWrapper}>For my team</Box>
                </Box>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={2}
                >
                  <Typography component="h6" sx={styles.generalHeading}>
                    Quantity:
                  </Typography>

                  <Stack
                    sx={styles.qtyBtnContainer}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    flexWrap="wrap"
                  >
                    <Button
                      disabled={quantity <= 1}
                      onClick={() =>
                        setQuantity((prevValue) => {
                          return prevValue - 1;
                        })
                      }
                      sx={[styles.qtyBtn, styles.decreaseBtn]}
                    >
                      <Remove sx={{ fill: "#000" }} />
                    </Button>

                    <Typography component="label">{quantity}</Typography>

                    <Button
                      onClick={() =>
                        setQuantity((prevValue) => {
                          return prevValue + 1;
                        })
                      }
                      sx={[styles.qtyBtn, styles.increaseBtn]}
                    >
                      <Add sx={{ fill: "#fff" }} />
                    </Button>
                  </Stack>
                </Stack>
                <Stack sx={styles.subtotal} pb={1}>
                  <Typography component="h6">Unit Price</Typography>
                  <Typography component="h6">
                    $
                    {cardType === "basic"
                      ? prices?.basicPricing
                      : prices?.premiumPricing}
                  </Typography>
                </Stack>
                <Stack sx={styles.subtotal} pb={1}>
                  <Typography component="h6">Subtotal</Typography>
                  <Typography component="h6">
                    {`${quantity} x ${
                      cardType === "basic"
                        ? prices?.basicPricing
                        : prices?.premiumPricing
                    }`}
                  </Typography>
                </Stack>

                <Stack sx={[styles.subtotal, styles.total]}>
                  <Typography component="h6">Pay Now</Typography>
                  <Typography sx={styles.price}>${total}</Typography>
                </Stack>
                {cardType === "basic" ? null : (
                  <Box mt={2}>
                    <Typography sx={styles.generalHeading}>
                      Select Colors:
                    </Typography>
                    <Stack direction="row" spacing="12px" mt={1} mb={2}>
                      {colors.map((myColor) => {
                        return (
                          <Box
                            key={myColor}
                            sx={{
                              ...styles.color,
                              bgcolor: myColor,
                              border:
                                selectedColor === myColor
                                  ? "2px solid #767D90"
                                  : "",
                            }}
                            onClick={() => handleColorClick(myColor)}
                          >
                            {selectedColor === myColor && (
                              <Box component="div" sx={styles.selectedColor} />
                            )}
                          </Box>
                        );
                      })}
                    </Stack>
                    <Typography
                      sx={[styles.generalHeading, styles.divider]}
                      mb={1}
                    >
                      Logo:
                    </Typography>

                    <Box position="relative">
                      {/* <Box sx={styles.banner} rowGap="16px" onClick={handleCoverClick}>
          <Box
            component="img"
            src={
              user?.cover_image
                ? typeof user?.cover_image === "string"
                  ? `${GET_IMAGE}/${user?.cover_image}`
                  : URL.createObjectURL(user?.cover_image)
                : ProfileBanner
            }
            sx={styles.bannerImg}
          />
          {!user?.cover_image && (
            <Stack sx={styles.uploadStack}>
              <UploadPic />
              <Box component="label">Upload cover photo</Box>
            </Stack>
          )}
        </Box> */}

                      <Avatar
                        sx={styles.avatar}
                        onClick={handleAvatarClick}
                        src={
                          logoImage
                            ? typeof logoImage === "string"
                              ? `${logoImage}`
                              : URL.createObjectURL(logoImage)
                            : cart?.[0]?.logoImage
                            ? cart?.[0]?.logoImage
                            : "https://knocknoccards.s3.us-east-1.amazonaws.com/knocknock/createCard/blueLogo.png"
                        }
                      >
                        <CameraAltOutlinedIcon />
                      </Avatar>
                      <input
                        type="file"
                        accept="image/*"
                        hidden
                        onChange={handleAvatarChange}
                        id="avatarUpload"
                        ref={avatarRef}
                      />
                    </Box>
                  </Box>
                )}

                {/* <Typography sx={styles.coupon}>Have a coupon?</Typography> */}
                {/* <Typography component="h5">
                Shipping, taxes, and discounts added at checkout.
              </Typography> */}
                {/* <Stack sx={styles.card}>
                <MasterCard />
                <Box>
                  <Typography>Digital Card No</Typography>
                  <Typography>**** **** 7894</Typography>
                </Box>
              </Stack> */}
              </Box>
              <Button
                variant="contained"
                sx={styles.checkoutBtn}
                disableElevation
                fullWidth
                onClick={handleNext}
                disabled={
                  orderCardsData?.membersCard?.length < 1 ? true : false
                }
              >
                Proceed to Checkout
              </Button>
            </Grid>
          </Grid>
        ) : activeStep === 1 ? (
          <CheckOutStep
            handleNext={handleNext}
            handleBack={handleBack}
            setAddress={setAddress}
            address={address}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            totalCards={cart.length}
            orderCardsData={orderCardsData}
            setOrderCardsData={setOrderCardsData}
            setOrderData={setOrderData}
            isPersonalCard={true}
            logoImage={logoImage}
            cardType={cardType}
          />
        ) : (
          <OrderCompleteStep orderData={orderData} />
        )}
      </>
    </Box>
  );
};

export default PersonalOrderCard;
