const styles = {
	autocomplete: {
		"& .MuiOutlinedInput-root": {
			// backgroundColor: "#F9F9F9",
			minHeight: 51,
			borderRadius: "10px",
			"& fieldset": {
				border: "1px solid #EEE",
			},
			"&:hover fieldset": {
				borderColor: "#656DFC",
			},
			"&.Mui-focused fieldset": {
				borderColor: "#656DFC",
			},
			"& .Mui-disabled": {
				fontWeight: 700
			}
		},
		"& .MuiOutlinedInput-notchedOutline": {
			border: "1px solid #E2E3E4",
			borderRadius: "10px",
		},
		"& .Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: "#2B2A69 !important",
			borderWidth: "1px !important",
		},

		"& input": {
			borderRadius: "10px",
			"&::placeholder": {
				color: "#B4B1B6",
				fontWeight: 400,
				opacity: 1,
				fontSize: 14,
			},
		},
	},
	"& .MuiFormHelperText-root": {
		marginLeft: 0,
	},
	helperText: {
		marginLeft: "14px",
		fontSize: 14,
		color: "#d32f2f",
	},

	error: {
		"& p": {
			fontSize: 14,
		},
		"& .MuiOutlinedInput-notchedOutline": {
			border: "1px solid #d32f2f !important",
		},
	},
};

export default styles;
