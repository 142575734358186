import { Box, CircularProgress } from "@mui/material";
import styles from "../../assets/styles/overlay/loadingOverlay.styles";

const LoadingOverlay = ({ loading }) => {
  if (loading) {
    return (
      <Box sx={styles.container}>
        <Box component="div" sx={styles.box}>
          <CircularProgress sx={styles.loader} />
        </Box>
      </Box>
    );
  } else {
    return <></>;
  }
};

export default LoadingOverlay;
