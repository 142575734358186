const styles = {
  input: {
    "& .MuiInputBase-root ": {
      borderBottom: 0
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "transparent",
      minHeight: 66,
      borderRadius: 0,
      p: 0,
      "& fieldset": {
        border: "1px solid #EEE",
      },
      "&:hover fieldset": {
        borderColor: "#656DFC",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#656DFC",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #AEAEAE",
      borderRadius: "10px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#656DFC",
      borderWidth: "1px !important",
    },
    "& input": {
      backgroundColor: "transparent",
      color: "#1E1E1F",
      fontSize: 16,
      px: "18px"
    },
    "& textarea": {
      backgroundColor: "transparent",
      p: "20px",
      height: 100,
      "&::placeholder": {
        color: "#B4B1B6",
        fontWeight: 400,
        opacity: 1,
        fontSize: 15,
      },
    },
    "& .MuiFormHelperText-root": {
      marginLeft: 0,
    },
  },
  "& .MuiFormHelperText-root": {
    marginLeft: 0,
  },

  error: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #d32f2f !important",
    },
  },
};

export default styles;
