import { useState } from "react";
import PageTitle from "../../../components/common/PageTitle";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { get } from "../../../server";
import { useSnackbar } from "notistack";
import queryNames from "../../../data/constants/queryNames";
import { CUSTOMER_ORDER_DETAIL } from "../../../data/constants/apiRoutes";
import Loader from "../../../components/Loader";
import { Avatar, Box, Button, Grid, Stack, Typography } from "@mui/material";
import { ReactComponent as RightArrow } from "../../../assets/svg/RightArrow.svg";
import routes from "../../../data/constants/routes";
import moment from "moment";
import PlaceHolderImg from "../../../assets/images/PlaceholderImage.jpg";

const styles = {
  card: {
    border: "1px solid #E6E6E9",
    p: 2,
    bgcolor: "#fff",
    borderRadius: 4,
    // mb: 2,
    // "& img": { width: 225, height: 355, border: "1px solid #E6E6E9" },
  },
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    mt: 5,
    "& h1": {
      fontSize: 24,
      color: "#000",
      fontWeight: 500,
    },
  },

  title: {
    color: "#000",
    fontSize: 18,
    fontWeight: 500,
    whiteSpace: "nowrap",
    mr: 0.5,
  },
  cancelBtn: {
    textTransform: "capitalize",
    color: "#000",
    bgcolor: "#E8EEF5",
    width: { md: 120, xs: 55 },
    height: 45,
    borderRadius: 2.5,
    fontWeight: 600,

    minWidth: { xs: 95 },
    "&:hover": {
      color: "#000",
      bgcolor: "#E8EEF5",
      opacity: 0.8,
    },
  },
  avatar: {
    height: 100,
    width: 250,
    borderRadius: "10px",
    bgcolor: "#fff",
    border: "1.5px solid #EEEEEE",
    p: 1,
    "& img": {
      objectFit: "contain",
      width: "100%",
    },

    // position: "absolute",
    // left: { lg: 50, md: 40, sm: 40 },
    // top: 160,
    cursor: "pointer",
    "& svg": {
      height: 75,
      width: 75,
      fill: "#135099",
    },
    // "@media (max-width: 768px)": {
    //   left: 20,
    //   top: 140,
    // },
  },
};
const CardOrderDetail = () => {
  const [orderDetail, setOrderDetail] = useState({});

  const { orderId } = useParams();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const isId = orderId !== null && typeof orderId !== "undefined";

  const fetchSingleOrder = async () => {
    const { status, message, data } = await get(
      `${CUSTOMER_ORDER_DETAIL}/${orderId}`
    );

    if (status) {
      let totalQuantity = 0;

      for (const order of data.orderDetail) {
        totalQuantity += parseInt(order.quantity);
      }
      setOrderDetail({ ...data, totalQuantity: totalQuantity });
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const singleOrderQuery = useQuery(
    [queryNames.ORDER, orderId],
    fetchSingleOrder,
    { enabled: isId, refetchOnWindowFocus: false }
  );

  if (singleOrderQuery.isFetching) {
    return <Loader />;
  }
  return (
    <>
      <Box my={1}>
        <Button
          variant="contained"
          sx={styles.cancelBtn}
          onClick={() => navigate(routes.CUSTOMER_CARD_ORDER_HISTORY)}
          disableElevation
        >
          <RightArrow className="mr-1" />
          Back
        </Button>
      </Box>
      <PageTitle title="Order Detail" />
      <Box sx={styles.card}>
        <Typography>
          <Typography component="span" sx={styles.title}>
            Order Id:
          </Typography>
          {orderDetail?.orderId}
        </Typography>
        <Typography mt={0.5}>
          <Typography component="span" sx={styles.title}>
            Order Type:
          </Typography>
          {orderDetail?.team?.name ? "Team" : "Personal"}
        </Typography>
        <Typography mt={0.5}>
          <Typography component="span" sx={styles.title}>
            Team Name:
          </Typography>
          {orderDetail?.team?.name ? orderDetail?.team?.name : "-"}
        </Typography>

        <Typography mt={0.5}>
          <Typography component="span" sx={styles.title}>
            Name:
          </Typography>
          {`${orderDetail?.user?.first_name} ${orderDetail?.user?.last_name}`}
        </Typography>
        <Typography mt={0.5}>
          <Typography component="span" sx={styles.title}>
            Email:
          </Typography>
          {orderDetail?.user?.email}
        </Typography>
        <Typography mt={0.5}>
          <Typography component="span" sx={styles.title}>
            Shipping Address:
          </Typography>
          {orderDetail.shippingAddress}
        </Typography>
        <Typography mt={0.5}>
          <Typography component="span" sx={styles.title}>
            {orderDetail?.team?.name ? "Quantity Per Member" : "Quantity"}:
          </Typography>
          {orderDetail?.orderDetail?.[0]?.quantity}
        </Typography>
        {orderDetail?.team?.name ? (
          <>
            <Typography mt={0.5}>
              <Typography component="span" sx={styles.title}>
                Total Members:
              </Typography>
              {orderDetail?.orderDetail?.length}
            </Typography>
            <Typography mt={0.5}>
              <Typography component="span" sx={styles.title}>
                Total Cards:
              </Typography>
              {`${orderDetail?.orderDetail?.length} x ${orderDetail?.orderDetail?.[0]?.quantity} = ${orderDetail?.totalQuantity}`}
            </Typography>
          </>
        ) : null}

        <Typography mt={0.5}>
          <Typography component="span" sx={styles.title}>
            Unit Price:
          </Typography>
          $ {orderDetail?.orderDetail?.[0]?.pricing?.price}
        </Typography>
        {orderDetail?.team?.name ? (
          <Typography mt={0.5}>
            <Typography component="span" sx={styles.title}>
              Total Price:
            </Typography>

            {`${orderDetail?.totalQuantity} x ${orderDetail?.orderDetail?.[0]?.pricing?.price} = $ ${orderDetail?.totalPrice}`}
          </Typography>
        ) : (
          <Typography mt={0.5}>
            <Typography component="span" sx={styles.title}>
              Total Price:
            </Typography>
            $ {orderDetail?.totalPrice}
          </Typography>
        )}

        <Typography mt={0.5}>
          <Typography component="span" sx={styles.title}>
            Date:
          </Typography>
          <Typography component="span">
            {moment(orderDetail?.created_at).format("DD/MMM/YYYY h:mm:ss A")}
          </Typography>
        </Typography>
        <Typography mt={0.5}>
          <Typography component="span" sx={styles.title}>
            Status:
          </Typography>
          <Typography component="span" sx={{ textTransform: "capitalize" }}>
            {orderDetail?.status}
          </Typography>
        </Typography>

        <Stack direction="row" alignItems="center" gap={0.5} mt={0.5}>
          <Typography sx={styles.title}>Selected Color:</Typography>
          <Box
            sx={{
              width: 20,
              height: 20,
              borderRadius: "50%",
              bgcolor: orderDetail?.orderDetail?.[0]?.color,
            }}
          />
        </Stack>
        <Stack direction="row" alignItems="center" gap={0.5} mt={1}>
          <Typography sx={styles.title}>Logo:</Typography>
          <Box position="relative">
            <Avatar
              sx={styles.avatar}
              src={orderDetail?.orderDetail?.[0]?.logo}
            />
          </Box>
        </Stack>
      </Box>

      <PageTitle
        title={orderDetail?.team?.name ? "Team Detail" : "Card Detail"}
      />
      {orderDetail?.orderDetail?.length > 0 ? (
        <Grid container spacing={2}>
          {orderDetail?.orderDetail?.map((order) => {
            return (
              <Grid item md={12} xs={12} key={order?.id}>
                <Box sx={styles.card}>
                  <Stack direction="row" alignItems="center" columnGap={2}>
                    <Avatar
                      width={50}
                      height={50}
                      src={
                        order?.card?.profileImage
                          ? order?.card?.profileImage
                          : PlaceHolderImg
                      }
                    />
                    <Box>
                      <Typography component="div">
                        {order?.card?.fullName}
                      </Typography>
                      <Typography component="div">
                        {order?.card?.cardEmails?.[0]?.email}
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Box sx={styles.noData}>
          <Typography component="h1">No record found</Typography>
        </Box>
      )}
    </>
  );
};

export default CardOrderDetail;
