import {
	Box,
	Button,
	Divider,
	FormControl,
	InputAdornment,
	OutlinedInput,
	Stack,
	Typography,
} from "@mui/material";
import { ReactComponent as Search } from "../../../assets/svg/Search.svg";
import { ReactComponent as BankCard } from "../../../assets/svg/BankCard.svg";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import routes from "../../../data/constants/routes";

const styles = {
	title: {
		justifyContent: "space-between",
		alignItems: "center",
		"& h2": {
			color: "#1E1E1F",
			fontSize: 24,
			fontStyle: "normal",
			fontWeight: 500,
			lineHeight: "36px",
		},
	},
	searchInput: {
		maxWidth: 340,
		width: "100%",
		backgroundColor: "#FFF",
		borderRadius: "10px",
		"& .MuiOutlinedInput-notchedOutline": {
			borderRadius: "10px",
			border: "1px solid #EEE",
		},
		"& input": {
			py: "13px",
			px: "21px",
			"&::placeholder": {
				color: "#767D90",
				fontWeight: 400,
				fontSize: 16,
			},
		},
	},
	container: {
		alignItems: "center",
		maxWidth: 675,
		"& h5": {
			color: "#000",
			fontSize: 24,
			fontWeight: 600,
			lineHeight: "36px",
			mt: "34px",
			mb: 1,
		},
		"& p": {
			color: "#4B4B4B",
			fontSize: 18,
			fontFamily: "Segoe UI",
			textAlign: "center",
		},
		"& button": {
			bgcolor: "#135099",
			color: "#FFF",
			borderRadius: "10px",
			fontSize: 16,
			width: "100%",
			maxWidth: 350,
			textTransform: "capitalize",
			mt: "45px",
			"&:hover": {
				bgcolor: "#135099",
				color: "#FFF",
			},
		},
	},
};

const ProductPayment = () => {
	const navigate = useNavigate();

	return (
		<>
			<Stack direction="row" sx={styles.title}>
				<Typography component="h2">Payment</Typography>

				<FormControl variant="outlined" sx={styles.searchInput}>
					<OutlinedInput
						size="small"
						type="text"
						endAdornment={
							<InputAdornment position="end">
								<Search />
							</InputAdornment>
						}
						placeholder="Search..."
					/>
				</FormControl>
			</Stack>

			<Divider sx={{ borderColor: "#EBEBEE", my: "26px" }} />

			<Box display="flex" justifyContent="center" mt={20}>
				<Stack sx={styles.container}>
					<BankCard />
					<Typography component="h5">No Card Added Yet</Typography>
					<Typography component="p">
						Lorem Ipsum is simply dummy text the printing and typesetting
						industry. Lorem Ipsum has been the industry's standard dummy text
						ever since 1500s,
					</Typography>
					<Button variant="contained" onClick={() => navigate(routes.CUSTOMER_PAYMENT_CARD_ADD)}>
						<Add /> Add your first card
					</Button>
				</Stack>
			</Box>
		</>
	);
};

export default ProductPayment;
