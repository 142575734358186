import {
  createRoutesFromElements,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import CustomerRoutes from "./data/routes/CustomerRoutes";
import AdminRoutes from "./data/routes/AdminRoutes";
import MiscRoutes from "./data/routes/MiscRoutes";
import { useAtom } from "jotai";
import {
  mobileViewAtom,
  loggedUserAtom,
  appPackagesAtom,
  featureModal,
} from "./data/store";
import { useEffect } from "react";
import { get } from "./server";
import { SUBSCRIPTION_LISTING } from "./data/constants/apiRoutes";
import useRefreshUser from "./hooks/useRefreshUser";

import { useSnackbar } from "notistack";

const router = createBrowserRouter(
  createRoutesFromElements([AdminRoutes, CustomerRoutes, MiscRoutes])
);

function App() {
  const [mobileView, setMobileView] = useAtom(mobileViewAtom);
  const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);
  const [packages, setPackages] = useAtom(appPackagesAtom);
  const [globalFeatureModal, setGlobalFeatureModal] = useAtom(featureModal);

  const { refreshUser } = useRefreshUser();

  const { enqueueSnackbar } = useSnackbar();

  const resizeFunction = () => {
    if (window.innerWidth >= 768) {
      setMobileView(false);
    } else {
      setMobileView(true);
    }
  };

  useEffect(() => {
    // set global feature modal empty
    setGlobalFeatureModal(false);
    window.addEventListener("resize", resizeFunction);

    return function cleanup() {
      window.removeEventListener("resize", resizeFunction);
    };
  }, []);

  const getPackagesListing = async () => {
    const { status, message, data } = await get(SUBSCRIPTION_LISTING);

    if (status) {
      setPackages(data);
    } else {
      enqueueSnackbar(message, { variant: "error", message });
    }
  };

  useEffect(() => {
    if (loggedUser && loggedUser?.data?.is_password_otp_verified > 0) {
      refreshUser();
      getPackagesListing();
    }
  }, [loggedUser?.data?.id]);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
