import {
  Modal,
  Box,
  Stack,
  Typography,
  Button,
  IconButton,
  MenuItem,
} from "@mui/material";
import styles from "../../assets/styles/modal/contactUsStatusModal.styles";
import { Close } from "@mui/icons-material";
import SelectInput from "../forms/SelectInput";
import LoadingButton from "../button/LoadingButton";

const ContactUsStatusModal = ({
  open,
  handleClose,
  handleSubmit,
  title,
  confirmButtonColor,
  options,
  selectedData,
  setSelectedData,
  label,
  name,
  isLoading,
}) => {
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setSelectedData({ ...selectedData, [name]: value });
  };

  return (
    <>
      <Modal open={open} aria-describedby="modal-modal-description">
        <Box sx={styles.container}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">{title}</Typography>
            <Box sx={styles.closeIconBtnWrapper}>
              <IconButton onClick={handleClose} sx={styles.closeIconBtn}>
                <Close />
              </IconButton>
            </Box>
          </Stack>
          <Box mt={1}>
            <SelectInput
              label={label}
              name={name}
              value={selectedData.status}
              onChange={handleChange}
              //   error={errors?.parentCategory ? true : false}
              //   errorMessage={errors?.parentCategory || ""}
            >
              {options.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.value}>
                    {option.name}
                  </MenuItem>
                );
              })}
            </SelectInput>
          </Box>
          <Stack
            direction="row"
            sx={styles.btnsWrapper}
            justifyContent="center"
            spacing={2}
          >
            <Button
              variant="contained"
              sx={styles.cancelBtn}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              sx={styles.saveBtn}
              onClick={handleSubmit}
              isLoading={isLoading}
            >
              Save
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default ContactUsStatusModal;
