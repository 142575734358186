import Joi from "joi";

const PricingSchema = Joi.object({
  price: Joi.number().min(0).required().messages({
    "number.base": "Please enter price",
    "number.min": `Price must be minimum 0`,
  }),
});

export default PricingSchema;
