const styles = {
  uploadBtn: {
    textTransform: "capitalize",
    bgcolor: "#135099",
    color: "#FFF",
    height: "50px",
    fontSize: 16,
    fontWeight: 600,
    minWidth: 150,
    px: 2,
    boxShadow: 0,
    // borderRadius: "10px",
    "&:hover": {
      bgcolor: "#135099",
      color: "#FFF",
    },
  },

  button: {
    mb: 3,
    borderRadius: "10px",
    bgcolor: "#135099",
    color: "#FFF",
    minWidth: { md: 260, xs: "100%" },
    fontSize: 16,
    height: "50px",
    fontWeight: 500,
    textTransform: "capitalize",
  },
  downloadButton: {
    borderRadius: "10px",
    bgcolor: "#135099",
    color: "#FFF",
    // width: { md: 200, xs: "100%" },
    fontSize: 16,
    height: "50px",
    fontWeight: 500,
    textTransform: "capitalize",
  },
  submitButton: {
    mt: { md: "30px", xs: "25px" },
    borderRadius: "10px",
    bgcolor: "#135099",
    color: "#FFF",
    minWidth: { md: 260, xs: "100%" },
    fontSize: 16,
    height: "50px",
    fontWeight: 500,
    textTransform: "capitalize",
  },
  tableWrapper: {
    bgcolor: "#fff",
    p: 1,
    borderRadius: 1,
  },
  tableContainer: {
    "&.MuiTableContainer-root": {
      boxShadow: "none",
      borderRadius: 0,
      "& .MuiTableCell-root": {
        border: 0,
        px: 2.5,
      },
    },
  },
  tableHeader: {
    bgcolor: "#F9F9F9",
  },
  tableHeading: {
    fontWeight: 600,
    whiteSpace: "nowrap",
  },
  evenTableRow: {
    "&:nth-of-type(even)": { bgcolor: "#F9F9F9" },
  },
  link: {
    textDecoration: "none",
    color: "#656DFC",
  },
  name: {
    color: "#5C5C5C",
    fontSize: 14,
    fontWeight: 700,
  },
  linkName: {
    color: "#656DFC",
    fontSize: 14,
    fontWeight: 700,
    "&:hover": {
      textDecoration: "underline",
      color: "#656DFC",
    },
  },
  price: {
    color: "#EE4730",
  },
  description: {
    color: "#5C5C5C",
  },
  fileBox: {
    height: 130,
    p: { md: "0 56px", xs: 2.5 },
    bgcolor: " #fff",
    border: "2px dashed #e2e3e4",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: { md: "row", xs: "column" },
    cursor: "pointer",
  },
  fileText: {
    color: "#767D90",
    fontWeight: 400,
    cursor: "pointer",
    textAlign: { md: "left", xs: "center" },
    fontSize: { md: 14, xs: 12 },
  },
  imgBtn: {
    bgcolor: "#FFFFFF",
    color: "#135099",
    border: " 1px solid #135099",
    cursor: "pointer",
    textTransform: "capitalize",
    fontWeight: 600,
    "&:hover": {
      bgcolor: "#E2E3E4",
    },
  },
  cancelBtn: {
    textTransform: "capitalize",
    color: "#000",
    bgcolor: "#E8EEF5",
    width: { md: 120, xs: 55 },
    height: 45,
    borderRadius: 2.5,
    fontWeight: 600,

    minWidth: { xs: 95 },
    "&:hover": {
      color: "#000",
      bgcolor: "#E8EEF5",
      opacity: 0.8,
    },
  },

  pagination: {
    mt: 4,
    "& .MuiPagination-ul": {
      justifyContent: "center",
    },
    "& .MuiPaginationItem-root": {
      backgroundColor: "transparent", // Add background color to all page numbers
      border: "none",
      margin: "0 10	px",
      color: "#000",
      // fontSize: 17,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    "&  .MuiPaginationItem-previousNext": {
      // width: 40,
      // height: 40,
      backgroundColor: "#2B2A69",
      color: "#FFF",
      "&:hover": {
        backgroundColor: "#2B2A69",
        color: "#FFF",
      },
      "&.Mui-disabled": {
        backgroundColor: "#FFF",
        color: "#B8B8B8",
        border: "1px solid #AEAEAE",
      },
      "& svg": {
        // height: 25,
        // width: 25,
      },
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      color: "#293277",
    },
  },
};

export default styles;
