import { Backdrop, CircularProgress, Stack } from "@mui/material";

const LoadingBackDrop = ({ loading, children }) => {
  return (
    <>
      <Backdrop
        sx={{
          color: "#D3A23B",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          gap={1}
        >
          <CircularProgress color="inherit" />
          {children}
        </Stack>
      </Backdrop>
    </>
  );
};

export default LoadingBackDrop;
