import { useAtom } from "jotai";
import { loggedUserAtom } from "../../data/store";
import { useNavigate, Outlet } from "react-router-dom";
import routes from "../../data/constants/routes";

const CheckAuth = () => {
	const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);
	const navigate = useNavigate();

	if (!loggedUser?.token) {
		navigate(routes.CUSTOMER_LOGIN);
	} else if (loggedUser?.token && loggedUser?.data?.email_verified_at === 0) {
		navigate(routes.CUSTOMER_VERIFY_ACCOUNT);
	} else if(loggedUser?.token && loggedUser?.data?.is_superadmin === "1")
		{navigate(routes.ADMIN_CUSTOMERS)}
	return <Outlet />;
};

export default CheckAuth;
