import { useEffect, useState } from "react";
import SplashForm from "../../../components/auth/SplashForm";
import routes from "../../../data/constants/routes";
import { InputAdornment, IconButton, Box } from "@mui/material";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { loggedUserAtom } from "../../../data/store";
import { validator } from "../../../utils/helpers/validator";
import { ResetPasswordSchema } from "../../../utils/schemas/AuthSchema";
import { post } from "../../../server";
import { CUSTOMER_RESET_PASSWORD } from "../../../data/constants/apiRoutes";
import FormInput from "../../../components/forms/FormInput";

const NewPassword = () => {
	const [newPasswordForm, setNewPasswordForm] = useState({
		password: "",
		confirm_password: "",
	});

	const [errors, setErrors] = useState({});

	const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleClickShowConfirmPassword = () =>
		setShowConfirmPassword((show) => !show);

	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;

		setNewPasswordForm({ ...newPasswordForm, [name]: value });
	};

	const validateForm = () => {
		let newErrors = validator(newPasswordForm, ResetPasswordSchema);
		setErrors(newErrors);
		if (Object.keys(newErrors).length) return false;
		return true;
	};

	const handleResetPassword = async () => {
		const { status, message } = await post(CUSTOMER_RESET_PASSWORD, {
			id: loggedUser.id,
			...newPasswordForm,
		});
		if (status === true) {
			enqueueSnackbar(message, { variant: "success" });
			navigate(routes.CUSTOMER_LOGIN);
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};

	const mutation = useMutation(handleResetPassword);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!validateForm()) return;
		mutation.mutate();
	};

	useEffect(() => {
		if (errors.password || errors.confirm_password) {
			enqueueSnackbar(errors.password || errors.confirm_password, {
				variant: "error",
			});
		}
	}, [errors]);

	return (
		<>
			<SplashForm
				title="Create New Password"
				btnText="Reset Password"
				tagline="Enter your new strong password."
				handleSubmit={handleSubmit}
				isLoading={mutation.isLoading}
			>
				<>
					<FormInput
						type={showPassword ? "text" : "password"}
						name="password"
						value={newPasswordForm.password}
						onChange={handleChange}
						error={errors.password}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={handleClickShowPassword}>
										{showPassword ? (
											<VisibilityOffOutlinedIcon />
										) : (
											<VisibilityOutlinedIcon />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
						placeholder="Password"
					/>

					<FormInput
						type={showConfirmPassword ? "text" : "password"}
						name="confirm_password"
						value={newPasswordForm.confirm_password}
						onChange={handleChange}
						error={errors.confirm_password}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={handleClickShowConfirmPassword}>
										{showConfirmPassword ? (
											<VisibilityOffOutlinedIcon />
										) : (
											<VisibilityOutlinedIcon />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
						placeholder="Confirm Password"
					/>

					<Box component="p" sx={{ color: "#D3A23B", fontSize: 14 }}>
						Both password must match.
					</Box>
				</>
			</SplashForm>
		</>
	);
};

export default NewPassword;
