import { Box } from "@mui/material";
import React from "react";

const styles = {
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "80vh",
  },
  loader: {
    border: "3px solid transparent",
    borderRadius: "50%",
    borderTop: " 3px solid #D3A23B",
    borderBottom: "3px solid #D3A23B",
    width: 50,
    height: 50,
    WebkitAnimation: "spin 1s linear infinite",
    /* Safari */
    animation: "spin 1s linear infinite",
    margin: "0 auto",
    "@keyframes spin": {
      "0%": {
        transform: "rotate(0deg)",
      },
      "100%": {
        transform: "rotate(360deg)",
      },
    },
  },
};

function Loader() {
  return (
    <>
      <Box sx={styles.wrapper}>
        <Box sx={styles.loader}></Box>
      </Box>
    </>
  );
}

export default Loader;
