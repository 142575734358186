import { FormControl, Select } from "@mui/material";
import styles from "../../assets/styles/forms/selectInput.styles";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "./FormLabel";

const SelectInput = ({
  label,
  error,
  errorMessage,
  withasterisk,
  children,
  style,
  height,
  isWidthySelect = false,
  ...other
}) => {
  const inputId = `input-${other.name}`;

  return (
    <>
      <FormLabel label={label} inputId={inputId} withasterisk={withasterisk} />

      <FormControl
        fullWidth
        error={error}
        style={style}
        sx={{
          ...styles.input,
          ...(error && {
            ...styles.error,
          }),
        }}
      >
        <Select
          fullWidth
          {...other}
          sx={{
            ...(isWidthySelect ? styles.widthySelect : styles.select),
            height: height && height,
          }}
        >
          {children}
        </Select>

        <FormHelperText
          sx={[styles.helperText, { fontSize: isWidthySelect && 14 }]}
        >
          {errorMessage}
        </FormHelperText>
      </FormControl>
    </>
  );
};

export default SelectInput;
