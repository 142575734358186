import { useEffect, useState } from "react";
import PageTitle from "../../../components/common/PageTitle";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { Box, FormHelperText } from "@mui/material";
import { get, post, put } from "../../../server";
import { PAGES } from "../../../data/constants/apiRoutes";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import { useMutation, useQuery } from "react-query";
import PageSchema from "../../../utils/schemas/PageSchema";
import { validator } from "../../../utils/helpers/validator";
import routes from "../../../data/constants/routes";
import LoadingButton from "../../../components/button/LoadingButton";
import Loader from "../../../components/Loader";
import queryNames from "../../../data/constants/queryNames";

const styles = {
  submitBtn: {
    bgcolor: "#135099",
    borderRadius: 2.5,
    mt: 2,
    minWidth: 130,
    height: 45,
  },
  error: {
    color: "#d32f2f",
  },
};

const initialData = {
  title: "Privacy Policy",
  pageType: "privacy",
  description: "",
};

const AboutUs = () => {
  const [aboutUs, setAboutUs] = useState(initialData);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(null);
  const [errors, setErrors] = useState({});

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const validateForm = () => {
    let newErrors = validator(aboutUs, PageSchema);
    setErrors(newErrors);

    if (Object.keys(newErrors).length) return false;

    return true;
  };

  const handleFormRequest = async () => {
    const { status, message } = await (isEdit ? put : post)(
      isEdit ? `${PAGES}/${editId}` : PAGES,
      aboutUs
    );
    if (status) {
      enqueueSnackbar(message, { variant: "success" });
      navigate(routes.ADMIN_MEMBERS);
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const mutation = useMutation(handleFormRequest);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    mutation.mutate();
  };

  const fetchPages = async () => {
    let { status, message, data } = await get(PAGES);
    if (status) {
      if (data?.length > 0) {
        const aboutData = data?.find((item) => item?.pageType === "about_us");

        setAboutUs({
          ...aboutUs,
          title: aboutData?.title || "About Us",
          pageType: aboutData?.pageType || "about_us",
          description: aboutData?.description,
        });
        if (aboutData) {
          setEditId(aboutData?.id);
          setIsEdit(true);
        } else {
          setIsEdit(false);
          setEditId(null);
        }
      } else {
        setIsEdit(false);
        setEditId(null);
      }
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const allPagesQuery = useQuery(queryNames.PAGE, fetchPages, {
    refetchOnWindowFocus: false,
  });

  if (allPagesQuery.isFetching) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle title="About Us" />
      <Box className={errors.description ? "ck-content errorInput" : "ck-content"} mt={2}>
        <CKEditor
          editor={ClassicEditor}
          config={{
            removePlugins: [
              "EasyImage",
              "ImageUpload",
              "Table",
              "Indent",
              "MediaEmbed",
            ],
          }}
          data={aboutUs.description}
          onChange={(event, editor) => {
            const data = editor.getData();
            setAboutUs({ ...aboutUs, description: data });
          }}
        />
      </Box>
      <FormHelperText sx={styles.error}>{errors.description}</FormHelperText>
      <Box>
        <LoadingButton
          variant="contained"
          disableElevation
          sx={styles.submitBtn}
          type="submit"
          onClick={handleSubmit}
          isLoading={mutation.isLoading}
        >
          Save
        </LoadingButton>
      </Box>
    </>
  );
};

export default AboutUs;
