import Joi from "joi";

const ContacUsSchema = Joi.object({
  name: Joi.string().max(50).required().messages({
    "string.empty": `Please enter full name`,
    "string.max": `Full name must be maximum 50 characters!`,
  }),
  email: Joi.string()
    .max(70)
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.empty": `Please enter email address`,
      "string.email": `Please enter a valid email address`,
      "string.max": `Email must be maximum 70 characters!`,
    }),

  message: Joi.string().max(4000).required().messages({
    "string.empty": `Please enter message`,
    "string.max": `Message must be maximum 4000 characters!`,
  }),
});

export default ContacUsSchema;
