import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FormLabel from "./FormLabel";

import React from "react";
import { Box, FormHelperText } from "@mui/material";

const styles = {
	error: {
		fontSize: 14,
		color: "#d32f2f",
		ml: "14px",
		"& .MuiOutlinedInput-notchedOutline": {
			border: "1px solid #d32f2f !important",
			borderRadius: "10px",
		},
	},
};

const CkEditor = ({ label, handleChange, error, ...other }) => {
	const inputId = `input-${other.name}`;
	return (
		<>
			<FormLabel label={label} inputId={inputId} />
			<Box
				className={
					error?.length > 0 ? "ck-content-form errorInput" : "ck-content-form"
				}
				inputId={inputId}
			>
				<CKEditor
					editor={ClassicEditor}
					config={{
						placeholder: other.placeholder,
						removePlugins: [
							"EasyImage",
							"ImageUpload",
							"Table",
							"Indent",
							"MediaEmbed",
						],
					}}
					data={other.value}
					onChange={(event, editor) => {
						const data = editor.getData();
						// setAboutUs({ ...aboutUs, description: data });
						handleChange(data);
					}}
				/>
			</Box>
			<FormHelperText sx={styles.error}>{error}</FormHelperText>
		</>
	);
};

export default CkEditor;
