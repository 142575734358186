// const styles = {
//   container: {
//     width: "100%",
//     height: "100%",
//     position: "absolute",
//     left: 0,
//     top: 0,
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     zIndex: 1100,
//     bgcolor: "rgba(255, 255, 255, 0.75)",
//     minHeight: "calc(100vh - 128px)",
//   },
//   loader: {
//     height: "47px !important",
//     width: "47px !important",
//   },
// };

// export default styles;

const styles = {
  container: {
    width: "100%",
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 99,
    bgcolor: "rgba(0, 0, 0,0.5)",
    minHeight: "100vh",
    borderRadius: 2,
  },
  box: {
    width: "70px",
    height: "70px",
    bgcolor: "#D3A23B",
    // bgcolor: "#135099",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
  },
  loader: {
    height: "35px !important",
    width: "35px !important",
    color: "#fff",
  },
};

export default styles;
