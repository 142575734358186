import { useState } from "react";
import PageTitle from "../../../components/common/PageTitle";
import { Box, Button, Grid } from "@mui/material";
import CustomInputField from "../../../components/forms/CustomInputField";

const styles = {
  wrapper: {
    bgcolor: "#fff",
    borderRadius: 5,
    p: 3,
    mt: 2,
  },
  submitBtn: {
    bgcolor: "#135099",
    borderRadius: 2.5,
    mt: 2,
    minWidth: 130,
    height: 45,
  },
};

const Contact = () => {
  const [contactForm, setContactForm] = useState({
    firstName: "",
    lastName: "",
    message: "",
  });
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
  };
  return (
    <>
      <PageTitle title="Contact Us" />
      <Box component="form" noValidate autoComplete="off">
        <Box sx={styles.wrapper}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <CustomInputField
                label="Full Name"
                name="fullName"
                value={contactForm.value}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={6}>
              <CustomInputField
                label="Last Name"
                name="lastName"
                value={contactForm.value}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={12}>
              <CustomInputField
                label="Message"
                name="lastName"
                value={contactForm.value}
                onChange={handleChange}
                multiline
                rows={5}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Button
        variant="contained"
        disableElevation
        sx={styles.submitBtn}
        type="submit"
      >
        Send
      </Button>
    </>
  );
};

export default Contact;
