import { useAtom } from "jotai";
import { loggedUserAtom } from "../../data/store";
import { Outlet, useNavigate } from "react-router-dom";
import routes from "../../data/constants/routes";

const CheckifLoggedIn = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);
  const navigate = useNavigate();

  if (!loggedUser?.token) {
    navigate(routes.CUSTOMER_LOGIN);
  } else if (loggedUser?.token && loggedUser?.data?.is_superadmin == 0) {
    // if (loggedUser?.data?.email_verified_at === "Y")

    navigate(routes.CUSTOMER_BUSINESS_CARD);
    // else
    //  navigate(routes.CUSTOMER_VERIFY_ACCOUNT);
  } else if (loggedUser?.token && loggedUser?.data?.is_superadmin == 1) {
    navigate(routes.ADMIN_CUSTOMERS);
  }

  // return <Outlet />;
};

export default CheckifLoggedIn;
