import {
	Box,
	Button,
	Divider,
	FormControl,
	Grid,
	InputAdornment,
	OutlinedInput,
	Stack,
	Typography,
} from "@mui/material";
import PageTitle from "../../../components/common/PageTitle";
import { ReactComponent as Scan } from "../../../assets/svg/Cards/Scan.svg";
import { ReactComponent as MasterCard } from "../../../assets/svg/Cards/Master Card.svg";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
// import Texture from "../../../assets/svg/Cards/Texture.png";
import { ReactComponent as Texture } from "../../../assets/svg/Cards/Paper.svg";

const styles = {
	cardContainer: {
		minWidth: 340,
		minHeight: 200,
		color: "#FFF",
	},
	topContainer: {
		position: "relative",
		overflow: "hidden",
		width: "100%",
		minHeight: 100,
		borderTopLeftRadius: "30px",
		borderTopRightRadius: "30px",
		boxShadow: "0px 0px 1px 0px rgba(0, 0, 0, 0.25)",
		bgcolor: "#135099",
		pt: 3,
		pl: "25px",
		pr: 5,
		"& h5": {
			color: "#FFF",
			fontSize: 24,
			fontWeight: 600,
			lineHeight: "26px",
		},
		"& h6": {
			color: "#FFF",
			fontSize: 14,
			lineHeight: "26px",
			textTranform: "uppercase",
		},
	},
	botContainer: {
		width: "100%",
		minHeight: 100,
		bgcolor: "#000",
		borderBottomLeftRadius: "30px",
		borderBottomRightRadius: "30px",
		pt: 3,
		pl: "25px",
		pr: 3,
		pb: "21px",
		"& h4": {
			color: "#FFF",
			fontSize: 22,
			fontWeight: 500,
			lineHeight: "26px",
		},
		"& h5": {
			color: "#FFF",
			fontSize: 13,
			fontWeight: 600,
			letterSpacing: "-0.055px",
			textTransform: "uppercase",
		},
	},
	addCard: {
		width: "100%",
		minHeight: 200,
		minWidth: 340,
		borderRadius: "30px",
		py: "50px",
		bgcolor: "#F3F7FD",
		border: "2px dashed #E6E6E9",
		cursor: "pointer",
		"& h5": {
			color: "#1E1E1F",
			fontSize: 16,
			fontWeight: 500,
		},
		"& svg": {
			fill: "#135099",
			width: 62,
			height: 62,
		},
	},
};

const CardListing = () => {
	const navigate = useNavigate();
	return (
		<>
			<PageTitle title="Add Card" />
			<Divider sx={{ borderColor: "#EBEBEE", my: "26px" }} />

			<Grid container spacing="30px">
				<Grid item>
					<Box sx={styles.cardContainer}>
						<Box sx={styles.topContainer}>
							{/* <Texture className="cardTexture" /> */}
							<Stack
								direction="row"
								justifyContent="space-between"
								alignItems="center"
								// zIndex={2}
							>
								<Stack>
									<Typography component="h5">John Doe</Typography>
									<Typography component="h6">Product Manager</Typography>
								</Stack>
								<Scan />
							</Stack>
						</Box>

						<Box sx={styles.botContainer}>
							<Stack
								direction="row"
								justifyContent="space-between"
								alignItems="center"
							>
								<Stack>
									<Typography component="h4">**** 5678</Typography>
									<Stack direction="row" gap={2} mt={1}>
										<Typography component="h5">Nick Ohmy</Typography>
										<Typography component="h5">05 / 24</Typography>
									</Stack>
								</Stack>
								<MasterCard />
							</Stack>
						</Box>
					</Box>
				</Grid>

				<Grid item>
					<Box sx={styles.addCard} onClick={() => navigate("/payment/addCard")}>
						<Stack alignItems="center">
							<Add />
							<Typography component="h5">Add Payment Method</Typography>
						</Stack>
					</Box>
				</Grid>
			</Grid>
		</>
	);
};

export default CardListing;
