import React, { useState } from "react";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import CustomPagination from "./CustomPagination";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
import { useSnackbar } from "notistack";
import { useQuery } from "react-query";
import MuiPagination from "@mui/material/Pagination";
import Loader from "../Loader";
const MemoizedCustomPagination = React.memo(CustomPagination);
const MemoizedCustomNoRowsOverlay = React.memo(CustomNoRowsOverlay);

const styles = {
  backgroundColor: "#fff",
  borderRadius: "12px",
  color: "#313131",
  border: 0,
  fontWeight: 500,
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: "#FCFCFC",
    "&:hover, &.Mui-hovered": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    "&.Mui-selected": {
      backgroundColor: "#FCFCFC",
      "&:hover, &.Mui-hovered": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
    },
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    color: "#1D2939",
    fontWeight: 500,
  },
  "& .MuiDataGrid-columnHeaders": {
    pl: "22px",
  },
  "& .MuiDataGrid-cell--textLeft": {
    pl: 4,
  },
  "&.MuiDataGrid-root .MuiDataGrid-withBorderColor": {
    // borderColor: "#F1F1F1",
    border: 0,
  },
  "& .MuiDataGrid-footerContainer": {
    justifyContent: "center",
  },
  "& .MuiTablePagination-displayedRows": {
    display: "none",
  },
  pagination: {
    mt: 4,
    "& .MuiPagination-ul": {
      justifyContent: "center",
    },
    "& .MuiPaginationItem-root": {
      backgroundColor: "transparent", // Add background color to all page numbers
      border: "none",
      margin: "0 10	px",
      color: "#000",
      // fontSize: 17,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    "&  .MuiPaginationItem-previousNext": {
      // width: 40,
      // height: 40,
      backgroundColor: "#2B2A69",
      color: "#FFF",
      "&:hover": {
        backgroundColor: "#2B2A69",
        color: "#FFF",
      },
      "&.Mui-disabled": {
        backgroundColor: "#FFF",
        color: "#B8B8B8",
        border: "1px solid #AEAEAE",
      },
      "& svg": {
        // height: 25,
        // width: 25,
      },
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      color: "#293277",
    },
  },
};

function Pagination({ page, onPageChange, className, count, limit }) {
  return (
    <MuiPagination
      className={className}
      page={page + 1}
      count={Math.ceil(count / limit)}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
      variant="outlined"
      shape="rounded"
      sx={styles.pagination}
    />
  );
}

export default function DataGridListing({
  rows = [],
  columns,
  className,
  handleCellClick,
  fetchRecords,
  queryName,
  loading = false,
  mode = false,
  search,
  isSearch,
  type,
}) {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const { enqueueSnackbar } = useSnackbar();

  const fetchData = async () => {
    const {
      status,
      message,
      data: serverData,
    } = await fetchRecords(
      paginationModel.page,
      paginationModel.pageSize,
      search,
      type,
      isSearch
    );
    if (status) {
      return serverData;
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const { isLoading, data, isFetching } = useQuery(
    [queryName, paginationModel.page, search, type, isSearch],
    fetchData,
    { enabled: !mode, keepPreviousData: true }
  );

  if (isLoading || loading) {
    return <Loader />;
  }

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        autoHeight
        rows={mode ? rows : data?.data || []}
        columns={columns}
        sx={styles}
        pagination
        disableRowSelectionOnClick
        className={className}
        loading={mode ? loading : isLoading || isFetching}
        disableColumnMenu
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        hideFooter={true}
        rowCount={data?.totalCount || rows.length || 0}
        paginationMode={mode ? "client" : "server"}
        slots={{
          //   pagination: MemoizedCustomPagination,
          noRowsOverlay: MemoizedCustomNoRowsOverlay,
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        pageSizeOptions={[10]}
      />

      <Pagination
        page={paginationModel.page}
        onPageChange={(event, newPage) =>
          setPaginationModel({ ...paginationModel, page: newPage })
        }
        count={data?.totalCount || rows.length || 0}
        limit={paginationModel.pageSize}
      />
    </div>
  );
}
