import React, { useEffect } from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import {
	Box,
	Button,
	Divider,
	Icon,
	IconButton,
	Stack,
	Typography,
} from "@mui/material";
import { Add, Clear, Close, Delete, Remove } from "@mui/icons-material";
import Product1 from "../../assets/images/Product 1.png";
import { useAtom } from "jotai";
import { cartAtom } from "../../data/store";
import { useNavigate } from "react-router";
import routes from "../../data/constants/routes";

const styles = {
	modal: {
		"& .MuiPaper-root ": {
			width: 400,
		},
	},
	container: {
		p: 2,
	},
	cartContainer: {
		px: 2,
		py: 3,
		borderRadius: "10px",
		border: "1px solid #E6EAF2",
		position: "relative",
		display: "flex",
		flexDirection: "row",
		gap: 2,
		mb: 2,
		alignItems: "center",
	},
	image: {
		width: 40,
		height: 40,
		borderRadius: "10px",
	},
	qtyBtnContainer: {
		bgcolor: "#FFF",
		border: "1px solid #E8EEF5",
		borderRadius: "10px",
		width: 100,
		justifyContent: "center",
		alignItems: "center",
		"& label": {
			color: "#000",
			fontSize: 16,
			fontWeight: 500,
			width: 70,
			textAlign: "center",
		},
	},
	qtyBtn: {
		bgcolor: "#E8EEF5",
		borderRadius: "10px",
		minWidth: 20,
		minHeight: 20,
		border: 0,
	},
	delete: {
		position: "absolute",
		top: 0,
		right: 0,
		width: 30,
		height: 30,
	},
	productDetail: {
		flex: 1,
		"& h2": {
			fontSize: 14,
			fontWeight: 500,
			color: "#1E1E1F",
		},
		"& label": {
			fontSize: 12,
			fontWeight: 400,
			color: "#767D90",
		},
	},
	checkoutContainer: {
		bgcolor: "#E8EEF5",
		borderTopRightRadius: "10px",
		borderTopLeftRadius: "10px",
		p: 2,
		"& h2": {
			fontSize: 15,
			fontWeight: 500,
			color: "#000",
			mb: 1,
		},
		"& h3": {
			fontSize: 14,
			fontWeight: 500,
			color: "#000",
			mb: 1,
		},
		"& label": {
			fontSize: 13,
			fontWeight: 400,
			color: "#767D90",
		},
	},
	divider: {
		color: "#EEE",
		mb: 2,
	},
	button: {
		width: "100%",
		bgcolor: "#135099",
		color: "#FFF",
		fontWeight: 500,
		fontSize: 14,
		borderRadius: "5px",
		textTransform: "capitalize",
		"&:hover": {
			bgcolor: "#135099",
		},
	},
};

const CartModal = ({ modal, setModal }) => {
	const [cart, setCart] = useAtom(cartAtom);

	const handleClose = () => {
		setModal(false);
	};

	const navigate = useNavigate();

	const handleQuantityChange = (item, newQuantity) => {
		// Find the item in the cart array and update its quantity
		const updatedCart = cart.map((cartItem) =>
			cartItem.id === item.id
				? { ...cartItem, quantity: newQuantity }
				: cartItem
		);
		setCart(updatedCart);
	};

	const handleRemoveItem = (item) => {
		// Filter out the item to remove it from the cart
		const updatedCart = cart.filter((cartItem) => cartItem.id !== item.id);
		setCart(updatedCart);
	};

	const subtotal = cart.reduce(
		(total, item) => total + item.price * item.quantity,
		0
	);

	return (
		<>
			<SwipeableDrawer
				open={modal}
				onClose={handleClose}
				anchor="right"
				sx={styles.modal}
				disableSwipeToOpen={true}
			>
				<Stack alignItems="flex-end">
					<IconButton onClick={handleClose}>
						<Close />
					</IconButton>
				</Stack>
				<Stack gap={2} justifyContent="space-between" height="100%">
					<Box sx={styles.container}>
						{cart?.map((item) => (
							<Box key={item.id} sx={styles.cartContainer}>
								<Box component="img" src={Product1} sx={styles.image} />
								<Stack sx={styles.productDetail}>
									<Typography component="h2">{item.name}</Typography>
									<Typography component="label">
										{item.quantity} x {item.price}
									</Typography>
								</Stack>
								<Stack sx={styles.qtyBtnContainer} direction="row" mt={1}>
									<Button
										disabled={item.quantity <= 1}
										onClick={() =>
											handleQuantityChange(item, item.quantity - 1)
										}
										sx={styles.qtyBtn}
									>
										<Remove sx={{ fill: "#000" }} />
									</Button>

									<Typography component="label">{item.quantity}</Typography>

									<Button
										// disabled={counter >= product["countInStock"]}
										onClick={() =>
											handleQuantityChange(item, item.quantity + 1)
										}
										sx={styles.qtyBtn}
									>
										<Add sx={{ fill: "#135099" }} />
									</Button>
								</Stack>

								<IconButton
									sx={styles.delete}
									onClick={() => handleRemoveItem(item)}
								>
									<Clear sx={{ width: 20 }} />
								</IconButton>
							</Box>
						))}
					</Box>

					<Box sx={styles.checkoutContainer}>
						<Typography component="h2">Products</Typography>
						{cart?.map((item) => (
							<Stack
								key={item.id}
								direction="row"
								justifyContent="space-between"
							>
								<Typography component="h3">{item.name}</Typography>
								<Typography component="label">{item.price}</Typography>
							</Stack>
						))}

						<Divider sx={styles.divider} />

						<Stack direction="row" justifyContent="space-between">
							<Typography component="h3">Sub Total:</Typography>
							<Typography component="label">${subtotal.toFixed(2)}</Typography>
						</Stack>

						<Stack direction="row" justifyContent="space-between">
							<Typography component="h3">Discount:</Typography>
							<Typography component="label">0.00</Typography>
						</Stack>

						<Divider sx={styles.divider} />

						<Stack direction="row" justifyContent="space-between">
							<Typography component="h3" fontSize={14}>
								Total:
							</Typography>
							<Typography component="label">${subtotal.toFixed(2)}</Typography>
						</Stack>

						<Button
							variant="contained"
							disableElevation
							sx={styles.button}
							disabled={!cart.length}
							onClick={() => {
								navigate(routes.CUSTOMER_CHECKOUT);
								handleClose();
							}}
						>
							Check out
						</Button>
					</Box>
				</Stack>
			</SwipeableDrawer>
		</>
	);
};

export default CartModal;
