import { Box, Grid, Typography } from "@mui/material";
import SplashImg from "../../assets/svg/SplashImg.svg";
import Logo from "../../assets/svg/Logo.svg";
import LoadingButton from "../button/LoadingButton";
import { Link } from "react-router-dom";
import LOGO from "../../assets/images/LOGO.png";

const styles = {
  sideImg: {
    width: "100%",
    height: "100vh",
    objectFit: "cover",
  },
  rightSide: {
    height: "100vh",
    overflowY: "auto",
    pt: { md: "85px", sm: "50px", xs: "50px" },
    px: { lg: "130px", md: "80px", xs: "20px" },
    pb: 2,
    position: "relative",
    fontFamily: "sans-serif",
    // textAlign: { md: "unset", sm: "-webkit-center", xs: "-webkit-center" },
    "& h2": {
      fontWeight: 600,
      color: "#1E1E1F",
      pt: "70px",
      fontSize: 30,
      lineHeight: "30px",
      "@media (max-height: 650px)": {
        pt: "50px",
        fontSize: 27,
      },
      textAlign: { md: "unset", sm: "center", xs: "center" },
    },
    "& h6": {
      color: "#5F6368",
      fontSize: 16,
      fontWeight: 400,
      pt: 1,
      lineHeight: "25.65px",
    },
    "& img": {
      "@media (max-height: 650px)": {
        height: "80px !important",
      },
      // textAlign: "center",
    },
    "@media (max-height: 650px)": {
      pt: "50px",
    },
  },
  leftSide: {
    // height: "100vh",
    overflow: "hidden",
    display: { md: "flex", sm: "none", xs: "none" },
  },
  form: {
    pt: { md: 3, sm: 3, xs: 3 },
    "& .MuiFormControl-root": {
      mb: "15px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px !important",
      height: 55,
      overflow: "hidden",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E2E3E4",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#2B2A69 !important",
      borderWidth: "1px !important",
    },
    "& input": {
      py: { md: "14px", lg: "19px" },
      pl: { md: "19px", sm: "15px", xs: "15px" },
      "&::placeholder": {
        color: "#B4B1B6",
        opacity: 1,
      },
    },
    "& .MuiIconButton-root": {
      p: "14px",
      color: "#D9D9D9",
    },
    "@media (max-height: 650px)": {
      "& a": {
        fontSize: 15,
      },
      "& span": {
        fontSize: 15,
      },
      "& h6": {
        fontSize: 15,
      },
      "& .MuiOutlinedInput-root": {
        fontSize: 15,
        height: 45,
      },
    },
  },
  submitBtn: {
    bgcolor: "#135099",
    height: 54,
    textTransform: "capitalize",
    fontWeight: 600,
    fontSize: 16,
    mt: { md: "20px", xs: "12px" },
    borderRadius: "10px",
    "@media (max-height: 650px)": {
      fontSize: 15,
      height: 45,
      mt: "15px",
    },
  },
  newAccount: {
    color: "#1E1E1F",
    pt: "20px",
    textAlign: "center",
    fontWeight: 500,
    "& a": {
      textDecoration: "none",
      color: "#000000",
      fontWeight: 600,
    },
    "@media (max-height: 650px)": {
      fontSize: 15,
    },
  },
  forgetPassword: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiFormControlLabel-root .MuiFormControlLabel-label": {
      color: "#767D90",
    },
    "& svg": {
      fill: "#EAEAEA",
    },
    "& a": {
      color: "#0F4590",
      textDecoration: "none",
    },
  },
};

const SplashForm = ({
  title,
  btnText,
  tagline = null,
  taglinePart1 = null,
  taglinePart2 = null,
  email = "",
  subTagline = null,
  children,
  linkTaglinePlainText,
  linkTaglineText,
  linkTaglineHref,
  showImage = false,
  image,
  handleSubmit,
  isLoading,
}) => {
  return (
    <>
      <Grid container justifyContent="center">
        <Grid item md={6} sx={styles.leftSide}>
          <Box
            component="img"
            src={SplashImg}
            alt="auth-img"
            sx={styles.sideImg}
          />
        </Grid>

        <Grid item md={6} sx={styles.rightSide}>
          <Box
            sx={{
              textAlign: {
                md: "unset",
                sm: "-webkit-center",
                xs: "-webkit-center",
              },
            }}
          >
            <Box
              component="img"
              src={LOGO}
              alt="Logo"
              height={100}
              display="block"
            />
          </Box>
          {showImage ? (
            <Box
              component="img"
              src={image}
              alt="Email"
              height={215}
              mt="90px"
              sx={{
                "@media (max-height: 800px)": {
                  height: 100,
                  mt: 9,
                },
              }}
            />
          ) : null}
          <Box component="h2" pt={subTagline && "32px !important"}>
            {title}
          </Box>
          {tagline ? (
            <Box component="h6" mt={subTagline && 1.5}>
              {tagline} 
            </Box>
          ) : taglinePart1 && email ? (
            <Box component="h6" mt={subTagline && 1.5}>
              {taglinePart1} <b>{email}</b> {taglinePart2}
            </Box>
          ) : null}
          {subTagline ? <Box component="h6">{subTagline}</Box> : null}

          <form>
            {!subTagline && (
              <Box component="form" sx={styles.form}>
                {children}
              </Box>
            )}

            <LoadingButton
              variant="contained"
              fullWidth
              disableElevation
              sx={styles.submitBtn}
              type="submit"
              onClick={handleSubmit}
              isLoading={isLoading}
            >
              {btnText}
            </LoadingButton>

            <Typography component="p" sx={styles.newAccount}>
              {linkTaglinePlainText}
              <Link to={linkTaglineHref}>{linkTaglineText}</Link>
            </Typography>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default SplashForm;
