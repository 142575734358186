import { Autocomplete, FormHelperText, TextField } from "@mui/material";
import styles from "../../assets/styles/forms/formAutoComplete.styles";
import FormLabel from "./FormLabel";

const FormAutoComplete = ({
  label,
  labelSize,
  isFilledInput,
  isSmallFont,
  inputType = false,
  helperText,
  ...other
}) => {
  const inputId = `input-${other.name}`;

  return (
    <>
      <FormLabel
        label={label}
        inputId={inputId}
        inputType={inputType}
        labelSize={labelSize}
      />
      <Autocomplete
        id={other?.id ? other.id : inputId}
        // fullWidth
        renderInput={(params) => (
          <TextField {...params} placeholder={other.placeholder} />
        )}
        sx={{
          ...other.sx,
          ...styles.autocomplete,
          ...(other.error && {
            ...styles.error,
          }),
        }}
        {...other}
      />
      {helperText && (
        <FormHelperText sx={styles.helperText}>{helperText}</FormHelperText>
      )}
    </>
  );
};

export default FormAutoComplete;
