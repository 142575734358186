import { useState } from "react";
import { Box, Grid, IconButton, InputAdornment } from "@mui/material";
import PageTitle from "../../components/common/PageTitle";
import { useMutation } from "react-query";
import { post } from "../../server";
import { CUSTOMER_CHANGE_PASSWORD } from "../../data/constants/apiRoutes";
import { useSnackbar } from "notistack";
import { validator } from "../../utils/helpers/validator";
import { ChangePasswordSchema } from "../../utils/schemas/AuthSchema";
import LoadingButton from "../../components/button/LoadingButton";
import FormInput from "../../components/forms/FormInput";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const styles = {
  wrapper: {
    bgcolor: "#fff",
    borderRadius: "20px",
    p: 3,
    mt: 2,
  },
  submitBtn: {
    bgcolor: "#135099",
    borderRadius: 2.5,

    minWidth: 130,
    height: 45,
    textTransform: "capitalize",
  },
};

const initialData = {
  current_password: "",
  password: "",
  confirm_password: "",
};

const Settings = () => {
  const [auth, setAuth] = useState(initialData);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({});

  const { enqueueSnackbar } = useSnackbar();

  const handleClickShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setAuth({ ...auth, [name]: value });
  };

  // handle Validation
  const validateForm = () => {
    let newErrors = validator(auth, ChangePasswordSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };

  const handleChangePassword = async () => {
    const { status, message } = await post(CUSTOMER_CHANGE_PASSWORD, auth);

    if (status) {
      enqueueSnackbar(message, {
        variant: "success",
      });
      setAuth(initialData);
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const mutation = useMutation(handleChangePassword);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validateForm()) return;
    mutation.mutate();
  };

  return (
    <>
      <PageTitle title="Change Password" />
      <Box sx={styles.wrapper}>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormInput
                  placeholder="Enter Current password"
                  type={showCurrentPassword ? "text" : "password"}
                  name="current_password"
                  value={auth.current_password}
                  onChange={handleChange}
                  error={errors?.current_password ? true : false}
                  helperText={errors?.current_password || ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowCurrentPassword}
                          edge="end"
                        >
                          {showCurrentPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} />
              <Grid item xs={12} md={6}>
                <FormInput
                  placeholder="Enter new password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={auth.password}
                  onChange={handleChange}
                  error={errors?.password ? true : false}
                  helperText={errors?.password || ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} />
              <Grid item xs={12} md={6}>
                <FormInput
                  placeholder="Enter confirm password"
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirm_password"
                  value={auth.confirm_password}
                  onChange={handleChange}
                  error={errors?.confirm_password ? true : false}
                  helperText={errors?.confirm_password || ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box component="div" mt={2}>
        <LoadingButton
          variant="contained"
          disableElevation
          sx={styles.submitBtn}
          type="submit"
          isLoading={mutation.isLoading}
          onClick={handleSubmit}
        >
          Save
        </LoadingButton>
      </Box>
    </>
  );
};

export default Settings;
