const YouTubePlayer = ({ videoId }) => {
  return (
    <div>
      <iframe
        width="560"
        height="315"
        src={`https://www.youtube.com/embed/${videoId}`}
        title="YouTube Video Player"
        frameBorder="0"
        allowFullScreen
      />
    </div>
  );
};

export default YouTubePlayer;
