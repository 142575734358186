import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "../../assets/styles/unauthorize.styles";
import routes from "../../data/constants/routes";

const Unauthorize = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* <Typography variant="h1">
        You are Unauthorze to use this resource
      </Typography> */}
      <Box sx={styles}>
        <Typography variant="h1">401</Typography>
        <Typography variant="h2">Not Authorized</Typography>
        <Typography variant="h4">
          This page is not publically available
        </Typography>
        <Box textAlign="center">
          <Button
            variant="contained"
            onClick={() => navigate(routes.CUSTOMER_BUSINESS_CARD)}
          >
            Return Home
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Unauthorize;
