import React, { useEffect, useState } from "react";
import FormTab from "../../components/tabs/FormTab";
import TabPanel from "../../components/tabs/TabPanel";
import PageTitle from "../../components/common/PageTitle";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import FormInput from "../../components/forms/FormInput";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { get, post, put } from "../../server";
import {
  CUSTOMER_CHANGE_PASSWORD,
  CUSTOMER_USER_NOTIFICATION,
} from "../../data/constants/apiRoutes";
import { ChangePasswordSchema } from "../../utils/schemas/AuthSchema";
import { validator } from "../../utils/helpers/validator";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import routes from "../../data/constants/routes";
import LoadingButton from "../../components/button/LoadingButton";
import queryNames from "../../data/constants/queryNames";
import Loader from "../../components/Loader";

const styles = {
  submitBtn: {
    bgcolor: "#135099",
    borderRadius: 2.5,
    mt: 2,
    minWidth: 130,
    height: 45,
    textTransform: "capitalize",
  },
  switch: {
    "& .MuiSwitch-switchBase:hover": {
      backgroundColor: "transparent",
    },
    "& .MuiSwitch-switchBase.Mui-checked:hover": {
      backgroundColor: "transparent",
    },
  },
};

const initialData = {
  current_password: "",
  password: "",
  confirm_password: "",
};

const Settings = () => {
  const [tabValue, setTabValue] = useState(0);
  const [auth, setAuth] = useState(initialData);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const handleClickShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setAuth({ ...auth, [name]: value });
  };

  // handle Validation
  const validateForm = () => {
    let newErrors = validator(auth, ChangePasswordSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };

  const handleChangePassword = async () => {
    const { status, message } = await post(CUSTOMER_CHANGE_PASSWORD, auth);

    if (status) {
      enqueueSnackbar(message, {
        variant: "success",
      });
      setAuth(initialData);
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const mutation = useMutation(handleChangePassword);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validateForm()) return;
    mutation.mutate();
  };

  const handleNotifications = async () => {
    let { status, message } = await put(CUSTOMER_USER_NOTIFICATION, {
      send_push: isChecked ? 1 : 0,
    });

    if (status) {
      enqueueSnackbar("Notifications setting has been updated successfully", {
        variant: "success",
      });
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const notificationMutation = useMutation(handleNotifications);

  const handleNotificationSubmit = (event) => {
    event.preventDefault();
    notificationMutation.mutate();
  };

  const fetchNotification = async () => {
    let { status, message, data } = await get(CUSTOMER_USER_NOTIFICATION);

    if (status) {
      setIsChecked(data.sendPush);
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const notificationQuery = useQuery(
    [queryNames.NOTIFICATION, tabValue],
    fetchNotification,
    {
      refetchOnWindowFocus: false,
    }
  );

  if (notificationQuery.isLoading) {
    return <Loader />;
  }
  return (
    <>
      <PageTitle title="Settings" />
      <Grid container spacing={2} mt={2}>
        <Box>
          <FormTab
            tabValue={tabValue}
            setTabValue={setTabValue}
            tabs={["Password", "Notifications"]}
            centered
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <TabPanel value={tabValue} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormInput
                placeholder="Enter Current password"
                type={showCurrentPassword ? "text" : "password"}
                name="current_password"
                value={auth.current_password}
                onChange={handleChange}
                error={errors?.current_password ? true : false}
                helperText={errors?.current_password || ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowCurrentPassword}
                        edge="end"
                      >
                        {showCurrentPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} />
            <Grid item xs={12} md={6}>
              <FormInput
                placeholder="Enter new password"
                type={showPassword ? "text" : "password"}
                name="password"
                value={auth.password}
                onChange={handleChange}
                error={errors?.password ? true : false}
                helperText={errors?.password || ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword} edge="end">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} />
            <Grid item xs={12} md={6}>
              <FormInput
                placeholder="Enter confirm password"
                type={showConfirmPassword ? "text" : "password"}
                name="confirm_password"
                value={auth.confirm_password}
                onChange={handleChange}
                error={errors?.confirm_password ? true : false}
                helperText={errors?.confirm_password || ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Box component="div" mt={2}>
            <LoadingButton
              variant="contained"
              disableElevation
              sx={styles.submitBtn}
              type="submit"
              isLoading={mutation.isLoading}
              onClick={handleSubmit}
            >
              Save
            </LoadingButton>
          </Box>
        </TabPanel>
      </Grid>
      <Grid item xs={12}>
        <TabPanel value={tabValue} index={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h6">Receive Notification</Typography>
                <Box>
                  <FormControlLabel
                    sx={styles.switch}
                    control={
                      <Switch
                        checked={isChecked}
                        onChange={(e) => setIsChecked(e.target.checked)}
                        // size="small"
                      />
                    }
                  />

                  <FormHelperText>{/* {errors.isChecked} */}</FormHelperText>
                </Box>
              </Stack>
              <Typography>
                Opt in to receive push notifications in all scenarios on this
                account
              </Typography>
              <Box component="div" mt={2}>
                <LoadingButton
                  variant="contained"
                  disableElevation
                  sx={styles.submitBtn}
                  type="submit"
                  onClick={handleNotificationSubmit}
                  isLoading={notificationMutation.isLoading}
                >
                  Update Notification
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </TabPanel>
      </Grid>
    </>
  );
};

export default Settings;
