import {
  Modal,
  Box,
  Stack,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { Close, Done } from "@mui/icons-material";
import LoadingButton from "../button/LoadingButton";

const styles = {
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 400,
    minWidth: 300,
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 15,
    p: 2,
    "& button": {
      width: 120,
      boxShadow: "none",
      fontWeight: 600,
    },
  },
  iconBox: {
    width: 60,
    height: 60,
    borderRadius: "50%",
    textAlign: "center",
    border: "3px solid #E53935",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  crossIcon: {
    width: 40,
    height: 40,
    color: "#E53935",
  },
  doneIcon: {
    width: 40,
    height: 40,
    color: "green",
  },
  closeIconBtnWrapper: {
    textAlign: "end",
  },
  closeIconBtn: {
    textAlign: "end",
    width: "40px !important",
    height: 40,
  },
  headerText: {
    fontSize: 18,
    fontWeight: 600,
    flexGrow: 1,
    textAlign: "center",
    color: "#636363",
  },
  confirmTitle: {
    color: "#838383",
    marginInline: "10px",
    fontSize: 14,
  },
  cancelBtn: {
    bgcolor: "#c1c1c1",
    color: "#ffffff",
    height: 40,
    textTransform: "capitalize",
    "&:hover": { bgcolor: "#c1c1c1" },
  },
  btnsWrapper: {
    mt: 1,
    mb: 3,
  },
};

const BoxModal = ({
  open,
  handleClose,
  handleConfirm,
  header,
  title,
  confirmButton,
  confirmButtonColor,
  isLoading,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-describedby="modal-modal-description"
    >
      <Box sx={styles.container}>
        <Box sx={styles.closeIconBtnWrapper}>
          <IconButton onClick={handleClose} sx={styles.closeIconBtn}>
            <Close />
          </IconButton>
        </Box>

        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          <Box
            component="div"
            sx={{
              ...(open === "confirm"
                ? { borderColor: "green !important" }
                : {}),
              ...styles.iconBox,
            }}
          >
            {open === "confirm" ? (
              <Done sx={styles.doneIcon} />
            ) : (
              <Close sx={styles.crossIcon} />
            )}
          </Box>
          <Typography sx={styles.headerText}>{header && header}</Typography>
        </Stack>
        <Typography
          id="modal-modal-description"
          py={3.5}
          fontSize={17}
          textAlign="center"
          sx={styles.confirmTitle}
        >
          {title}
        </Typography>
        <Stack
          direction="row"
          sx={styles.btnsWrapper}
          justifyContent="center"
          spacing={2}
          textAlign="justify"
        >
          <Button
            variant="contained"
            sx={styles.cancelBtn}
            onClick={handleClose}
          >
            Cancel
          </Button>

          <LoadingButton
            variant="contained"
            sx={{
              height: 40,
              textTransform: "capitalize",
              bgcolor: confirmButtonColor ? confirmButtonColor : "#E53935",
              "&:hover": {
                bgcolor: confirmButtonColor ? confirmButtonColor : "#E53935",
              },
            }}
            onClick={handleConfirm}
            isLoading={isLoading}
          >
            {confirmButton}
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  );
};

export default BoxModal;
