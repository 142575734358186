import React from "react";
import TemplateOne from "./BusinessCardTemplate/TemplateOne";
import TemplateTwo from "./BusinessCardTemplate/TemplateTwo";
import TemplateThree from "./BusinessCardTemplate/TemplateThree";
import TemplateFour from "./BusinessCardTemplate/TemplateFour";

function BusinessCardPreview({ templateNo, ...other }) {
  return (
    <>
      {templateNo === 1 ? (
        <TemplateOne {...other} />
      ) : templateNo === 2 ? (
        <TemplateTwo {...other} />
      ) : templateNo === 3 ? (
        <TemplateThree {...other} />
      ) : templateNo === 4 ? (
        <TemplateFour {...other} />
      ) : (
        <TemplateOne {...other} />
      )}
    </>
  );
}

export default BusinessCardPreview;
