import { Route } from "react-router-dom";
import routes from "../constants/routes";
import Login from "../../pages/customer/auth/Login";
import Signup from "../../pages/customer/auth/Signup";
import VerifyOTP from "../../pages/customer/auth/VerifyOTP";
import ResetPassword from "../../pages/customer/auth/ResetPassword";
import NewPassword from "../../pages/customer/auth/NewPassword";
import VerifyEmail from "../../pages/customer/auth/VerifyEmail";
import DashboardLayout from "../../layout/DashboardLayout";
import AddEditProfile from "../../pages/customer/profile/AddEditProfile";
import ViewProfile from "../../pages/customer/profile/ViewProfile";
import Library from "../../pages/customer/media/Library";
import Gallery from "../../pages/customer/media/Gallery";
import ProductListing from "../../pages/customer/product/Listing";
import ProductDetail from "../../pages/customer/product/Detail";
import Payment from "../../pages/customer/payment/Payment";
import AddCard from "../../pages/customer/payment/AddCard";
import CardListing from "../../pages/customer/payment/CardListing";
import BusinessCardListing from "../../pages/customer/businessCards/Listing";
import CreateCard from "../../pages/customer/businessCards/CreateCard";
import Contact from "../../pages/customer/contact/Contact";
import CardOrdersListing from "../../pages/customer/cardOrders/CardOrdersListing";
import Settings from "../../pages/customer/Settings";
import CheckIfCustomerAlreadyLoggedIn from "../../components/auth/CheckIfCustomerAlreadyLoggedIn";
import CheckifLoggedIn from "../../components/auth/CheckifLoggedIn";
import VerifyAccount from "../../pages/customer/auth/VerifyAccount";
import CustomerDashboard from "../../pages/customer/CustomerDashboard";
import ConnectionListing from "../../pages/customer/connections/Listing";
import CreateConnection from "../../pages/customer/connections/CreateConnection";
import ContactUs from "../../pages/customer/help/ContactUs";
import AboutUs from "../../pages/customer/help/AboutUs";
import PrivacyPolicy from "../../pages/customer/help/PrivacyPolicy";
import Checkout from "../../pages/customer/checkout/Checkout";
import TeamsListing from "../../pages/customer/teams/TeamsListing";
import TeamMembers from "../../pages/customer/teams/TeamMembers";
import AddEditTeam from "../../pages/customer/teams/AddEditTeam";
import CheckAuth from "../../components/auth/CheckAuth";
import ShareCard from "../../pages/customer/ShareCard";
import PublicCard from "../../pages/customer/publicPages/PublicCard";
import CheckToken from "../../components/auth/CheckToken";
import OrderCard from "../../pages/customer/teams/OrderCard";
import NotificationsListing from "../../pages/customer/notifications/NotificationsListing";
import CardOrderDetail from "../../pages/customer/cardOrders/CardOrderDetail";
import Subscription from "../../pages/customer/subscription/Subscription";
import PersonalOrderCard from "../../pages/customer/businessCards/PersonalOrderCard";
import OtherTeamMembers from "../../pages/customer/teams/OtherTeamMembers";
import AddEditProduct from "../../pages/customer/product/AddEditProduct";
import MyProductListing from "../../pages/customer/product/ProductListing";
import TermsAndConditions from "../../pages/customer/help/TermsAndConditions";
import PublicAboutUs from "../../pages/customer/publicPages/PublicAboutUs";
import PublicContactUs from "../../pages/customer/publicPages/PublicContactUs";
import PublicPrivacyPolicy from "../../pages/customer/publicPages/PublicPrivacyPolicy";
import PublicTermsAndConditions from "../../pages/customer/publicPages/PublicTermsAndConditions";

const CustomerRoutes = (
  <>
    <Route path={"/"} element={<CheckifLoggedIn />} />

    <Route
      path={`${routes.CUSTOMER_PUBLIC_CARD}/:uuid`}
      element={<PublicCard />}
    />
    <Route path={routes.PUBLIC_ABOUT_US} element={<PublicAboutUs />} />
    <Route path={routes.PUBLIC_CONTACT_US} element={<PublicContactUs />} />
    <Route
      path={routes.PUBLIC_PRIVACY_POLICY}
      element={<PublicPrivacyPolicy />}
    />
    <Route
      path={routes.PUBLIC_TERMS_AND_CONDITIONS}
      element={<PublicTermsAndConditions />}
    />

    <Route
      path={`${routes.CUSTOMER_FORM_REGISTRATION}`}
      element={<ShareCard />}
    />

    <Route path={routes.CUSTOMER_LOGIN} element={<Login />} />
    <Route path={routes.CUSTOMER_SIGNUP} element={<Signup />} />
    <Route path={routes.CUSTOMER_VERIFY_EMAIL} element={<VerifyEmail />} />
    <Route path={routes.CUSTOMER_VERIFY_OTP} element={<VerifyOTP />} />
    <Route path={routes.CUSTOMER_RESET_PASSWORD} element={<ResetPassword />} />
    <Route path={routes.CUSTOMER_VERIFY_ACCOUNT} element={<VerifyAccount />} />
    <Route
      path={routes.CUSTOMER_CREATE_NEW_PASSWORD}
      element={<NewPassword />}
    />

    <Route element={<CheckAuth />}>
      <Route element={<DashboardLayout />}>
        <Route
          path={routes.CUSTOMER_DASHBOARD}
          element={<CustomerDashboard />}
        />
        <Route path={routes.CUSTOMER_PROFILE} element={<ViewProfile />} />

        <Route
          path={routes.CUSTOMER_PROFILE_ADD}
          element={<AddEditProfile />}
        />
        <Route
          path={`${routes.CUSTOMER_PROFILE_EDIT}:id`}
          element={<AddEditProfile />}
        />

        {/* <Route path={"/library"} element={<Library />} />
			<Route path={"/gallery"} element={<Gallery />} /> */}

        <Route path={routes.CUSTOMER_PRODUCT} element={<ProductListing />} />

        <Route
          path={`${routes.CUSTOMER_PRODUCT_DETAIL}/:productId`}
          element={<ProductDetail />}
        />

        <Route
          path={routes.CUSTOMER_PRODUCT_ADD}
          element={<AddEditProduct />}
        />

        <Route
          path={`${routes.CUSTOMER_PRODUCT_EDIT}/:productId`}
          element={<AddEditProduct />}
        />

        <Route
          path={routes.CUSTOMER_PRODUCT_LISTING}
          element={<MyProductListing />}
        />

        <Route path={routes.CUSTOMER_PAYMENT} element={<Payment />} />

        <Route path={routes.CUSTOMER_PAYMENT_CARD_ADD} element={<AddCard />} />

        <Route
          path={routes.CUSTOMER_PAYMENT_CARD_LISTING}
          element={<CardListing />}
        />

        <Route
          path={routes.CUSTOMER_BUSINESS_CARD}
          element={<BusinessCardListing />}
        />
        <Route
          path={`${routes.CUSTOMER_BUSINESS_CARD_ORDER}/:cardId`}
          element={<PersonalOrderCard />}
        />

        <Route path={routes.CUSTOMER_CREATE_CARD} element={<CreateCard />} />

        <Route
          path={`${routes.CUSTOMER_EDIT_CARD}/:id`}
          element={<CreateCard />}
        />

        <Route path={routes.CUSTOMER_CONTACT} element={<Contact />} />

        <Route
          path={routes.CUSTOMER_CONNECTIONS}
          element={<ConnectionListing />}
        />
        <Route
          path={routes.CUSTOMER_CREATE_CONNECTION}
          element={<CreateConnection />}
        />
        <Route
          path={`${routes.CUSTOMER_EDIT_CONNECTION}/:id`}
          element={<CreateConnection />}
        />

        <Route
          path={routes.CUSTOMER_CARD_ORDER_HISTORY}
          element={<CardOrdersListing />}
        />
        <Route
          path={`${routes.CUSTOMER_CARD_ORDER_HISTORY}/:orderId`}
          element={<CardOrderDetail />}
        />

        <Route path={routes.CUSTOMER_CHECKOUT} element={<Checkout />} />

        <Route path={routes.CUSTOMER_SETTINGS} element={<Settings />} />
        <Route path={routes.CUSTOMER_SUBSCRIPTION} element={<Subscription />} />
        <Route path={routes.CUSTOMER_CONTACT_US} element={<ContactUs />} />
        <Route path={routes.CUSTOMER_ABOUT_US} element={<AboutUs />} />
        <Route
          path={routes.CUSTOMER_TERMS_AND_CONDITIONS}
          element={<TermsAndConditions />}
        />
        <Route
          path={`${routes.CUSTOMER_ORDERCARD}/:teamId`}
          element={<OrderCard />}
        />

        <Route
          path={routes.CUSTOMER_PRIVACY_POLICY}
          element={<PrivacyPolicy />}
        />
        <Route path={routes.CUSTOMER_TEAMS} element={<TeamsListing />} />
        <Route
          path={`${routes.CUSTOMER_TEAM_MEMBERS}/:teamId/teamMembers`}
          element={<TeamMembers />}
        />
        <Route
          path={`${routes.CUSTOMER_TEAM_MEMBERS}/:teamId/teamMembers/:teamOwnerId`}
          element={<OtherTeamMembers />}
        />
        <Route path={routes.CUSTOMER_CREATE_TEAM} element={<AddEditTeam />} />
        <Route
          path={`${routes.CUSTOMER_EDIT_TEAM}/:teamId`}
          element={<AddEditTeam />}
        />

        <Route
          path={routes.CUSTOMER_TEAM_MEMBER_CREATE_CARD}
          element={<CreateCard />}
        />

        <Route
          path={`${routes.CUSTOMER_TEAM_MEMBER_EDIT_CARD}/:id`}
          element={<CreateCard />}
        />

        <Route
          path={routes.CUSTOMER_NOTIFICATION}
          element={<NotificationsListing />}
        />
        {/* </Route> */}
      </Route>
    </Route>
  </>
);

export default CustomerRoutes;
