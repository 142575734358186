import { Tab, Tabs, Box } from "@mui/material";
import styles from "../../assets/styles/tabs/formTab.styles";

function a11yProps(index) {
  return {
    id: `form-tab-${index}`,
    "aria-controls": `form-tabpanel-${index}`,
  };
}

const FormTab = ({ tabValue, setTabValue, tabs, ...others }) => {
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={styles.tabsWrapper}>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        aria-label="form tabs"
        {...others}
        TabIndicatorProps={{
          style: { display: "none" },
        }}
      >
        {tabs.map((tab, index) => (
          <Tab label={tab} key={tab} {...a11yProps(index)} sx={styles.tab} />
        ))}
      </Tabs>
    </Box>
  );
};

export default FormTab;
