const styles = {
  label: {
    color: "#838383",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "19px",
    marginBottom: "5px",
    display: "block",
    "& > *": {
      color: "#fa5252",
    },
  },

  select: {
    borderRadius: "10px",
    height: 50,
    "& .MuiSelect-select": {
      backgroundColor: "transparent",
      fontWeight: 400,
      opacity: 1,
      fontSize: 14,
      color: "#202020",
      borderRadius: "10px",
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #656DFC",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
      borderColor: "#EEE ",
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
    },

    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#656DFC",
      borderWidth: "1px",
    },
    "& span": {
      color: "#9c9c9c",
    },
  },

  widthySelect: {
    borderRadius: "10px",
    height: 50,
    "& .MuiSelect-select": {
      backgroundColor: "transparent",
      fontWeight: 500,
      opacity: 1,
      fontSize: 14,
      color: "#202020",
      borderRadius: "10px",
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #656DFC",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
      borderColor: "#EEE ",
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
    },

    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#656DFC",
      borderWidth: "1px",
    },
    "& span": {
      color: "#B4B1B6",
    },
  },

  helperText: {
    marginLeft: 0,
  },
  error: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #d32f2f !important",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d32f2f !important",
      borderWidth: "1px",
    },
  },
};

export default styles;
