import {
  Modal,
  Box,
  Stack,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { Close, Done } from "@mui/icons-material";
import LoadingButton from "../button/LoadingButton";
import styles from "../../assets/styles/modal/colorPicker.styles";
import { SketchPicker } from "react-color";

const ColorPickerModal = ({
  open,
  setOpen,
  handleClose,
  header,
  data,
  setData,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-describedby="modal-modal-description"
    >
      <Box sx={styles.container}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography sx={styles.headerText}>{header && header}</Typography>
          <Box sx={styles.closeIconBtnWrapper}>
            <IconButton onClick={handleClose} sx={styles.closeIconBtn}>
              <Close />
            </IconButton>
          </Box>
        </Stack>
        <Box sx={styles.colorPickerModal} mt={2}>
          <SketchPicker
            className="customColorPicker"
            color={data.primaryColor}
            onChange={(newColor) =>
              setData({ ...data, primaryColor: newColor.hex })
            }
          />
        </Box>
        <Box sx={styles.btnWrapper}>
          <Button
            variant="contained"
            sx={styles.btn}
            onClick={() => setOpen(false)}
          >
            Done
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ColorPickerModal;
