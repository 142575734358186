const styles = {
  pageTitle: {
    flexDirection: "row",
    columnGap: 1.5,
    pb: 2,
    mb: 2,
    borderBottom: "1px solid #EBEBEE",
    alignItems: "center",
  },
  card: {
    borderRadius: 5,
    bgcolor: "#fff",
    p: { sm: 3, xs: "24px 16px" },
    boxShadow: "0px 0px 18px 0px rgba(0, 0, 0, 0.06)",
    "& h2": {
      color: "#1E1E1F",
      fontWeight: 600,
      fontSize: 16,
      pb: 1,
    },
    "& .customBgImg": {
      width: 70,
      height: 70,
    },
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    bgcolor: "rgba(0,0,0,0.3)",
    borderRadius: "10px",
  },
  coverImgOverlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    bgcolor: "rgba(0,0,0,0.6)",
    borderRadius: 2,
    position: "absolute",
    top: 0,
    left: 0,
    width: 70,
    height: 70,
  },
  logoOverlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    bgcolor: "rgba(0,0,0,0.6)",
    borderRadius: 2,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  templateOverlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    bgcolor: "rgba(0,0,0,0.6)",
    borderRadius: 2,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },

  cancelBtn: {
    textTransform: "capitalize",
    color: "#000",
    bgcolor: "#E8EEF5",
    width: 120,
    height: 45,
    borderRadius: 2.5,
    fontWeight: 600,
    width: { xs: 55 },
    minWidth: { xs: 95 },
    "&:hover": {
      color: "#000",
      bgcolor: "#E8EEF5",
      opacity: 0.8,
    },
  },
  nextBtn: {
    textTransform: "capitalize",
    color: "#fff",
    bgcolor: "#135099",
    width: 120,
    height: 45,
    borderRadius: 2.5,
    fontWeight: 600,
    width: { xs: 55 },
    minWidth: { xs: 95 },
    "&:hover": {
      color: "#fff",
      bgcolor: "#135099",
      opacity: 0.8,
    },
  },
  tabWrapper: {
    bgcolor: "#eee",
    borderRadius: { sm: 15, xs: 2 },
    p: "10px",
    mb: 2,
    display: "inline-flex",
    flexWrap: "wrap",
    columnGap: 1,
  },
  tab: {
    minWidth: 80,
    px: { sm: 2, xs: 1 },
    fontSize: { sm: 16, xs: 14 },
    textAlign: "center",
    height: 35,
    lineHeight: "35px",
    borderRadius: 10,
    whiteSpace: "nowrap",
    color: "#0C0C0C",
    cursor: "pointer",
  },
  tabActive: {
    bgcolor: "#fff",
    color: "#135099",
    fontWeight: 700,
  },
  colorBox: {
    width: 50,
    height: 50,
    borderRadius: 2.5,
    cursor: "pointer",
  },
  colorPickerBox: {
    border: "1px solid #000000",
    width: "50px",
    height: "50px",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  profileImg: {
    width: 104,
    height: 104,
    border: "1px solid #eee",
    borderRadius: 4,
    objectFit: "cover",
    cursor: "pointer",
  },
  coverImg: {
    width: 70,
    height: 70,
    objectFit: "cover",
    borderRadius: 2,
    cursor: "pointer",
  },
  add: {
    color: "#135099",
    fontSize: 14,
    fontWeight: 500,
    pt: 1,
    display: "inline-block",
    cursor: "pointer",
  },
  itemWrapper: {
    // display: "grid",
    // gridTemplateColumns: "repeat(auto-fit,minmax(150px, 1fr))",
    // gap: 2,
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: 2,
  },
  item: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "10px",
    border: "1px solid #eee",
    columnGap: 1.5,
    p: "8px 15px",
    cursor: "pointer",
    "& p": {
      fontSize: 14,
    },
  },

  phoneWrapper: {
    "& .phoneInputWrapper": {
      width: "100%",
      "& .react-tel-input .form-control": {
        borderColor: "#eee",
      },
    },
  },
  crossWrapper: {
    display: "inline-block",
    position: "relative",
    "& svg": {
      position: "absolute",
      top: 10,
      right: 10,
      background: "rgba(255, 255, 255, 0.3)",
      backdropFilter: "blur(20px)",
      borderRadius: "50%",
      p: 0.5,
      cursor: "pointer",
    },
  },
  iconBox: {
    bgcolor: "#fbf6eb",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 30,
    minWidth: 30,
    height: 30,
    "& svg": {
      width: 15,
    },
  },
  floatBtn: {
    position: "fixed",
    right: 20,
    bottom: 20,
    background: "#D3A23B",
    width: 40,
    height: 40,
    "& svg": {
      color: "#fff",
      width: 20,
      height: 20,
    },
  },
  templateWrapper: {
    cursor: "pointer",
    width: 100,
    height: 200,
    objectFit: "contain",
  },
  socialIconsColor: {
    fill: "rgb(28, 95, 174)",
  },
};
export default styles;
