const styles = {
  label: {
    color: "#000",
    fontWeight: 400,
    fontSize: 16,
    marginBottom: "7px",
    display: "block",
    "& > *": {
      color: "#fa5252",
    },
  },
};

export default styles;
