import Joi from "joi";

const SendEmailSchema = Joi.object({
  subject: Joi.string().max(500).required().messages({
    "string.empty": `Please enter subject`,
    "string.max": `Subject must be maximum 500 characters!`,
  }),
  type: Joi.string().required().messages({
    "string.empty": `Please select community database member`,
  }),
  body: Joi.string().max(5000).required().messages({
    "string.empty": `Please enter body`,
    "string.max": `Body must be maximum 5000 characters!`,
  }),
});

export default SendEmailSchema;
