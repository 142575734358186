import { useEffect, useState } from "react";
import SplashForm from "../../../components/auth/SplashForm";
import routes from "../../../data/constants/routes";
import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { loggedUserAtom } from "../../../data/store";
import { validator } from "../../../utils/helpers/validator";
import { OTPSchema } from "../../../utils/schemas/AuthSchema";
import { post } from "../../../server";
import {
	CUSTOMER_FORGET_PASSWORD,
	CUSTOMER_FORGET_PASSWORD_VERIFY_OTP,
} from "../../../data/constants/apiRoutes";
import FormInput from "../../../components/forms/FormInput";
import { Box } from "@mui/material";

const styles = {
	email: {
		display: "flex",
		justifyContent: "flex-end",
		"& label": {
			cursor: "pointer",
			fontWeight: 500,
		},
	},
};

const VerifyOTP = () => {
	const [otpForm, setOtpForm] = useState({
		otp: "",
	});
	const [errors, setErrors] = useState({});

	const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	useEffect(() => {
		if (loggedUser)
			setOtpForm({
				...otpForm,
				id: loggedUser.id,
			});
	}, []);

	const handleChange = (e) => {
		const name = e.target.name;
		let value = e.target.value;
		if (!/^\d{0,4}$/.test(value)) {
			value = value.slice(0, 4);
		}
		setOtpForm({ ...otpForm, [name]: value });
	};

	const validateForm = () => {
		let newErrors = validator(otpForm, OTPSchema);
		setErrors(newErrors);
		if (Object.keys(newErrors).length) return false;
		return true;
	};

	const handleResetPassword = async () => {
		const { status, message, data } = await post(
			CUSTOMER_FORGET_PASSWORD_VERIFY_OTP,
			{ id: otpForm.id, otp: otpForm.otp }
		);
		if (status === true && data.is_verified) {
			enqueueSnackbar(message, { variant: "success" });
			navigate(routes.CUSTOMER_CREATE_NEW_PASSWORD);
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};

	const mutation = useMutation(handleResetPassword);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!validateForm()) return;
		mutation.mutate();
	};

	const handleResendEmail = async () => {
		const { status, message, data } = await post(CUSTOMER_FORGET_PASSWORD, {
			email: loggedUser.email,
		});
		if (status === true) {
			enqueueSnackbar(message, { variant: "success" });
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};


	return (
		<>
			<SplashForm
				title="Verify OTP"
				taglinePart1="We just sent a verification code to"
				email={loggedUser?.email}
				taglinePart2=". If it isn't in your inbox, please check your spam folder"
				btnText="Verify"
				handleSubmit={handleSubmit}
				isLoading={mutation.isLoading}
			>
				<>
					<FormInput
						type="number"
						name="otp"
						value={otpForm.otp}
						onChange={handleChange}
						placeholder="OTP Code"
						error={errors?.otp}
						helperText={errors?.otp || ""}
						onKeyDown={(e) => {
							if (e.key === "Enter") handleSubmit(e);
						}}
					/>

					<Box sx={styles.email}>
						<Box component="label" onClick={handleResendEmail}>
							Didn't receive an email?
						</Box>
					</Box>
				</>
			</SplashForm>
		</>
	);
};

export default VerifyOTP;
