import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from '../../assets/styles/unauthorize.styles'


const PageNotFound = () => {
  const navigate = useNavigate()
  return (
    <>
      {/* <Typography variant="h1">
        You are Unauthorze to use this resource
      </Typography> */}
      <Box sx={styles}>
        <Typography variant="h1">
          404
        </Typography>
        <Typography variant="h2">
        Page Not Found
        </Typography>
        <Box textAlign="center">
          <Button variant="contained" onClick={() => navigate('/')}>
            Return Home
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default PageNotFound;
