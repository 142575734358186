import { useState, useRef, useEffect } from "react";
import { ReactComponent as RightArrow } from "../../../assets/svg/RightArrow.svg";
import { ReactComponent as ProfileCamera } from "../../../assets/svg/ProfileCamera.svg";
import { ReactComponent as Cross } from "../../../assets/svg/businesscard/Cross.svg";

import { ReactComponent as Email } from "../../../assets/svg/createCard/Email.svg";
import { ReactComponent as Phone } from "../../../assets/svg/createCard/Phone.svg";
import { ReactComponent as Check } from "../../../assets/svg/createCard/Check.svg";
import { ReactComponent as Address } from "../../../assets/svg/createCard/Address.svg";
import { ReactComponent as Website } from "../../../assets/svg/createCard/Website.svg";
import { ReactComponent as Instagram } from "../../../assets/svg/createCard/Instagram.svg";
import { ReactComponent as Facebook } from "../../../assets/svg/createCard/Facebook.svg";
import { ReactComponent as LinkedIn } from "../../../assets/svg/createCard/LinkedIn.svg";
import RightBusinessDrawer from "../../../components/customer/RightBusinessDrawer";
import PageTitle from "../../../components/common/PageTitle";
import CropAvatar from "../../../components/modal/CropAvatar";
import AddIcon from "@mui/icons-material/Add";

import { validator } from "../../../utils/helpers/validator";
import {
	FirstStepSchema,
	ThirdStepSchema,
} from "../../../utils/schemas/CardSchema";

import {
	Stack,
	Box,
	Grid,
	Divider,
	Typography,
	Fab,
	Button,
	IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomInputField from "../../../components/forms/CustomInputField";
import PhoneNumberInput from "../../../components/forms/PhoneNumberInput";
import _ from "lodash";
import { useParams, useNavigate } from "react-router-dom";
import { get, post, put } from "../../../server";
import {
	CARDS_DETAIL,
	CARDS_CREATE,
	IMAGE_UPLOAD_URL,
} from "../../../data/constants/apiRoutes";
import routes from "../../../data/constants/routes";
import CloseIcon from "@mui/icons-material/Close";
import { Close, RemoveRedEyeOutlined } from "@mui/icons-material";
import Loader from "../../../components/Loader";
import { useAtom } from "jotai";
import { mobileViewAtom } from "../../../data/store";
import styles from "../../../assets/styles/common/createCardAndConnection.styles";
import {
	templateImages,
	coverImages,
	PrimaryColor,
	tabs,
} from "../../../data/constants/createCardAndConnectionData";

const schemas = [FirstStepSchema, null, ThirdStepSchema];

const drawerWidth = 350;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
	({ theme, open }) => ({
		flexGrow: 1,
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginRight: -drawerWidth,
		...(open && {
			transition: theme.transitions.create("margin", {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginRight: 0,
		}),
	})
);

const items = [
	{
		id: 1,
		icon: <Email />,
		name: "Email",
	},
	{
		id: 2,
		icon: <Phone />,
		name: "Phone",
	},
	{
		id: 3,
		icon: <Address />,
		name: "Address",
	},
	{
		id: 4,
		icon: <Website />,
		name: "Website",
	},
	{
		id: 5,
		icon: <Instagram />,
		name: "Instagram",
	},
	{
		id: 6,
		icon: <Facebook />,
		name: "Facebook",
	},
	{
		id: 7,
		icon: <LinkedIn />,
		name: "LinkedIn",
	},
	{
		id: 8,
		icon: <LinkedIn />,
		name: "Tiktok",
	},
	{
		id: 9,
		icon: <LinkedIn />,
		name: "Pinterest",
	},
	{
		id: 10,
		icon: <LinkedIn />,
		name: "Threads",
	},
	{
		id: 11,
		icon: <LinkedIn />,
		name: "Clubhouse",
	},
];

const initialCardState = {
	fullName: "",
	title: "",
	department: "",
	profileImage: "",
	backgroundImage: "",
	logoImage: "",
	cardEmails: [],
	cardPhones: [],
	address: "",
	company: "",
	primaryColor: "",
	instagram: "",
	facebook: "",
	website: "",
	linkedin: "",
	cardName: "",
};

const CreateConnection = () => {
	const [mobileView, setMobileView] = useAtom(mobileViewAtom);

	const [open, setOpen] = useState(() => (mobileView ? false : true));
	const [tabNo, setTabNo] = useState(0);
	const [modal, setModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState({});

	const [cardID, setCardID] = useState(null);
	const [card, setCard] = useState(initialCardState);
	const [isCustomBgImg, setIsCustomBgImg] = useState(false);
	const [showItems, setShowItems] = useState({
		address: false,
		website: false,
		instagram: false,
		facebook: false,
		linkedin: false,
	});


	const profileRef = useRef();
	const logoRef = useRef();
	const customBgImgRef = useRef();

	const { id } = useParams();
	const navigate = useNavigate();

	const handleProfileClick = () => {
		profileRef.current.click();
	};

	const handleProfileChange = (e) => {
		setCard({ ...card, profileImage: e.target.files[0] });
		setModal("profileImage");
	};

	const handleLogoClick = () => {
		logoRef.current.click();
	};

	const handleRemoveLogo = () => {
		setCard({ ...card, logoImage: null });
	};

	const handleRemoveCustomBgImg = () => {
		setCard({ ...card, backgroundImage: null });
	};

	const handleRemoveProfile = () => {
		setCard({ ...card, profileImage: null });
	};

	const handleCustomBgImgClick = () => {
		customBgImgRef.current.click();
	};

	const handleLogoChange = (e) => {
		setCard({ ...card, logoImage: e.target.files[0] });
		setModal("logoImage");
	};

	const handleCustomBgImgChange = (e) => {
		setCard({ ...card, backgroundImage: e.target.files[0] });
		setIsCustomBgImg(true);
	};

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const handleUploadImage = async (image, imageType, type) => {
		const formData = new FormData();
		formData.append("image", image);
		formData.append("image_type", imageType);

		const response = await post(`${IMAGE_UPLOAD_URL}`, formData);

		if (response.status) {
			return response.data;
		}
	};

	const validateForm = () => {
		if (schemas[tabNo]) {
			let newErrors = validator(card, schemas[tabNo]);
			setErrors(newErrors);

			if (Object.keys(newErrors).length) return false;
		}

		return true;
	};

	const handleNextClick = async () => {
		if (!validateForm()) return;

		setLoading(true);
		// Send a POST request using Axios
		if (cardID) {
			let payload = _.cloneDeep(card);

			if (card?.profileImage && typeof card?.profileImage === "object") {
				const url = await handleUploadImage(
					card.profileImage,
					"profile_image",
					"profileImage"
				);
				payload.profileImage = url;
			}

			if (card?.logoImage && typeof card?.logoImage === "object") {
				const url = await handleUploadImage(
					card.logoImage,
					"logo_image",
					"logoImage"
				);

				payload.logoImage = url;
			}

			const result = await put(`${CARDS_CREATE}/${cardID}`, payload);
			if (result) {
				setCard(payload);
				setCardID(result?.data?.id);
			}
		} else {
			let payload = _.cloneDeep(card);

			if (card?.profileImage && typeof card?.profileImage === "object") {
				const url = await handleUploadImage(
					card.profileImage,
					"profile_image",
					"profileImage"
				);
				payload.profileImage = url;
			}

			if (card?.logoImage && typeof card?.logoImage === "object") {
				const url = await handleUploadImage(
					card.logoImage,
					"logo_image",
					"logoImage"
				);

				payload.logoImage = url;
			}

			const result = await post(`${CARDS_CREATE}`, payload);
			if (result) {
				setCard(payload);
				setCardID(result?.data?.id);
			}
		}

		setLoading(false);


		if (tabs.length - 1 > tabNo) {
			setTabNo(tabNo + 1);
		}
	};

	const handleSaveClick = async () => {
		// Send a POST request using Axios
		if (cardID) {
			await put(`${CARDS_CREATE}/${cardID}`, card)
				.then((response) => {
					// Handle the successful response here
					navigate(`${routes.CUSTOMER_BUSINESS_CARD}`);
				})
				.catch((error) => {
					// Handle any errors that occurred during the request
					console.error("Error:", error);
				});
		}
	};

	const handlePreviousClick = () => {
		if (tabNo <= 0) {
			navigate(routes.CUSTOMER_BUSINESS_CARD);
		} else {
			setTabNo(tabNo - 1);
		}
	};

	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;

		setCard({ ...card, [name]: value });
	};

	const handleDesignChange = (design) => {
		setCard({ ...card, backgroundImage: design });
		setIsCustomBgImg(false);
	};

	const handlePrimaryColorChange = (color) => {
		setCard({ ...card, primaryColor: color });
	};

	const addNewEmail = () => {
		const newEmails = _.cloneDeep(card.cardEmails);
		newEmails.push({ email: "" });
		setCard({ ...card, cardEmails: newEmails });
	};

	const handleRemoveEmail = (index) => {
		const newEmails = _.cloneDeep(card.cardEmails);
		newEmails.splice(index, 1);
		setCard({ ...card, cardEmails: newEmails });
	};

	const handleEmailChange = (e, index) => {
		let newEmails = _.cloneDeep(card.cardEmails);
		newEmails[index].email = e.target.value;
		setCard({ ...card, cardEmails: newEmails });
	};

	const addNewPhone = () => {
		const newNumbers = _.cloneDeep(card.cardPhones);
		newNumbers.push({ phone: "" });
		setCard({ ...card, cardPhones: newNumbers });
	};

	const handleRemovePhone = (index) => {
		const newNumbers = _.cloneDeep(card.cardPhones);
		newNumbers.splice(index, 1);
		setCard({ ...card, cardPhones: newNumbers });
	};

	const handlePhoneChange = (value, index) => {
		let newNumbers = _.cloneDeep(card.cardPhones);
		newNumbers[index].phone = value;
		setCard({ ...card, cardPhones: newNumbers });
	};

	const handleAddItem = (name) => {
		if (!["email", "phone"].includes(name.toLowerCase())) {
			setShowItems({ ...showItems, [name.toLowerCase()]: true });
		}
	};
	const handleRemoveItem = (name) => {
		if (!["email", "phone"].includes(name.toLowerCase())) {
			setShowItems({ ...showItems, [name.toLowerCase()]: false });
			setCard({ ...card, [name.toLowerCase()]: "" });
		}
	};
	const getCardDetail = async () => {
		setLoading(true);
		if (id) {
			const { status, message, data } = await get(`${CARDS_DETAIL}/${id}`);

			if (status) {
				let newEmails = [];
				let newPhones = [];

				data.cardEmails.forEach((email) =>
					newEmails.push({ email: email.email })
				);

				data.cardPhones.forEach((phone) =>
					newPhones.push({ phone: phone.phone })
				);

				setCardID(id);

				setCard({
					fullName: data.fullName || "",
					department: data.department || "",
					title: data.title || "",
					headline: data.headline || "",
					profileImage: data.profileImage || "",
					instagram: data.instagram || "",
					website: data.website || "",
					backgroundImage: data.backgroundImage || "",
					primaryColor: data.primaryColor || "",
					logoImage: data.logoImage || "",
					address: data.address || "",
					company: data.company || "",
					facebook: data.facebook || "",
					linkedin: data.linkedin || "",
					cardEmails: newEmails,
					cardPhones: newPhones,
					cardName: data.cardName || "",
				});

				setShowItems({
					...showItems,
					instagram: data.instagram ? true : false,
					linkedin: data.linkedin ? true : false,
					facebook: data.facebook ? true : false,
					address: data.address ? true : false,
					website: data.website ? true : false,
				});
			}
		}
		setLoading(false);
	};

	useEffect(() => {
		getCardDetail();
	}, []);

	if (loading) {
		return <Loader />;
	}

	return (
		<>
			<Stack direction="row">
				<Main open={open}>
					<Box sx={styles.pageTitle}>
						<PageTitle title={`${id ? "Edit" : "Create"} Connection`} />
					</Box>

					<Box sx={styles.card}>
						<Box sx={styles.tabWrapper}>
							{tabs.map((tab, index) => (
								<Typography
									sx={[styles.tab, tabNo === index && styles.tabActive]}
									key={tab.id}
								>
									{tab.name}
								</Typography>
							))}
						</Box>

						<Box
							component="form"
							noValidate
							style={tabNo !== 0 ? { display: "none" } : null}
						>
							<Grid container>
								<Grid item md={6} xs={12}>
									<CustomInputField
										label="Full Name"
										name="fullName"
										type="text"
										value={card.fullName}
										onChange={handleChange}
										error={errors?.fullName}
										helperText={errors?.fullName || ""}
									/>
								</Grid>
							</Grid>
							<Divider sx={{ my: 3 }} />
							<Typography component="h2">Affiliation</Typography>
							<Grid container spacing={2}>
								<Grid item md={6} xs={12}>
									<CustomInputField
										label="Title"
										name="title"
										value={card.title}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item md={6} xs={12}>
									<CustomInputField
										label="Department"
										name="department"
										value={card.department}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item md={6} xs={12}>
									<CustomInputField
										label="Company"
										name="company"
										value={card.company}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item md={6} xs={12}>
									<CustomInputField
										label="Headline"
										name="headline"
										value={card.headline}
										onChange={handleChange}
									/>
								</Grid>
							</Grid>
							<Divider sx={{ my: 3 }} />
							<Typography component="h2">Profile</Typography>
							{card.profileImage ? (
								<Box sx={styles.crossWrapper}>
									<Box
										component="img"
										sx={styles.profileImg}
										src={
											card?.profileImage
												? typeof card?.profileImage === "object"
													? URL.createObjectURL(card?.profileImage)
													: card?.profileImage
												: null
										}
									/>
									<Close onClick={handleRemoveProfile} />
								</Box>
							) : (
								<ProfileCamera
									onClick={handleProfileClick}
									className="pointer"
								/>
							)}
							<input
								type="file"
								accept="image/*"
								hidden
								ref={profileRef}
								id="profile"
								onChange={handleProfileChange}
							/>
						</Box>

						<Box style={tabNo !== 1 ? { display: "none" } : null}>
							<Typography component="h2">Template</Typography>
							<Stack direction="row" gap={1} flexWrap="wrap">
								{templateImages.map((img, index) => (
									<Box sx={{ position: "relative" }} key={index}>
										{img.src === card.backgroundImage ? (
											<Box sx={styles.coverImgOverlay}>
												<Check />
											</Box>
										) : null}
										<Box
											key={img.id}
											sx={styles.coverImg}
											onClick={() => handleDesignChange(img.src)}
											component="img"
											src={img.src}
										/>
									</Box>
								))}
								{card.backgroundImage && isCustomBgImg ? (
									<Box sx={styles.crossWrapper}>
										<Box
											component="img"
											sx={styles.coverImg}
											src={
												card?.backgroundImage
													? typeof card?.backgroundImage === "object"
														? URL.createObjectURL(card?.backgroundImage)
														: card?.backgroundImage
													: null
											}
										/>
										<Close onClick={handleRemoveCustomBgImg} />
									</Box>
								) : (
									<ProfileCamera
										onClick={handleCustomBgImgClick}
										className="pointer customBgImg"
									/>
								)}
								<input
									type="file"
									accept="image/*"
									hidden
									ref={customBgImgRef}
									onChange={handleCustomBgImgChange}
								/>
							</Stack>

							<Typography component="h2">Design</Typography>
							<Stack direction="row" gap={1} flexWrap="wrap">
								{coverImages.map((img, index) => (
									<Box sx={{ position: "relative" }} key={index}>
										{img.src === card.backgroundImage ? (
											<Box sx={styles.coverImgOverlay}>
												<Check />
											</Box>
										) : null}
										<Box
											key={img.id}
											sx={styles.coverImg}
											onClick={() => handleDesignChange(img.src)}
											component="img"
											src={img.src}
										/>
									</Box>
								))}
								{card.backgroundImage && isCustomBgImg ? (
									<Box sx={styles.crossWrapper}>
										<Box
											component="img"
											sx={styles.coverImg}
											src={
												card?.backgroundImage
													? typeof card?.backgroundImage === "object"
														? URL.createObjectURL(card?.backgroundImage)
														: card?.backgroundImage
													: null
											}
										/>
										<Close onClick={handleRemoveCustomBgImg} />
									</Box>
								) : (
									<ProfileCamera
										onClick={handleCustomBgImgClick}
										className="pointer customBgImg"
									/>
								)}
								<input
									type="file"
									accept="image/*"
									hidden
									ref={customBgImgRef}
									onChange={handleCustomBgImgChange}
								/>
							</Stack>

							<Divider sx={{ my: 3 }} />
							<Typography component="h2">Primary Color</Typography>
							<Stack direction="row" columnGap={1} pb={2}>
								{PrimaryColor.map((color) => (
									<Box
										key={color.id}
										sx={{
											bgcolor: color.bgcolor,
											...styles.colorBox,
										}}
										onClick={() => handlePrimaryColorChange(color.bgcolor)}
									>
										{color.bgcolor === card.primaryColor ? (
											<Box sx={styles.overlay}>
												<Check />
											</Box>
										) : null}
									</Box>
								))}
							</Stack>
							<Divider sx={{ my: 3 }} />
							<Typography component="h2">Logo</Typography>
							{card.logoImage ? (
								<Box sx={styles.crossWrapper}>
									<Box
										component="img"
										sx={styles.profileImg}
										src={
											card?.logoImage
												? typeof card?.logoImage === "object"
													? URL.createObjectURL(card?.logoImage)
													: card?.logoImage
												: null
										}
									/>
									<CloseIcon onClick={handleRemoveLogo} />
								</Box>
							) : (
								<ProfileCamera onClick={handleLogoClick} className="pointer" />
							)}
							<input
								type="file"
								accept="image/*"
								hidden
								ref={logoRef}
								onChange={handleLogoChange}
							/>
						</Box>

						<Box style={tabNo !== 2 ? { display: "none" } : null}>
							<Typography component="h2">Email</Typography>
							<Grid container>
								<Grid item md={6} xs={12}>
									{card?.cardEmails?.length
										? card.cardEmails.map((single, index) => (
												<Stack
													direction="row"
													columnGap={1.5}
													alignItems="center"
													pt={1}
													key={single.id}
												>
													<CustomInputField
														label="Email"
														value={single.email}
														onChange={(e) => handleEmailChange(e, index)}
														error={
															errors[`cardEmails[${index}].email`]
																? true
																: false
														}
														helperText={
															errors[`cardEmails[${index}].email`] || ""
														}
													/>
													<Cross
														className="pointer"
														onClick={() => handleRemoveEmail(index)}
													/>
												</Stack>
										  ))
										: null}
									<Typography sx={styles.add} onClick={addNewEmail}>
										+Add a Email
									</Typography>
								</Grid>
							</Grid>
							<Divider sx={{ my: 3 }} />
							<Typography component="h2">Phone</Typography>
							<Grid container>
								<Grid item md={6} xs={12} sx={styles.phoneWrapper}>
									{card?.cardPhones?.length
										? card.cardPhones.map((single, index) => (
												<Stack
													direction="row"
													columnGap={1.5}
													alignItems="center"
													pt={1}
													key={single.id}
												>
													<PhoneNumberInput
														value={single.phone}
														onChange={(value) =>
															handlePhoneChange(value, index)
														}
													/>
													<Cross
														onClick={() => handleRemovePhone(index)}
														className="pointer"
													/>
												</Stack>
										  ))
										: null}
									<Typography sx={styles.add} onClick={addNewPhone}>
										+Add a Phone
									</Typography>
								</Grid>
							</Grid>
							<Divider sx={{ my: 3 }} />
							{Object.keys(showItems).length &&
								Object.keys(showItems).map((single) => (
									<>
										{showItems[single] ? (
											<Box key={single.id}>
												<Typography
													component="h2"
													sx={{ textTransform: "capitalize" }}
												>
													{single}
												</Typography>
												<Grid container>
													<Grid item md={6} xs={12}>
														<Stack
															direction="row"
															columnGap={1.5}
															alignItems="center"
															pt={1}
														>
															<CustomInputField
																label={single}
																value={card[single]}
																onChange={handleChange}
																name={single}
																error={errors[single] ? true : false}
																helperText={errors[single] || ""}
															/>
															<Cross
																onClick={() => handleRemoveItem(single)}
																className="pointer"
															/>
														</Stack>
													</Grid>
												</Grid>
												<Divider sx={{ my: 3 }} />
											</Box>
										) : null}
									</>
								))}
							<Typography component="h2">Add Items</Typography>
							<Box sx={styles.itemWrapper}>
								{items.map((item) => (
									<Box
										sx={styles.item}
										key={item.id}
										onClick={() => handleAddItem(item.name)}
									>
										{item.icon}
										<Typography>{item.name}</Typography>
									</Box>
								))}
							</Box>
						</Box>
						<Box style={tabNo !== 3 ? { display: "none" } : null}>
							<Typography component="h2">Card Name</Typography>
							<Grid container>
								<Grid item md={6} xs={12}>
									<CustomInputField
										label="Work"
										name="cardName"
										value={card.cardName}
										onChange={handleChange}
									/>
								</Grid>
							</Grid>
						</Box>
					</Box>
					<Stack direction="row" justifyContent="space-between" pt={3}>
						<Button
							variant="contained"
							sx={styles.cancelBtn}
							onClick={handlePreviousClick}
							disableElevation
						>
							<RightArrow className="mr-1" />
							Back
						</Button>

						<Stack direction="row" columnGap={2}>
							{tabNo !== 0 ? (
								<Button
									variant="contained"
									sx={styles.cancelBtn}
									disableElevation
								>
									Cancel
								</Button>
							) : null}

							<Button
								variant="contained"
								sx={styles.nextBtn}
								disableElevation
								onClick={
									tabNo === tabs.length - 1 ? handleSaveClick : handleNextClick
								}
							>
								{tabNo === tabs.length - 1 ? "Save" : "Next"}
							</Button>
						</Stack>
					</Stack>
				</Main>
				<RightBusinessDrawer
					open={open}
					handleDrawerOpen={handleDrawerOpen}
					handleDrawerClose={handleDrawerClose}
					showActionBtn={false}
					{...card}
				/>
			</Stack>
			<CropAvatar
				modal={modal}
				setModal={setModal}
				image={
					modal === "profileImage"
						? card?.profileImage
							? typeof card?.profileImage === "object"
								? URL.createObjectURL(card?.profileImage)
								: card?.profileImage
							: null
						: card?.logoImage
						? typeof card?.logoImage === "object"
							? URL.createObjectURL(card?.logoImage)
							: card?.logoImage
						: null
				}
				user={card}
				cropType="round"
				setUser={setCard}
			/>

			<IconButton sx={styles.floatBtn} onClick={handleDrawerOpen}>
				<RemoveRedEyeOutlined />
			</IconButton>
		</>
	);
};

export default CreateConnection;
