
const VimeoPlayer = ({ videoId }) => {
  return (
    <div>
      <iframe
        src={`https://player.vimeo.com/video/${videoId}`}
        width="640"
        height="360"
        frameBorder="0"
        allowFullScreen
        title="Vimeo Video Player"
      ></iframe>
    </div>
  );
};

export default VimeoPlayer;
