import React from "react";
import MuiPagination from "@mui/material/Pagination";
import { GridPagination } from "@mui/x-data-grid";

const Pagination = ({ page, onPageChange, className }) => {
  return (
    <MuiPagination
      className={className}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
    />
  );
};

const CustomPagination = (props) => {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
};

export default CustomPagination;
