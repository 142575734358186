import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { ReactComponent as Delivery } from "../../../../assets/svg/In Delivery.svg";
import { ReactComponent as Clock } from "../../../../assets/svg/Clock.svg";
import moment from "moment";
import routes from "../../../../data/constants/routes";
import { useNavigate } from "react-router";

const styles = {
  container: {
    bgcolor: "#fff",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    py: 12,
    mt: 5,
    "& h1": {
      color: "#1E1E1F",
      fontSize: 30,
      fontWeight: 500,
      lineHeight: "36px",
      mt: 4,
      mb: 2,
    },
    "& h2": {
      color: "#bbb",
      fontSize: 24,
      lineHeight: "24px",
      mb: "30px",
    },
    "& h3": {
      color: "#767D90",
      fontSize: 18,
    },
  },
  input: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "transparent",
      minHeight: 42,
      borderRadius: 0,
      p: 0,
      my: 1,
      "& fieldset": {
        border: "1px solid #EEE",
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
          {
            borderColor: "#EEE",
          },
      },
      "&:hover fieldset": {
        borderColor: "#EEE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#EEE",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #EEE",
      borderRadius: "10px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#EEE",
      borderWidth: "1px !important",
    },
    "& input": {
      height: "30px",
      // background: "rgba(226, 227, 228, 0.2)",
      // border: "1px solid #E2E3E4",
      borderRadius: "10px",
      padding: "6px 20px",
      "&::placeholder": {
        color: "#B4B1B6",
        fontWeight: 400,
        opacity: 1,
        fontSize: 15,
      },
    },
  },
  doneBtn: {
    height: 45,
    textTransform: "capitalize",
    width: 100,
  },
};

const OrderCompleteStep = ({ orderData, currentStep, setCurrentStep }) => {
  const navigate = useNavigate();

  return (
    <>
      <Box sx={styles.container}>
        <Delivery />

        <Typography component="h1">
          Your Order is Confirmed, Thank You!
        </Typography>

        <Typography component="h2">
          Your order has been placed and its on its way to being processed
        </Typography>

        <Typography component="h3">Order No: {orderData.orderId} </Typography>

        <TextField
          placeholder="Processing"
          sx={styles.input}
          disabled
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" sx={{ mr: 2 }}>
                <Clock />
              </InputAdornment>
            ),
          }}
        />

        {/* <Typography component="h3">21 Aug 2023, 9:21:52 PM</Typography> */}
        <Typography component="h3">
          {moment(orderData.created_at).format("D MMM YYYY, h:mm:ss A")}
        </Typography>
        <Box mt={2}>
          <Button
            variant="contained"
            sx={styles.doneBtn}
            onClick={() => navigate(routes.CUSTOMER_CARD_ORDER_HISTORY)}
            // isLoading={loading}
          >
            Done
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default OrderCompleteStep;
