import Listing from "../../../components/listing/DataGridListing";
import { ReactComponent as Edit } from "../../../assets/svg/Edit.svg";
import { ReactComponent as Delete } from "../../../assets/svg/Delete.svg";
import PageTitle from "../../../components/common/PageTitle";
import routes from "../../../data/constants/routes";
import {
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  Select,
  MenuItem,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import {
  CARDS_LISTING,
  CARDS_DELETE,
  CARDS_EXPORT,
} from "../../../data/constants/apiRoutes";
import { get, destroy } from "../../../server";
import queryNames from "../../../data/constants/queryNames";
import { useNavigate } from "react-router-dom";
import BoxModal from "../../../components/modal/BoxModal";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import QrCodeModel from "../../../components/modal/QrCodeModel";
import ShareIcon from "@mui/icons-material/Share";
import { Clear, RemoveRedEyeOutlined } from "@mui/icons-material";
import moment from "moment";
import Search from "@mui/icons-material/Search";
import useFeature from "../../../hooks/useFeature";
import LoadingButton from "../../../components/button/LoadingButton";
import { useAtom } from "jotai";
import { loggedUserAtom } from "../../../data/store";
import axios from "axios";

const styles = {
  select: {
    width: { md: 200, xs: "100%" },
    borderRadius: "10px",
    height: 50,
    backgroundColor: "#fff",
    "& .MuiSelect-select": {
      backgroundColor: "transparent",
      fontWeight: 400,
      opacity: 1,
      fontSize: 14,
      color: "#202020",
      borderRadius: "10px",
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #656DFC",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
      borderColor: "#EEE ",
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
    },

    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#656DFC",
      borderWidth: "1px",
    },
    "& span": {
      color: "#9c9c9c",
    },
  },

  searchInput: {
    maxWidth: 340,
    width: "100%",
    "& .MuiOutlinedInput-root": {
      px: "21px",
      backgroundColor: "#fff",
      minHeight: 35,
      borderRadius: "10px",
      "& fieldset": {
        border: "1px solid #EEE",
      },
      "&:hover fieldset": {
        borderColor: "#EEE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#656DFC",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #AEAEAE",
      borderRadius: "10px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#656DFC",
      borderWidth: "1px !important",
    },
    "& input": {
      backgroundColor: "#fff",
      height: "35px",
      borderRadius: "10px",
      fontWeight: 500,
      "&::placeholder": {
        color: "#B4B1B6",
        fontWeight: 400,
        opacity: 1,
        fontSize: 16,
      },
    },

    "& .MuiFormHelperText-root": {
      marginLeft: 0,
    },
  },
  searchBtn: {
    width: 40,
    height: 40,

    "& svg > path": {
      fill: "#135099",
    },
  },
  filledSearchBtn: {
    bgcolor: "#135099",
    width: 40,
    height: 40,
    "&:hover": {
      bgcolor: "#135099",
    },
    "& svg > path": {
      fill: "#fff",
    },
  },
  filterBtn: {
    textTransform: "capitalize",
    bgcolor: "#135099",
    color: "#FFF",
    height: "50px",
    fontSize: 16,
    fontWeight: 600,
    minWidth: 150,
    px: 1,
    boxShadow: 0,
    borderRadius: "10px",
    "&:hover": {
      bgcolor: "#135099",
      color: "#FFF",
    },
  },
};

const cardTypes = [
  {
    id: 1,
    name: "All",
    value: "",
  },
  {
    id: 2,
    name: "Team",
    value: "team",
  },
  {
    id: 3,
    name: "Personal",
    value: "personal",
  },
];

const BuissnessCardsListing = () => {
  const [modal, setModal] = useState(false);
  const [currentCard, setCurrentCard] = useState({});
  const [openQrModal, setOpenQrModal] = useState(false);
  const [cardType, setCardType] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [isHandleChangeSearch, setIsHandleChangeSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [searchedValue, setSearchedValue] = useState("");
  const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);

  const navigate = useNavigate();
  const { updateCount } = useFeature();
  const client = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setIsHandleChangeSearch(true);
    if (e.target.value.length < 1) {
      setIsHandleChangeSearch(false);
      setIsSearch(false);
      setSearchedValue("");
    }
  };

  const handleSearchClick = () => {
    setIsSearch(true);
    setSearchedValue(search);
  };

  const handleClearSearch = () => {
    setIsSearch(false);
    setSearch("");
    setSearchedValue("");
    setIsHandleChangeSearch(false);
  };

  const fetchCards = async (pageNo, limit, searchParam, isSearchParam) => {
    let url;

    url = new URL(CARDS_LISTING);
    url.searchParams.append("page", pageNo + 1);
    url.searchParams.append("limit", limit);
    if (cardType) {
      url.searchParams.append("type", cardType);
    }
    if (isSearch) {
      url.searchParams.append("search", search);
    }

    return await get(url.toString());
  };

  const handleOpen = (data) => {
    setCurrentCard(data);
    setModal(true);
  };
  const handleOpenQR = (data) => {
    setCurrentCard(data);
    setOpenQrModal(true);
  };

  const handleClose = () => {
    setModal(false);
    setCurrentCard({});
  };

  const handleDelete = async () => {
    const { status, message } = await destroy(
      `${CARDS_DELETE}/${currentCard.id}`
    );

    if (status) {
      updateCount("delete");
      enqueueSnackbar(message, { variant: "success" });
    }
    handleClose();
  };

  const mutation = useMutation(handleDelete, {
    onSuccess: () => {
      client.invalidateQueries([queryNames.CARD]);
    },
  });

  const handleConfirm = (e) => {
    e.preventDefault();
    mutation.mutate();
  };

  const handleDownloadFile = async () => {
    const url = CARDS_EXPORT;
    const token = loggedUser.token;

    try {
      const response = await axios.get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const blob = new Blob([response.data], { type: response.data.type });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Cards.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      enqueueSnackbar("Excel file has been downloaded successfully", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("There was a problem with the axios operation:", {
        variant: "error",
      });
    }
  };

  const exportMutation = useMutation(handleDownloadFile);

  const handleClickExport = (e) => {
    e.preventDefault();
    exportMutation.mutate();
  };

  const columns = [
    {
      field: "cardName",
      headerName: "Card Name",
      flex: 1,
      valueGetter: (params) => params.row?.cardName || "-",
    },
    {
      field: "fullName",
      headerName: "Full Name",
      flex: 1,
      valueGetter: (params) => params.row?.fullName || "-",
    },
    {
      field: "cardType",
      headerName: "Card Type",
      flex: 1,
      valueGetter: (params) =>
        params?.row?.team_id === "" || params?.row?.team_id === null
          ? "Personal"
          : "Team",
    },
    {
      field: "teamName",
      headerName: "Team Name",
      flex: 1,
      valueGetter: (params) =>
        params?.row?.teamData ? params?.row?.teamData?.name : "-",
    },
    {
      field: "created_by",
      headerName: "Created By",
      flex: 1,
    },
    {
      field: "created_at",
      headerName: "Created At",
      flex: 1,
      valueGetter: (params) =>
        moment(params.row.created_at).format("D MMM YYYY, h:mm:ss A"),
    },

    {
      field: "action",
      headerName: "Action",
      width: 160,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0.5}>
            <Tooltip title="View Detail" placement="top">
              <IconButton
                onClick={() =>
                  navigate(
                    `${routes.ADMIN_BUSINESS_CARD}/${params.row.uuid}?superAdmin=true`
                  )
                }
                size="small"
                disableRipple
              >
                <RemoveRedEyeOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete" placement="top">
              <IconButton
                onClick={() => {
                  handleOpen(params?.row);
                }}
                size="small"
                disableRipple
              >
                <Delete />
              </IconButton>
            </Tooltip>
            <Tooltip title="Share" placement="top">
              <IconButton
                onClick={() => handleOpenQR(params?.row)}
                size="small"
                disableRipple
              >
                <ShareIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        mb={{ md: 1, xs: 2 }}
      >
        <PageTitle title="KnocKnoc Cards" />
        <Stack direction="row" alignItems="center" gap={2}>
          <Select
            value={cardType}
            sx={styles.select}
            onChange={(e) => setCardType(e.target.value)}
            renderValue={(selected) => {
              if (!selected) {
                return <span>All</span>;
              }

              const found = cardTypes.find(
                (single) => single.value === selected
              );

              return found?.name;
            }}
            displayEmpty
          >
            {cardTypes.map((type) => {
              return (
                <MenuItem value={type.value} key={type.id}>
                  {type.name}
                </MenuItem>
              );
            })}
          </Select>

          <FormControl variant="outlined" sx={styles.searchInput} fullWidth>
            <OutlinedInput
              size="small"
              type="text"
              value={search}
              onChange={handleSearchChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearchClick();
                }
              }}
              endAdornment={
                <InputAdornment position="end">
                  {search && isSearch ? (
                    <Tooltip title="Clear Search" placement="top">
                      <IconButton onClick={handleClearSearch}>
                        <Clear />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Search" placement="top">
                      <IconButton
                        onClick={handleSearchClick}
                        sx={
                          isHandleChangeSearch
                            ? styles.filledSearchBtn
                            : styles.searchBtn
                        }
                      >
                        <Search />
                      </IconButton>
                    </Tooltip>
                  )}
                </InputAdornment>
              }
              placeholder="Search..."
            />
          </FormControl>

          <LoadingButton
            variant="contained"
            disableElevation
            sx={styles.filterBtn}
            onClick={handleClickExport}
            isLoading={exportMutation.isLoading}
          >
            Export
          </LoadingButton>
        </Stack>
      </Stack>
      <Listing
        columns={columns}
        fetchRecords={fetchCards}
        queryName={queryNames.CARD}
        search={searchedValue}
        isSearch={isSearch}
        type={cardType}
      />

      <ConfirmationModal
        header="Are you sure?"
        title={`Do you really want to delete this record. This process cannot be undone.`}
        confirmButton="Delete"
        confirmButtonColor="#E53935"
        open={modal}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        isLoading={mutation.isLoading}
      />

      <QrCodeModel
        open={openQrModal}
        handleClose={() => {
          setOpenQrModal(false);
          setCurrentCard({});
        }}
        uuid={currentCard.uuid}
        personName={currentCard.fullName}
        qrCodeUrl={currentCard?.qrCodeUrl}
        shareURL={currentCard?.shareURL}
      />
    </>
  );
};

export default BuissnessCardsListing;
