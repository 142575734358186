const styles = {
  tabsWrapper: {
    border: "1.5px solid #D3A23B",
    padding: "3px",
    marginBottom: 2,
    ml: 2,
    borderRadius: "10px",
  },
  tab: {
    py: 1,
    "&.MuiButtonBase-root": {
      textTransform: "capitalize",
    },
    "&.MuiTab-root.MuiButtonBase-root": {
      //   color: "#fff",
      fontWeight: 600,
      fontSize: 16,
      width: { md: 180, xs: 150 },
      whiteSpace: "nowrap",
    },
    "&.MuiTab-root.Mui-selected": {
      backgroundColor: "#D3A23B",
      color: "#fff",
      borderRadius: "10px",
      fontWeight: 700,
    },
  },
};

export default styles;
