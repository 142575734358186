import PhoneInput from "react-phone-input-2";
import { Box, FormHelperText } from "@mui/material";
import "react-phone-input-2/lib/style.css";
import styles from "../../assets/styles/forms/phoneNumberInput.styles";

const PhoneNumberInput = ({
  error,
  helperText,
  ...other
}) => {
  return (
    <>
      <Box component="div" className={"phoneInputWrapper"}>
        <PhoneInput
          inputClass={
            error ? "phoneNumberInput errorInput" : "phoneNumberInput"
          }
          country={"us"}
          enableSearch
          {...other}
        />
        {helperText ? (
          <FormHelperText sx={styles.errorMessage}>{helperText}</FormHelperText>
        ) : null}
      </Box>
    </>
  );
};

export default PhoneNumberInput;
