import Joi from "joi";

const NotificationSchema = Joi.object({
  title: Joi.string().max(500).required().messages({
    "string.empty": `Please enter title`,
    "string.max": `Title must be maximum 500 characters!`,
  }),
  customer_type: Joi.string().required().messages({
    "string.empty": `Please select customer type`,
  }),
  description: Joi.string().max(5000).required().messages({
    "string.empty": `Please enter description`,
    "string.max": `Description must be maximum 5000 characters!`,
  }),
});

export default NotificationSchema;
