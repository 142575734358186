import { useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { ReactComponent as Search } from "../../../assets/svg/Search.svg";
import SubTitles from "../../../components/common/SubTitles";
import Product1 from "../../../assets/images/Product 1.png";
import Product2 from "../../../assets/images/Product 2.png";
import Product3 from "../../../assets/images/Product 3.png";
import Product4 from "../../../assets/images/Product 4.png";
import Product5 from "../../../assets/images/Product 5.png";
import Product6 from "../../../assets/images/Product 6.png";
import { useNavigate } from "react-router-dom";
import Overlay from "../../../components/overlay/Overlay";
import PageTitle from "../../../components/common/PageTitle";
import routes from "../../../data/constants/routes";
import {
  PRODUCT_CUSTOMER_LISTING,
  POPULAR_PRODUCT_CUSTOMER_LISTING,
  COMMUNITY_PRODUCT_CUSTOMER_LISTING,
} from "../../../data/constants/apiRoutes";
import { get } from "../../../server";
import queryNames from "../../../data/constants/queryNames";
import { useQuery } from "react-query";
import Pagination from "../../../components/pagination/Pagination";
import Loader from "../../../components/Loader";
import FormTab from "../../../components/tabs/FormTab";
import TabPanel from "../../../components/tabs/TabPanel";

const styles = {
  title: {
    justifyContent: "space-between",
    alignItems: "center",
    "& h2": {
      color: "#1E1E1F",
      fontSize: 24,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "36px",
    },
  },
  searchInput: {
    maxWidth: 340,
    width: "100%",
    backgroundColor: "#FFF",
    borderRadius: "10px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
      border: "1px solid #EEE",
    },
    "& input": {
      py: "13px",
      px: "21px",
      "&::placeholder": {
        color: "#767D90",
        fontWeight: 400,
        fontSize: 16,
      },
    },
  },
  buttons: {
    mb: "29px",
    "& button": {
      borderRadius: "10px",
      fontSize: 16,
      bgcolor: "#E8EEF5",
      color: "#000",
      textTransform: "capitalize",
      boxShadow: 0,
      px: "20px",
      "&:hover": {
        bgcolor: "#E8EEF5",
        color: "#000",
      },
    },
  },
  container: {
    bgcolor: "#FFF",
    borderRadius: "20px",
    boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.06)",
    maxWidth: "100%",
    pb: 2,
    cursor: "pointer",
    width: 180,
    gap: 2,
    "& img": {
      borderRadius: "20px",
      width: "100%",
      objectFit: "cover",
      maxHeight: 110,
    },
    "& h5": {
      color: "#000",
      px: "21px",
      fontSize: 18,
      mt: "12px",
      fontWeight: 500,
    },
    "& label": {
      color: "#767D90",
      fontSize: 16,
      px: "21px",
    },
  },
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // minHeight: "calc(100vh - 64px)",

    // width: "100%",
    mt: 5,
    "& h1": {
      fontSize: 24,
      color: "#000",
      fontWeight: 500,
    },
  },
  tabsWrapper: {
    "& .MuiTab-root.MuiButtonBase-root": {
      //   color: "#fff",
      fontWeight: { md: 600, xs: 500 },
      fontSize: 16,
      width: { md: 200, xs: 180 },
      whiteSpace: "nowrap",
    },
    "& .MuiTab-root.Mui-selected": {
      backgroundColor: "#D3A23B",
      color: "#fff",
      borderRadius: "10px",
      fontWeight: { md: 700, xs: 600 },
    },
  },
};

const dummyProducts1 = [
  {
    title: "Product Title",
    price: 3.72,
    image: Product1,
  },
  {
    title: "Product Title",
    price: 3.72,
    image: Product2,
  },
  {
    title: "Product Title",
    price: 3.72,
    image: Product3,
  },
  {
    title: "Product Title",
    price: 3.72,
    image: Product4,
  },
  {
    title: "Product Title",
    price: 3.72,
    image: Product5,
  },
];

const dummyProducts2 = [
  {
    title: "Product Title",
    price: 3.72,
    image: Product6,
  },
  {
    title: "Product Title",
    price: 3.72,
    image: Product3,
  },
  {
    title: "Product Title",
    price: 3.72,
    image: Product3,
  },
  {
    title: "Product Title",
    price: 3.72,
    image: Product1,
  },
  {
    title: "Product Title",
    price: 3.72,
    image: Product5,
  },
];

const ProductListing = () => {
  const [popularProducts, setPopularProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [totalCount, setTotalCount] = useState(0);
  const [imageLoading, setImageLoading] = useState([]);
  const [popularImagesLoading, setPopularImagesLoading] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  const [search, setSearch] = useState("");
  const [searchedValue, setSearchedValue] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [isHandleChangeSearch, setIsHandleChangeSearch] = useState(false);

  const navigate = useNavigate();

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setIsHandleChangeSearch(true);
    if (e.target.value.length < 1) {
      setIsHandleChangeSearch(false);
      setIsSearch(false);
      setSearchedValue("");
    }
  };

  const handleSearchClick = () => {
    setIsSearch(true);
    setSearchedValue(search);
  };

  const handleClearSearch = () => {
    setIsSearch(false);
    setSearch("");
    setSearchedValue("");
    setIsHandleChangeSearch(false);
  };

  // const [selectedCategory, setSelectedCategory] = useState("all");

  // const handleCategoryClick = (category) => {
  // 	setSelectedCategory(category);
  // };

  const fetchProducts = async () => {
    let url = new URL(PRODUCT_CUSTOMER_LISTING);
    url.searchParams.append("page", paginationModel.page + 1);
    url.searchParams.append("limit", paginationModel.pageSize);
    if (searchedValue.length) {
      url.searchParams.append("search", searchedValue);
    }

    const { status, message, data } = await get(url.toString());
    if (status) {
      setProducts(data.data);
      setTotalCount(data.totalCount);
      // const imageLoadingArray = data.data.map((product) =>
      // 	product.images.map((image) => ({
      // 		id: image.id,
      // 		loading: true,
      // 	}))
      // );
      const imageLoadingArray = data.data.map((product) => ({
        id: product.id,
        loading: product.images.length > 0,
      }));

      setImageLoading(imageLoadingArray);
    }
  };
  const fetchPopularProducts = async () => {
    let url = new URL(COMMUNITY_PRODUCT_CUSTOMER_LISTING);
    url.searchParams.append("page", paginationModel.page + 1);
    url.searchParams.append("limit", paginationModel.pageSize);
    if (searchedValue.length) {
      url.searchParams.append("search", searchedValue);
    }

    const { status, message, data } = await get(url.toString());

    if (status) {
      // setPopularProducts(data);
      setProducts(data.data);
      setTotalCount(data.totalCount);
      // const imageLoadingArray = data.data.map((product) =>
      // 	product.images.map((image) => ({
      // 		id: image.id,
      // 		loading: true,
      // 	}))
      // );
      // const popularImageLoadingArray = data.map((product) => ({
      //   id: product.id,
      //   loading: product.images.length > 0,
      // }));

      // setPopularImagesLoading(popularImageLoadingArray);

      const imageLoadingArray = data.data.map((product) => ({
        id: product.id,
        loading: product.images.length > 0,
      }));

      setImageLoading(imageLoadingArray);
    }
  };

  const listingQuery = useQuery(
    [queryNames.CUSTOMER_PRODUCTS, paginationModel.page, searchedValue],
    fetchProducts,
    {
      enabled: tabValue === 0,
      refetchOnWindowFocus: false,
    }
  );

  const popularListingQuery = useQuery(
    [
      queryNames.CUSTOMER_COMMUNITY_PRODUCTS,
      paginationModel.page,
      searchedValue,
    ],
    fetchPopularProducts,
    {
      enabled: tabValue === 1,
      refetchOnWindowFocus: false,
    }
  );

  if (
    listingQuery.isLoading ||
    listingQuery.isFetching ||
    popularListingQuery.isLoading ||
    popularListingQuery.isFetching
  ) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle
        title="Community eCommerce"
        searchInput
        placeholder="Search..."
        search={search}
        setSearch={setSearch}
        isSearch={isSearch}
        handleClearSearch={handleClearSearch}
        handleSearchClick={handleSearchClick}
        handleSearchChange={handleSearchChange}
        createBtn
        createBtnText="+"
        isCreateProductBtn={true}
        url={routes.CUSTOMER_PRODUCT_ADD}
        isHandleChangeSearch={isHandleChangeSearch}
      />

      <Divider sx={{ borderColor: "#EBEBEE", mb: 2 }} />
      {/* <PageTitle title="Popular Products" /> */}

      <Grid container spacing={2} mt={2}>
        <Box sx={styles.tabsWrapper}>
          <FormTab
            tabValue={tabValue}
            setTabValue={setTabValue}
            tabs={["All Products", "Community Products"]}
            centered
          />
        </Box>
      </Grid>

      {/* {popularProducts?.length ? (
        <Box
          display="flex"
          sx={{
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 2,
          }}
        >
          {popularProducts?.map((product) => (
            <Box
              sx={styles.container}
              onClick={() => navigate(`/product/detail/${product?.id}`)}
              key={product.id}
            >
              <Box position="relative">
                <Box
                  component="img"
                  src={
                    product?.images.length
                      ? product?.images.find((image) => image.type === "image")
                          .path
                      : Product6
                  }
                  loading="lazy"
                  onLoad={() => {
                    const images = imageLoading.map((image) =>
                      image.id === product.id
                        ? { ...image, loading: false }
                        : image
                    );
                    setPopularImagesLoading(images);
                  }}
                  // sx={{
                  // 	display: imageLoading.find(
                  // 		(image) => image.id === product.id
                  // 	).loading
                  // 		? "none"
                  // 		: "block",
                  // }}
                  sx={{ width: 180, height: 114 }}
                />
                {popularImagesLoading?.find((image) => image.id === product.id)
                  ?.loading && (
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="100%"
                    height={114}
                    sx={{
                      borderRadius: "20px",
                      position: "absolute",
                      top: 0,
                    }}
                  />
                )}
              </Box>
              <Typography component="h5">{product?.name}</Typography>
              <Typography component="label">${product?.price}</Typography>
            </Box>
          ))}
        </Box>
      ) : (
        <Box sx={styles.noData}>
          <Typography component="h1">No record found</Typography>
        </Box>
      )} */}
      <PageTitle
        title={tabValue === 0 ? "All Products" : "Community Products"}
      />

      {/* <Stack direction="row" sx={styles.buttons} gap="20px" flexWrap="wrap">
				<Button
					variant="contained"
					sx={{
						bgcolor:
							selectedCategory === "all" ? "#D3A23B !important" : "#E8EEF5",
						color: selectedCategory === "all" ? "#FFF!important" : "#000",
					}}
					onClick={() => handleCategoryClick("all")}
				>
					All
				</Button>

				<Button
					variant="contained"
					sx={{
						bgcolor:
							selectedCategory === "cat1" ? "#D3A23B !important" : "#E8EEF5",
						color: selectedCategory === "cat1" ? "#FFF!important" : "#000",
					}}
					onClick={() => handleCategoryClick("cat1")}
				>
					Categories 1
				</Button>

				<Button
					variant="contained"
					sx={{
						bgcolor:
							selectedCategory === "cat2" ? "#D3A23B !important" : "#E8EEF5",
						color: selectedCategory === "cat2" ? "#FFF!important" : "#000",
					}}
					onClick={() => handleCategoryClick("cat2")}
				>
					Categories 2
				</Button>

				<Button
					variant="contained"
					sx={{
						bgcolor:
							selectedCategory === "cat3" ? "#D3A23B !important" : "#E8EEF5",
						color: selectedCategory === "cat3" ? "#FFF!important" : "#000",
					}}
					onClick={() => handleCategoryClick("cat3")}
				>
					Categories 3
				</Button>

				<Button
					variant="contained"
					sx={{
						bgcolor:
							selectedCategory === "cat4" ? "#D3A23B !important" : "#E8EEF5",
						color: selectedCategory === "cat4" ? "#FFF!important" : "#000",
					}}
					onClick={() => handleCategoryClick("cat4")}
				>
					Categories 4
				</Button>
			</Stack> */}

      {/* <SubTitles title="Popular Products" sideTitle="View all" />

			<Grid container spacing="20px" mt="1px">
				{dummyProducts1.map((product, index) => (
					<Grid item key={index} xs={12} sm={6} md={4} lg={2.4}>
						<Box
							sx={styles.container}
							onClick={() => navigate("/product/detail")}
						>
							<Box component="img" src={product.image} />
							<Typography component="h5">{product.title}</Typography>
							<Typography component="label">${product.price}</Typography>
						</Box>
					</Grid>
				))}
			</Grid>

			<Divider sx={{ borderColor: "#EBEBEE", mt: 5, mb: "20px" }} />

			<SubTitles title="Featured Products" sideTitle="View all" />

			<Grid container spacing="20px" mt="1px">
				{dummyProducts2.map((product, index) => (
					<Grid item key={index} xs={12} sm={6} md={4} lg={2.4}>
						<Box
							sx={styles.container}
							onClick={() => navigate("/product/detail")}
						>
							<Box component="img" src={product.image} />
							<Typography component="h5">{product.title}</Typography>
							<Typography component="label">${product.price}</Typography>
						</Box>
					</Grid>
				))}
			</Grid> */}

      {products?.length ? (
        <Box
          display="flex"
          sx={{
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 2,
          }}
        >
          {products?.map((product) => (
            <Box
              sx={styles.container}
              onClick={() => navigate(`/product/detail/${product?.id}`)}
              key={product.id}
            >
              <Box position="relative">
                <Box
                  component="img"
                  src={
                    product?.images?.length
                      ? product?.images?.find((image) => image.type === "image")
                          ?.path
                      : Product6
                  }
                  loading="lazy"
                  onLoad={() => {
                    const images = imageLoading.map((image) =>
                      image.id === product.id
                        ? { ...image, loading: false }
                        : image
                    );
                    setImageLoading(images);
                  }}
                  // sx={{
                  // 	display: imageLoading.find(
                  // 		(image) => image.id === product.id
                  // 	).loading
                  // 		? "none"
                  // 		: "block",
                  // }}
                  sx={{ width: 180, height: 114 }}
                />
                {imageLoading.find((image) => image.id === product.id)
                  .loading && (
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="100%"
                    height={114}
                    sx={{
                      borderRadius: "20px",
                      position: "absolute",
                      top: 0,
                    }}
                  />
                )}
              </Box>
              <Typography component="h5">{product?.name}</Typography>
              <Typography component="label">${product?.price}</Typography>
            </Box>
          ))}
        </Box>
      ) : (
        <Box sx={styles.noData}>
          <Typography component="h1">No record found</Typography>
        </Box>
      )}

      {products.length > 0 && (
        <Pagination
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          totalCount={totalCount}
        />
      )}
    </>
  );
};

export default ProductListing;
