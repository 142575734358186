import Joi from "joi";

const CustomerSchema = Joi.object({
  first_name: Joi.string().max(25).required().messages({
    "string.empty": `Please enter first name`,
    "string.max": `First name must be maximum 25 characters!`,
  }),
  last_name: Joi.string().max(25).required().messages({
    "string.empty": `Please enter last name`,
    "string.max": `Last name must be maximum 25 characters!`,
  }),
  email: Joi.string()
    .max(70)
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.empty": `Please enter email address`,
      "string.email": `Please enter a valid email address`,
      "string.max": `Email must be maximum 70 characters!`,
    }),

  password: Joi.string()
    .min(8)
    .when("id", {
      is: null,
      then: Joi.required(),
      otherwise: Joi.optional().allow("", null),
    })
    .messages({
      "string.empty": `Please enter password`,
      "string.base": `Please enter password`,
      "string.min": `Password must be at least 8 characters long`,
      "any.only": `Password and confirm password does not match`,
    }),

  confirm_password: Joi.string()
    .valid(Joi.ref("password"))
    .when("id", {
      is: null,
      then: Joi.required(),
      otherwise: Joi.optional().allow("", null),
    })
    .messages({
      "string.empty": `Please enter confirm password `,
      "any.only": `Password and confirm password does not match`,
      "string.base": `Please enter confirm password`,
    }),
});

export default CustomerSchema;
