import { useMemo, useState } from "react";
import PageTitle from "../../../components/common/PageTitle";
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  Menu,
  MenuItem,
  Divider,
  FormControl,
  OutlinedInput,
  Tooltip,
} from "@mui/material";
import { Clear, Search } from "@mui/icons-material";
import { get, destroy, post } from "../../../server";
import {
  ADMIN_CHANGE_STATUS,
  CONTACT_US,
} from "../../../data/constants/apiRoutes";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import DataGridListing from "../../../components/listing/DataGridListing";
import Loader from "../../../components/Loader";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useMutation, useQuery, useQueryClient } from "react-query";
import queryNames from "../../../data/constants/queryNames";
import routes from "../../../data/constants/routes";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import ContactUsStatusModal from "../../../components/modal/ContactUsStatusModal";
import { capitalizeWords } from "../../../utils/helpers/formatter";

const styles = {
  dropDownMenu: {
    elevation: 0,
    sx: {
      overflow: "visible",
      // filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
      boxShadow: "0 6px 5px rgba(0, 0, 0, 0.06)",
      mt: 1.5,
      width: 200,
      borderRadius: "10px",
      "& .MuiAvatar-root": {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
      },
      "&:before": {
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: "background.paper",
        transform: "translateY(-50%) rotate(45deg)",
        zIndex: 0,
      },
    },
  },

  searchInput: {
    maxWidth: 340,
    width: "100%",
    "& .MuiOutlinedInput-root": {
      px: "21px",
      backgroundColor: "#fff",
      minHeight: 35,
      borderRadius: "10px",
      "& fieldset": {
        border: "1px solid #EEE",
      },
      "&:hover fieldset": {
        borderColor: "#EEE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#656DFC",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #AEAEAE",
      borderRadius: "10px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#656DFC",
      borderWidth: "1px !important",
    },
    "& input": {
      backgroundColor: "#fff",
      height: "35px",
      borderRadius: "10px",
      fontWeight: 500,
      "&::placeholder": {
        color: "#B4B1B6",
        fontWeight: 400,
        opacity: 1,
        fontSize: 16,
      },
    },

    "& .MuiFormHelperText-root": {
      marginLeft: 0,
    },
  },
};
const ContactUsListing = () => {
  const [contactUs, setContactUs] = useState([]);
  const [id, setId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showStatusModal, setshowStatusModal] = useState(false);
  const [selectedStatusData, setSelectedStatusData] = useState({});
  const [filterText, setFilterText] = useState("");

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const open = Boolean(anchorEl);

  const handleClick = (event, obj) => {
    setAnchorEl(event.currentTarget);
    setId(obj.id);
    setSelectedStatusData(obj);
  };
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setId(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenStatusModal = () => {
    setshowStatusModal(true);
  };
  const handleCloseStatusModal = () => {
    setSelectedStatusData({});
    setshowStatusModal(false);
    setId(null);
  };

  const fetchContactUs = async () => {
    let { status, message, data } = await get(CONTACT_US);
    if (status) {
      setContactUs(data);
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const allContactUsQuery = useQuery(queryNames.CONTACT_US, fetchContactUs, {
    refetchOnWindowFocus: false,
  });

  const client = useQueryClient();

  const handleDelete = async () => {
    const { status, message } = await destroy(`${CONTACT_US}/${id}`);

    if (status) {
      enqueueSnackbar(message, { variant: "success" });
      handleClose();
      handleCloseDeleteModal();
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const mutation = useMutation(handleDelete, {
    onSuccess: () => {
      client.invalidateQueries([queryNames.CONTACT_US]);
    },
  });

  const handleConfirm = () => {
    mutation.mutate();
  };

  const handleFormRequest = async () => {
    const { status, message } = await post(
      `${ADMIN_CHANGE_STATUS}/${selectedStatusData.id}`,
      { status: selectedStatusData.status }
    );
    if (status) {
      enqueueSnackbar(message, { variant: "success" });
      const allContacts = contactUs.map((contact) =>
        contact.id === selectedStatusData.id
          ? { ...contact, status: selectedStatusData.status }
          : contact
      );
      setContactUs(allContacts);
      handleCloseStatusModal();
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const statusMutation = useMutation(handleFormRequest);

  const handleSubmit = (e) => {
    e.preventDefault();
    statusMutation.mutate();
  };

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  const filteredItems = useMemo(() => {
    const filtered = contactUs.filter((item) =>
      `${item.name || ""} ${item.email || ""} ${item.message || ""} ${
        item.status || ""
      }`
        .toLowerCase()
        .includes(
          filterText.toLowerCase().includes("progress") ||
            filterText.toLowerCase().includes("in progress")
            ? "in_progress"
            : filterText.toLowerCase()
        )
    );

    return filtered;
  }, [filterText, contactUs]);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      sortable: false,
      width: 200,
    },
    {
      field: "email",
      headerName: "Email",
      sortable: false,
      width: 200,
    },

    {
      field: "message",
      headerName: "Message",
      sortable: false,
      flex: 1,
    },
    {
      field: "status",
      headerName: "status",
      sortable: false,
      width: 250,
      valueGetter: (params) =>
        params.row.status
          ? params.row.status === "in_progress"
            ? "In Progress"
            : capitalizeWords(params.row.status)
          : "",
    },

    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              disableRipple
              onClick={(e) => handleClick(e, params.row)}
              size="small"
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={styles.dropDownMenu}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                onClick={() =>
                  navigate(`${routes.ADMIN_CONTACT_US_DETAIL}/${id}`)
                }
              >
                Detail
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleOpenStatusModal}>Change Status</MenuItem>
              <Divider />
              <MenuItem onClick={() => setShowDeleteModal(true)}>
                Delete
              </MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];

  if (allContactUsQuery.isFetching) {
    return <Loader />;
  }

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <PageTitle title="Contact Us Queries" />

        <FormControl
          variant="outlined"
          value={filterText}
          onChange={handleFilter}
          sx={{ ...styles.searchInput, ...styles.topSearch }}
        >
          <OutlinedInput
            size="small"
            type="text"
            value={filterText}
            endAdornment={
              <InputAdornment position="end">
                {filterText.length > 0 ? (
                  <Tooltip title="Clear Search">
                    <IconButton onClick={() => setFilterText("")}>
                      <Clear />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Search />
                )}
              </InputAdornment>
            }
            placeholder="Search..."
          />
        </FormControl>
      </Stack>
      <Box mt={3}>
        <DataGridListing rows={filteredItems} columns={columns} mode="client" />
      </Box>

      <ConfirmationModal
        header="Are you sure?"
        title={`Do you really want to delete this record. This process cannot be undone.`}
        open={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleConfirm={handleConfirm}
        confirmButton="Delete"
        confirmButtonColor="#E53935"
      />
      <ContactUsStatusModal
        open={showStatusModal}
        selectedData={selectedStatusData}
        setSelectedData={setSelectedStatusData}
        handleClose={handleCloseStatusModal}
        handleSubmit={handleSubmit}
        isLoading={statusMutation.isLoading}
        title="Change Status"
        label="Status"
        name="status"
        options={[
          { value: "pending", name: "Pending" },
          { value: "in_progress", name: "In Progress" },
          { value: "completed", name: "Completed" },
          { value: "cancelled", name: "Cancelled" },
        ]}
      />
    </>
  );
};

export default ContactUsListing;
