import {
  Modal,
  Box,
  Stack,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import styles from "../../assets/styles/modal/confirmationModal.styles";
import { Close } from "@mui/icons-material";
import LoadingButton from "../button/LoadingButton";
import routes from "../../data/constants/routes";
import { Link, useNavigate } from "react-router-dom";

const FeatureModal = ({
  open,
  handleClose,
  // handleConfirm,
  header,
  title,
  confirmButton,
  confirmButtonColor,
  isLoading,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.container}>
          <Box sx={styles.closeIconBtnWrapper}>
            <IconButton onClick={handleClose} sx={styles.closeIconBtn}>
              <Close />
            </IconButton>
          </Box>

          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <Box component="div" sx={styles.iconBox}>
              <Close sx={styles.crossIcon} />
            </Box>
            <Typography sx={styles.headerText}>
              {header ? header : "Are you sure?"}
            </Typography>
          </Stack>
          <Typography
            id="modal-modal-description"
            py={3}
            fontSize={17}
            textAlign="center"
            sx={styles.confirmTitle}
          >
            {title}
          </Typography>
          <Box sx={styles.btnsWrapper}>
            <Link to={routes.CUSTOMER_SUBSCRIPTION}>
              <Button
                variant="contained"
                sx={styles.subscribBtn}
                onClick={() => {
                  handleClose();
                  navigate(routes.CUSTOMER_SUBSCRIPTION);
                }}
              >
                Subscribe
              </Button>
            </Link>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default FeatureModal;
