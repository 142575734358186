import { useEffect, useState } from "react";
import SplashForm from "../../../components/auth/SplashForm";
import routes from "../../../data/constants/routes";
import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { loggedUserAtom } from "../../../data/store";
import { validator } from "../../../utils/helpers/validator";
import { OTPSchema } from "../../../utils/schemas/AuthSchema";
import { post } from "../../../server";
import {
  CUSTOMER_RESEND_OTP,
  CUSTOMER_VERIFY_EMAIL,
} from "../../../data/constants/apiRoutes";
import FormInput from "../../../components/forms/FormInput";
import { Box } from "@mui/material";
import _ from "lodash";

const styles = {
  email: {
    display: "flex",
    justifyContent: "flex-end",
    "& label": {
      cursor: "pointer",
    },
  },
};

const VerifyAccount = () => {
  const [otpForm, setOtpForm] = useState({
    otp: "",
  });
  const [user, setUser] = useState({});

  const [errors, setErrors] = useState({});

  const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    if (Object?.keys(loggedUser)?.length !== 0) {
      const newUser = loggedUser;
      setOtpForm({
        ...otpForm,
        email: loggedUser.data?.email,
      });
      setUser(newUser);
      setLoggedUser({});
    }
  }, [loggedUser]);

  const handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    value = value.replace(/\D/g, "");

    if (value.length > 4) {
      value = value.slice(0, 4);
    }

    setOtpForm({ ...otpForm, [name]: value });
  };

  const validateForm = () => {
    let newErrors = validator(otpForm, OTPSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };

  const handleVerifyOTP = async () => {
    const { status, message, data } = await post(CUSTOMER_VERIFY_EMAIL, {
      otp: otpForm.otp,
      email: otpForm.email,
    });
    if (status === true && data.is_verified === true) {
      let newLoggedUser = _.cloneDeep(user);

      newLoggedUser.data.is_password_otp_verified = 1;

      setLoggedUser(newLoggedUser);
      if (user?.data?.login_type === "login") {
        enqueueSnackbar("Login Successful", { variant: "success" });
      } else if (user?.data?.login_type === "signup") {
        enqueueSnackbar("Your account has been created successfully", {
          variant: "success",
        });

        if (data.package_id == 1) {
          navigate(routes.CUSTOMER_SUBSCRIPTION);
        } else {
          navigate(routes.CUSTOMER_CREATE_CARD);
        }
      }

      if (user?.data?.share_card) {
        navigate(routes.CUSTOMER_SUBSCRIPTION);
      } else {
        navigate(routes.CUSTOMER_SUBSCRIPTION);
      }
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const mutation = useMutation(handleVerifyOTP);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    mutation.mutate();
  };

  const handleResendEmail = async () => {
    const { status, message, data } = await post(CUSTOMER_RESEND_OTP, {
      email: user?.data?.email,
    });
    if (status === true) {
      enqueueSnackbar("OTP resent to your email address", {
        variant: "success",
      });
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  return (
    <>
      <SplashForm
        title="Verify OTP"
        taglinePart1="We just sent a verification code to"
        email={user?.data?.email}
        taglinePart2=". If it isn't in your inbox, please check your spam folder"
        btnText="Verify"
        handleSubmit={handleSubmit}
        isLoading={mutation.isLoading}
      >
        <>
          <FormInput
            // type="number"
            name="otp"
            value={otpForm.otp}
            onChange={handleChange}
            placeholder="OTP Code"
            error={errors?.otp}
            helperText={errors?.otp || ""}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSubmit(e);
            }}
          />

          <Box sx={styles.email}>
            <Box component="label" onClick={handleResendEmail}>
              Haven't got a code? Resend
            </Box>
          </Box>
        </>
      </SplashForm>
    </>
  );
};

export default VerifyAccount;
