import { Box, Step, StepLabel, Stepper } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import DoneIcon from "@mui/icons-material/Done";
import AddtoCartStep from "./checkoutSteps/AddtoCartStep";
import CheckoutStep from "./checkoutSteps/CheckoutStep";
import OrderCompleteStep from "./checkoutSteps/OrderCompleteStep";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 15,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#0F4590",
      transition: "background-color 0.3s ease", // Add a smooth transition
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#0F4590",
      transition: "background-color 0.3s ease", // Add a smooth transition
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#EBEBEE",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#F8F8F8",
  border: "1px solid #EBEBEE",
  zIndex: 1,
  color: "#fff",
  width: 40,
  height: 40,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  "& path": {
    fill: "#0F4590",
  },
  ...(ownerState.active && {
    backgroundColor: "#0F4590",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    "& path": {
      fill: "#FFF",
    },
  }),
  ...(ownerState.completed && {
    backgroundColor: "#0F4590",
    "& path": {
      fill: "#FFF",
    },
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {active || completed ? <DoneIcon /> : null}
    </ColorlibStepIconRoot>
  );
}

const Checkout = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const handleNext = () => {
    setCurrentStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setCurrentStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = ["Add to cart", "Checkout", "Order Complete"];

  return (
    <>
      <Box mt={3}>
        <Stepper
          alternativeLabel
          activeStep={currentStep}
          connector={<ColorlibConnector />}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel
                StepIconComponent={ColorlibStepIcon}
                sx={{
                  "& .MuiStepLabel-label": {
                    color: currentStep === index ? "#135099" : "#1E1E1F",
                    fontSize: "16px",
                    fontWeight: 600,
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      {currentStep === 0 && (
        <AddtoCartStep
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      )}

      {currentStep === 1 && (
        <CheckoutStep handleNext={handleNext} handleBack={handleBack} />
      )}

      {currentStep === 2 && (
        <OrderCompleteStep
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      )}
    </>
  );
};

export default Checkout;
