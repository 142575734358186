import { useState } from "react";
import PageTitle from "../../../components/common/PageTitle";
import { Box, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { get } from "../../../server";
import { PAGES } from "../../../data/constants/apiRoutes";
import Loader from "../../../components/Loader";
import { useQuery } from "react-query";
import queryNames from "../../../data/constants/queryNames";

const styles = {
  mainWrapper: {
    backgroundColor: "#F9FAFC",
    p: 4,
    minHeight: "100vh",
  },

  wrapper: {
    bgcolor: "#fff",
    borderRadius: "20px",
    p: 3,
    mt: 2,
  },
};

const PublicPrivacyPolicy = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState({});

  const { enqueueSnackbar } = useSnackbar();

  const fetchPages = async () => {
    let { status, message, data } = await get(PAGES);
    if (status) {
      const privacyData = data?.find((item) => item?.pageType === "privacy");

      setPrivacyPolicy({
        ...privacyPolicy,
        title: privacyData?.title || "Privacy Policy",
        pageType: privacyData?.pageType || "privacy",
        description: privacyData?.description,
      });
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const allPagesQuery = useQuery(queryNames.PAGE, fetchPages, {
    refetchOnWindowFocus: false,
  });

  if (allPagesQuery.isFetching) {
    return <Loader />;
  }

  return (
    <Box sx={styles.mainWrapper}>
      <PageTitle title="Privacy Policy" />
      <Box sx={styles.wrapper}>
        <Typography
          dangerouslySetInnerHTML={{ __html: privacyPolicy?.description }}
        />
      </Box>
    </Box>
  );
};

export default PublicPrivacyPolicy;
