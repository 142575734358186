import { useState } from "react";

import {
  Avatar,
  Stack,
  Tooltip,
  Typography,
  IconButton,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Button,
} from "@mui/material";
import PageTitle from "../../../components/common/PageTitle";

import {
  ADMIN_COMMUNITY_EXPORT,
  ADMIN_COMMUNITY_LISTING,
} from "../../../data/constants/apiRoutes";
import { get } from "../../../server";
import queryNames from "../../../data/constants/queryNames";
import DataGridListing from "../../../components/listing/DataGridListing";
import { Clear, Search } from "@mui/icons-material";
import moment from "moment";
import { useAtom } from "jotai";
import { loggedUserAtom } from "../../../data/store";
import { useSnackbar } from "notistack";
import axios from "axios";
import { useMutation } from "react-query";
import LoadingButton from "../../../components/button/LoadingButton";
import { formatPhoneNumber } from "../../../utils/helpers/formatter";

const styles = {
  searchInput: {
    maxWidth: 340,
    width: "100%",
    "& .MuiOutlinedInput-root": {
      px: "21px",
      backgroundColor: "#fff",
      minHeight: 35,
      borderRadius: "10px",
      "& fieldset": {
        border: "1px solid #EEE",
      },
      "&:hover fieldset": {
        borderColor: "#EEE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#656DFC",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #AEAEAE",
      borderRadius: "10px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#656DFC",
      borderWidth: "1px !important",
    },
    "& input": {
      backgroundColor: "#fff",
      height: "35px",
      borderRadius: "10px",
      fontWeight: 500,
      "&::placeholder": {
        color: "#B4B1B6",
        fontWeight: 400,
        opacity: 1,
        fontSize: 16,
      },
    },

    "& .MuiFormHelperText-root": {
      marginLeft: 0,
    },
  },
  searchBtn: {
    width: 40,
    height: 40,

    "& svg > path": {
      fill: "#135099",
    },
  },
  filledSearchBtn: {
    bgcolor: "#135099",
    width: 40,
    height: 40,
    "&:hover": {
      bgcolor: "#135099",
    },
    "& svg > path": {
      fill: "#fff",
    },
  },

  filterBtn: {
    textTransform: "capitalize",
    bgcolor: "#135099",
    color: "#FFF",
    height: "50px",
    fontSize: 16,
    fontWeight: 600,
    minWidth: 150,
    px: 1,
    boxShadow: 0,
    borderRadius: "10px",
    "&:hover": {
      bgcolor: "#135099",
      color: "#FFF",
    },
  },
};

const CommunityDatabaseListing = () => {
  const [isSearch, setIsSearch] = useState(false);
  const [isHandleChangeSearch, setIsHandleChangeSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [searchedValue, setSearchedValue] = useState("");
  const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);

  const { enqueueSnackbar } = useSnackbar();

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setIsHandleChangeSearch(true);
    if (e.target.value.length < 1) {
      setIsHandleChangeSearch(false);
      setIsSearch(false);
      setSearchedValue("");
    }
  };

  const handleSearchClick = () => {
    setIsSearch(true);
    setSearchedValue(search);
  };

  const handleClearSearch = () => {
    setIsSearch(false);
    setSearch("");
    setSearchedValue("");
    setIsHandleChangeSearch(false);
  };

  const fetchCommunityDatabase = async (
    pageNo,
    limit,
    searchParam,
    isSearchParam
  ) => {
    let url;

    url = new URL(ADMIN_COMMUNITY_LISTING);
    url.searchParams.append("page", pageNo + 1);
    url.searchParams.append("limit", limit);
    if (isSearch) {
      url.searchParams.append("search", search);
    }

    return await get(url.toString());
  };

  const handleDownloadFile = async () => {
    const url = ADMIN_COMMUNITY_EXPORT;
    const token = loggedUser.token;

    try {
      const response = await axios.get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const blob = new Blob([response.data], { type: response.data.type });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Community Database.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      enqueueSnackbar("Excel file has been downloaded successfully", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("There was a problem with the axios operation:", {
        variant: "error",
      });
    }
  };

  const exportMutation = useMutation(handleDownloadFile);

  const handleClickExport = (e) => {
    e.preventDefault();
    exportMutation.mutate();
  };

  const columns = [
    {
      field: "name",
      headerName: "Member Name",
      sortable: false,
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <>
            <Avatar
              src={params?.row?.image}
              sx={{ mr: 1.5, width: 40, height: 40 }}
            >
              {params?.row?.first_name?.charAt(0)}
            </Avatar>
            {`${params?.row?.first_name} ${params?.row?.last_name}`}
          </>
        );
      },
    },

    {
      field: "email",
      headerName: "Email",
      sortable: false,
      flex: 1,
    },

    {
      field: "phoneNumber",
      headerName: "Phone Number",
      sortable: false,
      flex: 1,
      valueGetter: (params) => {
        return formatPhoneNumber(
          `${params?.row?.country_code}${params?.row?.phone}`
        );
      },
    },

    {
      field: "updated_at",
      headerName: "Updated At",
      sortable: false,
      flex: 1,
      valueGetter: (params) => {
        return moment(params?.row?.updated_at).format("D MMM YYYY, h:mm:ss A");
      },
    },
  ];

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        mb={{ md: 1, xs: 2 }}
      >
        <PageTitle title="Community Database" />
        <Stack direction="row" alignItems="center" gap={2}>
          <FormControl variant="outlined" sx={styles.searchInput} fullWidth>
            <OutlinedInput
              size="small"
              type="text"
              value={search}
              onChange={handleSearchChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearchClick();
                }
              }}
              endAdornment={
                <InputAdornment position="end">
                  {search && isSearch ? (
                    <Tooltip title="Clear Search" placement="top">
                      <IconButton onClick={handleClearSearch}>
                        <Clear />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Search" placement="top">
                      <IconButton
                        onClick={handleSearchClick}
                        sx={
                          isHandleChangeSearch
                            ? styles.filledSearchBtn
                            : styles.searchBtn
                        }
                      >
                        <Search />
                      </IconButton>
                    </Tooltip>
                  )}
                </InputAdornment>
              }
              placeholder="Search..."
            />
          </FormControl>
          <LoadingButton
            variant="contained"
            disableElevation
            sx={styles.filterBtn}
            onClick={handleClickExport}
            isLoading={exportMutation.isLoading}
          >
            Export
          </LoadingButton>
        </Stack>
      </Stack>

      <DataGridListing
        columns={columns}
        fetchRecords={fetchCommunityDatabase}
        queryName={queryNames.COMMUNITY_DATA_BASE}
        search={searchedValue}
        isSearch={isSearch}
      />
    </>
  );
};

export default CommunityDatabaseListing;
