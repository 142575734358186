import { Route } from "react-router-dom";
import Unauthorize from "../../pages/misc/Unauthorize";
import PageNotFound from "../../pages/misc/PageNotFound"
import routes from "../constants/routes";

const miscRoutes = (
  <>
    <Route path={routes.UNAUTHORIZE} element={<Unauthorize />} />
    <Route path="*" element={<PageNotFound />} />
  </>
);

export default miscRoutes;
