const styles = {
  stepper: {
    pt: 3,
    "& .MuiStep-root": {
      p: 0,
    },
    "& .MuiStepLabel-label": {
      color: "#000",
      fontWeight: 600,
    },
    "& .MuiStepConnector-root": {
      left: "calc(-50% + 15px)",
      right: " calc(50% + 15px)",
    },
    "& .MuiStepLabel-root": {
      flexDirection: "column",
    },
    "& .MuiStepConnector-line": {
      borderColor: "#ebebeb",
    },
    "& .MuiStepIcon-root": {
      width: 30,
      height: 30,
      color: "#F8F8F8",
      border: "1px solid #ebebeb",
      borderRadius: "50%",
    },
    "& .MuiStepLabel-iconContainer.Mui-active": {
      "& .MuiStepIcon-root": {
        color: "#135099",
        border: "none",
      },
    },
    "& .MuiStepIcon-text": {
      display: "none",
    },
    "& .MuiStepLabel-label.Mui-active": {
      color: "#135099",
    },
  },

  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100%",

    // width: "100%",
    // mt: 5,
    "& h1": {
      fontSize: 24,
      color: "#000",
      fontWeight: 500,
    },
  },

  cartItems: {
    bgcolor: "#F8F8F8",
    borderRadius: "10px",
    py: "10px",
    px: 2,

    border: "1px solid #E8EEF5",

    "& label": {
      color: "#000",
      fontSize: 17,
      fontWeight: 500,
    },
    // // "& h3": {
    // //   color: "#000",
    // //   fontSize: 20,
    // //   fontWeight: 500,
    // //   lineHeight: "20px",
    // // },
    // // "& h4": {
    // //   color: "#135099",
    // //   fontSize: 20,
    // //   fontWeight: 500,
    // //   lineHeight: "20px",
    // //   mr: 2,
    // // },
  },

  rightWrapper: {
    bgcolor: "#fff",
    p: 2.5,
    borderRadius: 2.5,
    "& h4": {
      fontSize: 20,
      color: "#1E1E1F",
      fontWeight: 600,
      pb: 2,
      mb: 2,
      borderBottom: "1px solid #D4D4D4",
    },
    "& h5": {
      color: "#4B4B4B",
    },
  },
  subtotal: {
    flexDirection: "row",
    justifyContent: "space-between",
    "& p": {
      fontSize: 14,
      color: "#4B4B4B",
    },
    "& h6": {
      color: "#1E1E1F",
      fontWeight: 500,
    },
  },
  total: {
    pt: 1.5,
    borderTop: "1px solid #D4D4D4",
  },
  coupon: {
    color: "#135099",
    fontSize: 14,
    pb: 1,
    fontWeight: 600,
  },
  card: {
    flexDirection: "row",
    columnGap: 2,
    py: 3,
    "& p": {
      fontSize: 14,
      fontWeight: 600,
    },
  },
  checkoutBtn: {
    bgcolor: "#fff",
    borderRadius: 2.5,
    textTransform: "Capitalize",
    height: 50,
    mt: 2,
    color: "#135099",
    border: "1px solid #135099",
    "&:hover": {
      bgcolor: "#fff",
    },
  },

  cardName: {
    "& span": {
      color: "#000",
      fontSize: 17,
      fontWeight: 500,
    },
  },

  qtyBtnContainer: {
    bgcolor: "#FFF",
    border: "1px solid #E8EEF5",
    borderRadius: "10px",
    minWidth: 150,

    "& label": {
      color: "#000",
      fontSize: 16,
      fontWeight: 500,
      width: 70,
      textAlign: "center",
    },
  },
  qtyBtn: {
    borderRadius: "10px",
    minWidth: 20,
    minHeight: 40,
    border: 0,
  },
  increaseBtn: {
    bgcolor: "#135099",
    "&:hover": {
      bgcolor: "#135099",
    },
  },
  decreaseBtn: {
    bgcolor: "#E8EEF5",
    "&:hover": {
      bgcolor: "#E8EEF5",
    },
  },

  team: {
    textTransform: "Capitalize",
    fontSize: 20,
    fontWeight: 500,
    mb: 1,
  },

  basicImageWrapper: {
    bgcolor: "#fff",
    boxShadow: "0px 3.546391487121582px 3.546391487121582px 0px #0000000F",
    height: 200,
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 300,
    maxWidth: 300,
    cursor: "pointer",
    p: 1,
  },
  forMeWrapper: {
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "1.5px solid #135099",
    borderRadius: "6px",
  },
  activeForWrapper: {
    bgcolor: "#135099",
    width: "100%",
    color: "#fff",
    textAlign: "center",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    fontWeight: 500,
  },
  nonActiveForWrapper: {
    bgcolor: "trasnpanet",
    width: "100%",
    textAlign: "center",
    color: "#135099",
    fontSize: "16px",
    fontWeight: 500,
  },

  generalHeading: {
    color: "#1E1E1F",
    fontWeight: 500,
  },

  colorLegend: {
    color: "#000",
    fontSize: 18,
    fontWeight: 500,
    lineHeight: "36px",
  },
  color: {
    width: 40,
    height: 40,
    borderRadius: "50%",
    cursor: "pointer",
    position: "relative",
  },
  selectedColor: {
    width: 30,
    height: 30,
    borderRadius: "50%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    boxShadow: "0 0 0 2px white, 0 0 0 4px white",
  },

  avatar: {
    height: 100,
    width: "100%",
    borderRadius: "10px",
    bgcolor: "#fff",
    border: "1.5px solid #EEEEEE",
    p: 1,
    "& img": {
      objectFit: "contain",
      width: "100%",
    },
    mb: 2,
    // position: "absolute",
    // left: { lg: 50, md: 40, sm: 40 },
    // top: 160,
    cursor: "pointer",
    "& svg": {
      height: 75,
      width: 75,
      fill: "#135099",
    },
    // "@media (max-width: 768px)": {
    //   left: 20,
    //   top: 140,
    // },
  },
  divider: {
    borderTop: "1px solid #D4D4D4",
    pt: 1,
  },
  radioBtn: {
    "& .MuiFormControlLabel-label": {
      color: "#000000",
      fontSize: 20,
      fontWeight: 600,
    },
  },
  price: {
    fontSize: 16,
    fontWeight: 600,
    color: "#135099 !important",
  },
};
export default styles;
