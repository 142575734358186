import { useState } from "react";
import PageTitle from "../../../components/common/PageTitle";
import { useSnackbar } from "notistack";
import { validator } from "../../../utils/helpers/validator";
import SendEmailSchema from "../../../utils/schemas/SendEmailSchema";
import { get, post } from "../../../server";
import {
  ADMIN_COMMUNITY_LISTING,
  EMAIL_SEND,
} from "../../../data/constants/apiRoutes";
import { useMutation, useQuery } from "react-query";
import { Autocomplete, Box, Grid, MenuItem } from "@mui/material";
import FormInput from "../../../components/forms/FormInput";
import LoadingButton from "../../../components/button/LoadingButton";
import SelectInput from "../../../components/forms/SelectInput";
import queryNames from "../../../data/constants/queryNames";
import _ from "lodash";
const styles = {
  wrapper: {
    bgcolor: "#fff",
    borderRadius: "20px",
    p: 3,
    mt: 2,
  },
  submitBtn: {
    bgcolor: "#135099",
    borderRadius: 2.5,
    mt: 2,
    minWidth: 130,
    height: 45,
  },
  customStyles: {
    ".MuiAutocomplete-paper": {
      maxHeight: "60px",
      overflowY: "auto",
    },
  },
};

const customersValue = [
  { id: "all", name: "All community database members" },

  {
    id: "specific",
    name: "Specific community database members",
  },
];

const initialState = {
  subject: "",
  body: "",
  type: "",
};

const SendEmail = () => {
  const [sendEmail, setSendEmail] = useState(initialState);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [errors, setErrors] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === "subject") {
      if (value.length > 500) {
        value = value.slice(0, 500);
      }
    } else if (name === "body") {
      if (value.length > 5000) {
        value = value.slice(0, 5000);
      }
    }

    if (name === "type" && value === "all") {
      setSelectedUsers([]);
    }

    setSendEmail({ ...sendEmail, [name]: value });
  };

  const handleSelectedUsersChange = (event, newValue) => {
    setSelectedUsers(newValue);
  };

  const fetchCommunityDatabase = async () => {
    const { status, message, data } = await get(ADMIN_COMMUNITY_LISTING);

    if (status) {
      return data.data;
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const communityCustomerQuery = useQuery(
    [queryNames.ALL_COMMUNITY_DATA_BASE],
    fetchCommunityDatabase
  );

  const validateForm = () => {
    let newErrors = validator(sendEmail, SendEmailSchema);
    if (sendEmail.type === "specific" && selectedUsers.length < 1) {
      newErrors = {
        ...newErrors,
        selectedUsers: "Please select at least 1 community database member",
      };
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };

  const handleSendNotification = async () => {
    let payload = _.cloneDeep(sendEmail);
    if (payload.type === "specific" && selectedUsers.length > 0) {
      payload.email = selectedUsers.map((item) => item.email);
    }

    const { status, message, data } = await post(EMAIL_SEND, payload);
    if (status) {
      enqueueSnackbar(message, { variant: "success" });
      setSendEmail(initialState);
      setSelectedUsers([]);
    } else enqueueSnackbar(message || data, { variant: "error" });
  };

  const mutation = useMutation(handleSendNotification);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    mutation.mutate();
  };

  return (
    <>
      <PageTitle title="Send Email" />
      <Box component="form" noValidate autoComplete="off">
        <Box sx={styles.wrapper}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormInput
                withasterisk={true}
                label="Subject"
                placeholder="Subject"
                name="subject"
                value={sendEmail.subject}
                onChange={handleChange}
                error={errors?.subject}
                helperText={errors?.subject || ""}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectInput
                withasterisk={true}
                label="Community Database"
                height={58}
                isWidthySelect={true}
                value={sendEmail.type || ""}
                onChange={handleChange}
                name="type"
                error={errors?.type ? true : false}
                errorMessage={errors?.type || ""}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <span>Select community database members</span>;
                  }

                  const found = customersValue.find(
                    (single) => single.id === selected
                  );

                  return found?.name;
                }}
                displayEmpty
              >
                {customersValue.map((statusValue, index) => {
                  return (
                    <MenuItem value={statusValue.id} key={index}>
                      {statusValue.name}
                    </MenuItem>
                  );
                })}
              </SelectInput>
            </Grid>
            <Grid item xs={12} md={12}>
              {communityCustomerQuery?.data?.length > 0 &&
                sendEmail.type &&
                sendEmail.type != "all" && (
                  <Autocomplete
                    multiple
                    disablePortal
                    id="combo-box-demo"
                    options={communityCustomerQuery?.data || []}
                    getOptionLabel={(option) =>
                      `${option?.first_name} ${option?.last_name} (${option?.email})` ||
                      ""
                    }
                    value={selectedUsers}
                    onChange={handleSelectedUsersChange}
                    renderInput={(params) => (
                      <FormInput
                        {...params}
                        label="Community Database Members"
                        withasterisk
                        placeholder="Select members"
                        error={errors?.selectedUsers}
                        helperText={errors?.selectedUsers || ""}
                      />
                    )}
                  />
                )}
            </Grid>
            <Grid item sx={12} md={12}>
              <FormInput
                withasterisk={true}
                label="Body"
                placeholder="Body"
                name="body"
                value={sendEmail.body}
                onChange={handleChange}
                error={errors?.body}
                helperText={errors?.body || ""}
                multiline
                rows={2}
              />
            </Grid>
          </Grid>
        </Box>
        <LoadingButton
          variant="contained"
          disableElevation
          sx={styles.submitBtn}
          type="submit"
          onClick={handleSubmit}
          isLoading={mutation.isLoading}
        >
          Send
        </LoadingButton>
      </Box>
    </>
  );
};

export default SendEmail;
