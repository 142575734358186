import Modal from "@mui/material/Modal";
import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Box } from "@mui/system";

const styles = {
	container: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		maxWidth: 600,
		// minWidth: 300,
		maxHeight: 600,

		bgcolor: "#fff",
		borderRadius: 2,
		boxShadow: 24,
		p: 2,
		"& button": {
			width: 120,
			boxShadow: "none",
			fontWeight: 600,
		},
	},
	closeIconBtnWrapper: {
		textAlign: "end",
	},
	closeIconBtn: {
		textAlign: "end",
		width: "40px !important",
		height: 40,
	},
	image: {
		borderRadius: "10px",
		objectFit: "cover",
		maxWidth: 500,
	},
};

const ImagePreviewModal = ({ modal, handleClose, media }) => {
	return (
		<Modal
			open={modal}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={styles.container}>
				<Box sx={styles.closeIconBtnWrapper} id="modal-modal-title">
					<IconButton onClick={handleClose} sx={styles.closeIconBtn}>
						<Close />
					</IconButton>
				</Box>

				{modal === "image" ? (
					<Box
						component="img"
						src={media}
						alt="image"
						id="modal-modal-description"
						sx={styles.image}
					/>
				) : (
					modal === "video" && (
						<Box
							component="video"
							controls
							width="100%"
							height="100%"
							sx={styles.image}
						>
							<Box component="source" src={media} type="video/mp4" />
							Your browser does not support the video tag.
						</Box>
					)
				)}
			</Box>
		</Modal>
	);
};

export default ImagePreviewModal;
