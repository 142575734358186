import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const loggedUserAtom = atomWithStorage("loggedUser", {});
export const appPackagesAtom = atomWithStorage("packages", []);
export const featureModal = atomWithStorage("globalFeatureModal", false);
export const mobileViewAtom = atom(false);
export const cartAtom = atomWithStorage("cart", []);

export const shareCardVCFDataAtom = atomWithStorage("shareCardVCFData", {});
