import { Box } from "@mui/material";

const TikTokPlayer = ({ videoId }) => {
	return (
		<Box sx={{ height: "700px !important" }}>
			<iframe
				src={`https://www.tiktok.com/embed/v2/${videoId}?lang=en-US`}
				allowFullScreen
				title="TikTok Video Player"
				height={700}
			/>
		</Box>
	);
};

export default TikTokPlayer;
