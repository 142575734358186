import { Box, Typography } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import routes from "../../data/constants/routes";
import { SUBSCRIPTION_KEY } from "../../data/constants/feature";
import useFeature from "../../hooks/useFeature";

const styles = {
  cardWrapper: {
    border: "2px dashed #E6E6E9",
    borderRadius: 4,
    background: "#fff",
    width: 230,
    height: 320,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    position: "relative",
    zIndex: 2,
    "& p": {
      color: "#1E1E1F",
      fontSize: 16,
      fontWeight: 500,
    },
  },
  addIconWrapper: {
    background: "#F9FAFC",
    width: 50,
    height: 50,
    borderRadius: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      width: 25,
    },
  },
  addIcon: { color: "#135099", fontSize: 40 },
};
const AddBusinessCard = () => {
  const navigate = useNavigate();
  const { compareLimits} = useFeature();
  const handleCardLimit = (path,type) => {
    let res = compareLimits(type);
		if(res) {
			navigate(path)
		} else {
			return false;
		}
  }

  return (
    <Box
      sx={styles.cardWrapper}
      onClick={() => handleCardLimit(routes.CUSTOMER_CREATE_CARD,SUBSCRIPTION_KEY.card)}
    >
      <Box sx={styles.addIconWrapper}>
        <AddIcon sx={styles.addIcon} />
      </Box>
      <Typography component="p" mt={2}>
        Add Card
      </Typography>
    </Box>
  );
};

export default AddBusinessCard;
