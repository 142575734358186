import { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  IconButton,
  Divider,
  Tooltip,
} from "@mui/material";
import styles from "../../assets/styles/modal/qrCodeModal.styles";
import { Close } from "@mui/icons-material";
import QrCodeBorder from "../../assets/svg/QrCodeBorder.svg";
import { QRCode } from "react-qrcode-logo";
import ShareIcon from "@mui/icons-material/Share";
import html2canvas from "html2canvas";
import { useNavigate } from "react-router";
import { toPng } from "html-to-image";
import generateUniqueFileName from "../../utils/helpers/generateUniqueFileName";
import AWS from "aws-sdk";
import { useSnackbar } from "notistack";

const QrCodeModel = ({
  open,
  handleClose,
  uuid,
  personName,
  qrCodeUrl,
  shareURL,
}) => {
  const [openToolTip, setOpenToolTip] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleDownload = async (e) => {
    e.preventDefault();

    AWS.config.update({
      accessKeyId: "AKIATMHJNLYBZ74HZTPA",
      secretAccessKey: "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",
      region: "us-east-1",
    });

    const s3 = new AWS.S3();

    const bucketName = "knocknoccards";

    const key = `qrcodes/${uuid}.png`;
    try {
      const response = await s3
        .getObject({ Bucket: bucketName, Key: key })
        .promise();
      const imageBlob = new Blob([response.Body], {
        type: response.ContentType,
      });
      const imageUrl = URL.createObjectURL(imageBlob);
      const link = document.createElement("a");
      link.href = imageUrl;
      link.download = "Qr Code";
      link.click();
    } catch (error) {
      console.error("Error downloading image from S3:", error);
    }
  };

  const handleOpenToolTip = () => {
    // const encodedPersonName = encodeURIComponent(personName);
    // navigator.clipboard.writeText(
    //   `${
    //     import.meta.env.VITE_SHARE_URL
    //   }/card/${uuid}?personName=${encodedPersonName}`
    // );
    if (shareURL) {
      navigator.clipboard.writeText(shareURL);

      setOpenToolTip(true);
    } else {
      navigator.clipboard.writeText(
        "We can't find the Share card URL. Please attempt to re-save the card or notify the owner for corrections."
      );

      enqueueSnackbar(
        "We can't find the Share card URL. Please attempt to re-save the card or notify the owner for corrections.",
        { variant: "error" }
      );
    }
  };

  const handleCloseToolTip = () => {
    setOpenToolTip(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.container}>
          {qrCodeUrl ? (
            <>
              <Box sx={styles.closeIconBtnWrapper}>
                <IconButton onClick={handleClose} sx={styles.closeIconBtn}>
                  <Close />
                </IconButton>
              </Box>
              <Box sx={styles.qrWrapper}>
                <Box component="img" src={QrCodeBorder} sx={styles.qrBorder} />
                <Box sx={styles.qrCode}>
                  <Box component="img" src={qrCodeUrl} alt="Qr Code" />
                  {/* <QRCode
                value={`${
                  import.meta.env.VITE_SHARE_URL
                }/card/${uuid}?personName=${personName}`}
                id={"qr-code"}
              /> */}
                </Box>
              </Box>
              <Typography sx={styles.title}>Qr Code</Typography>
              <Typography sx={styles.subTitle}>
                Share or Download <br /> Your QR Code
              </Typography>
              <Box sx={styles.shareBtnWrapper}>
                <Tooltip
                  open={openToolTip}
                  onClose={handleCloseToolTip}
                  onClick={handleOpenToolTip}
                  title="Link Copied"
                  placement="top"
                >
                  <Button
                    disableElevation
                    variant="outlined"
                    startIcon={<ShareIcon />}
                  >
                    Share
                  </Button>
                </Tooltip>
              </Box>
              <Divider />
              <Box sx={styles.btnsWrapper}>
                <Button
                  variant="contained"
                  sx={styles.downloadBtn}
                  type="button"
                  onClick={(e) => handleDownload(e)}
                >
                  Download
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Box sx={styles.closeIconBtnWrapper}>
                <IconButton onClick={handleClose} sx={styles.closeIconBtn}>
                  <Close />
                </IconButton>
              </Box>
              <Box sx={styles.noDataFoundWrapper}>
                <Typography component="h1">No QR Code Found</Typography>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default QrCodeModel;
