import React from "react";
import Overlay from "../../../components/overlay/Overlay";
import { Box } from "@mui/material";

const Reports = () => {
  return (
    <Box sx={{ position: "relative", p: 2.5 }}>
      <Overlay />
      <div>Reports</div>
    </Box>
  );
};

export default Reports;
