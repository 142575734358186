const styles = {
  banner: {
    mt: "17px",
    position: "relative",
    height: 250,
    cursor: "pointer",
  },
  bannerImg: {
    height: "100%",
    width: "100%",
    borderRadius: "20px",
    objectFit: "contain",
    backgroundColor: "#eee",
  },
  uploadStack: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    alignItems: "center",
    cursor: "pointer",
    fontSize: { lg: 20, md: 18, sm: 15 },
    "& svg": {
      height: { lg: 64, md: 50, sm: 35 },
    },
    "& label": {
      color: "#FFF",
      fontWeight: 400,
      lineHeight: "24px",
      textTransform: "capitalize",
      whiteSpace: "nowrap",
    },
  },
  avatar: {
    height: { md: 160, sm: 120, xs: 100 },
    width: { md: 160, sm: 120, xs: 100 },
    border: { md: "5px solid #FFF", xs: "2px solid #fff" },
    bgcolor: "#E8EEF5",
    mb: 2,
    // position: "absolute",
    // left: { lg: 50, md: 40, sm: 40 },
    // top: 160,
    cursor: "pointer",
    "& svg": {
      height: 75,
      width: 75,
      fill: "#135099",
    },
    // "@media (max-width: 768px)": {
    //   left: 20,
    //   top: 140,
    // },
  },

  container: {
    position: "relative",
    // mt: { md: 12, xs: 11 },
    ml: 0,
    bgcolor: "#FFF",
    borderRadius: "10px",
    p: { md: "30px", xs: "20px 15px" },

    width: "100% !important",
    "& 	.MuiGrid-item": {
      width: "100%",
    },
  },
  button: {
    mt: { md: "30px", xs: "25px" },
    borderRadius: "10px",
    bgcolor: "#135099",
    color: "#FFF",
    width: { md: 200, xs: "100%" },
    fontSize: 16,
    height: "50px",
    fontWeight: 500,
    textTransform: "capitalize",
  },

  changeEmailBtn: {
    textTransform: "capitalize",
    color: "#135099",
  },
};

export default styles;
