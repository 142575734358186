import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Box, Collapse } from "@mui/material";
import { Link } from "react-router-dom";

import { Divider, Drawer, Toolbar } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ReactComponent as Logout } from "../assets/svg/sidebarIcons/Logout.svg";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import { customerSidebarRoutes } from "../data/constants/customerSidebarRoutes";

// import Logo from "../assets/svg/Logo.svg";
import Logo from "../assets/images/LOGO.png";
import { adminSiderbarRoutes } from "../data/constants/adminSiderbarRoutes";
import { useAtom } from "jotai";
import { cartAtom, loggedUserAtom, mobileViewAtom } from "../data/store";
import routes from "../data/constants/routes";

const styles = {
  toolbar: {
    "&.MuiToolbar-root": {
      minHeight: "100px",
    },
  },
  logo: {
    cursor: "pointer",
  },
  "&.MuiListItemIcon-root": {
    minWidth: 40,
  },
  "& svg": {
    width: 22,
    height: 22,
  },

  listItem: {
    mx: 2.5,
    mb: 0.5,
    pl: "26px",
    py: "10px",
    borderRadius: "10px",
    "& span": {
      fontWeight: 600,
    },
    "& svg": {
      fill: "#767D90",
      "& path": {
        fontWeight: 600,
      },
    },
    "&:hover": {
      bgcolor: "#D3A23B",
      color: "#FFFFFF",
      "& svg": {
        "& path": {
          fill: "#FFFFFF",
          fontWeight: 600,
        },
      },
    },
  },

  activeList: {
    bgcolor: "#D3A23B",
    borderRadius: "10px",
    mx: 2.5,
    mb: 0.5,
    pl: "26px",
    py: "10px",
    "& .MuiListItemIcon-root": {
      minWidth: 40,
    },
    "& span": {
      color: "#FFFFFF",
      fontWeight: 600,
    },
    "& svg": {
      "& path": {
        fill: "#FFFFFF",
        fontWeight: 600,
      },
    },
    "&:hover": {
      bgcolor: "#D3A23B",
    },
  },

  divider: {
    borderBottomWidth: "0.5px",
  },
  firstDivider: {
    mb: 1,
  },

  logOutText: {
    color: "#FF5757",
    fontWeight: 600,
  },
  logoutListItem: {
    py: "24px",
    pl: "44px",
    "& span": {
      // color: "#1E1E1F",
      letterSpacing: 0,
      fontSize: 16,
    },
  },
};

export default function Sidebar(props) {
  const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);
  const [cart, setCart] = useAtom(cartAtom);
  const [mobileView, setMobileView] = useAtom(mobileViewAtom);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const sidebarRoutes = pathname.includes("admin")
    ? adminSiderbarRoutes
    : customerSidebarRoutes;

  const handleClick = () => {
    setOpen(!open);
  };

  const { window, drawerWidth, mobileOpen, setMobileOpen } = props;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = async () => {
    const user = loggedUser?.data;
    setLoggedUser({});
    setCart([]);
    if (user?.is_superadmin === "1") navigate(routes.ADMIN_LOGIN);
    else navigate(routes.CUSTOMER_LOGIN);
  };

  // const CustomListItem = ({ route }) => {
  // 	return (
  // 		<ListItem disablePadding>
  // 			<ListItemButton
  // 				sx={pathname === route.path ? styles.activeList : styles.listItem}
  // 				onClick={() => navigate(route.path)}
  // 			>
  // 				<ListItemIcon sx={styles}>{route.icon}</ListItemIcon>
  // 				<ListItemText primary={route.label} />
  // 			</ListItemButton>
  // 		</ListItem>
  // 	);
  // };

  const CustomListItem = ({ route }) => {
    {
      return route.subCategories && route.subCategories.length > 0 ? (
        <>
          <ListItemButton onClick={handleClick} sx={styles.listItem}>
            <ListItemIcon>{route.icon}</ListItemIcon>
            <ListItemText primary={route.label} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse in={open} timeout="auto" unmountOnExit>
            {route.subCategories.map((category, index) => (
              <CustomListItem
                route={category}
                key={`${category.label}=${index}`}
              />
            ))}
          </Collapse>
        </>
      ) : (
        <ListItem disablePadding>
          <ListItemButton
            sx={
              pathname.includes(route.path)
                ? styles.activeList
                : styles.listItem
            }
            onClick={() => {
              navigate(route.path);
              handleDrawerToggle();
            }}
          >
            <ListItemIcon sx={styles}>{route.icon}</ListItemIcon>
            <ListItemText primary={route.label} />
          </ListItemButton>
        </ListItem>
      );
    }

    // return (
    //   <ListItem disablePadding>
    //     <ListItemButton
    //       sx={pathname === route.path ? styles.activeList : styles.listItem}
    //       onClick={() => navigate(route.path)}
    //     >
    //       <ListItemIcon sx={styles}>{route.icon}</ListItemIcon>
    //       <ListItemText primary={route.label} />
    //     </ListItemButton>
    //   </ListItem>
    // );
  };

  const drawer = (
    <>
      <Toolbar sx={styles.toolbar}>
        <Box
          component="img"
          src={Logo}
          alt="Logo"
          width="100%"
          sx={styles.logo}
          onClick={() =>
            navigate(
              loggedUser?.data?.is_superadmin === "1"
                ? routes.ADMIN_CUSTOMERS
                : routes.CUSTOMER_BUSINESS_CARD
            )
          }
        />
        {/* <Link to="/profile">
					<Box component="img" src={Logo} alt="Logo" width="100%" />
				</Link> */}
      </Toolbar>

      <Divider sx={styles.firstDivider} />

      <List disablePadding>
        {sidebarRoutes.map((route, index) => (
          <CustomListItem route={route} key={`${route.label}=${index}`} />
        ))}
      </List>
      <Box sx={{ flexGrow: 0, mt: "auto" }}>
        <Divider sx={styles.divider} />
        <ListItemButton
          sx={[styles.logoutListItem, { height: 78, pl: "46px !important" }]}
          onClick={handleLogout}
        >
          <ListItemIcon sx={styles}>
            <Logout />
          </ListItemIcon>
          <ListItemText sx={styles.logOutText}>Logout</ListItemText>
        </ListItemButton>
      </Box>
    </>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {mobileView ? (
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: false,
            }}
            sx={{
              // display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        ) : (
          <Drawer
            variant="permanent"
            sx={{
              // display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        )}
      </Box>
    </Box>
  );
}
