import { ReactComponent as Youtube } from "../../../assets/svg/businesscard/Youtube.svg";
import { ReactComponent as Vimeo } from "../../../assets/svg/businesscard/Vimeo.svg";
import { ReactComponent as TikTokThumb } from "../../../assets/svg/businesscard/Tiktok.svg";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { Box } from "@mui/system";
import VideoModal from "../../videoPlayers/VideoModal";

const styles = {
	youtube: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%,-50%)",
		width: 60,
		// height: 40,
		cursor: "pointer",
	},
	vimeo: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%,-50%)",
		width: 100,
		height: 40,
		cursor: "pointer",
	},
	youtubeThumbnail: {
		position: "relative",
		width: "100%",
		borderRadius: "20px",
		mt: 1.5,
		filter: "brightness(50%)",
		cursor: "pointer",
	},
	tiktokThumbnail: {
		bgcolor: "gray",
		width: "100%",
		borderRadius: "20px",
		mt: 1.5,
		filter: "brightness(80%)",
		cursor: "pointer",
		height: 155,
	},
	videoModal: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		// width: 350,
		bgcolor: "#fff",
		borderRadius: 3,
		p: 3,
		"& h2": {
			textAlign: "center",
			fontSize: 20,
			fontWeight: 600,
			pb: 2,
		},
		"& a": {
			textDecoration: "none",
			color: "#000",
		},
	},
};

const VideoTemplate = ({ youtube, vimeo, tiktok }) => {
	const [videoModal, setVideoModal] = useState(false);
	const [vimeoThumbnailImage, setVimeoThumbnail] = useState(null);
	const [imagesLoaded, setImagesLoaded] = useState({
		logoImage: false,
		youtube: false,
	});

	function extractYouTubeVideoId(url) {
		const youtubeRegex =
			/(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

		const match = url.match(youtubeRegex);

		// Check if there is a match and return the video ID, or null if no match
		return match ? match[1] : null;
	}

	const youtubeThumbnail = () => {
		const videoId = extractYouTubeVideoId(youtube);
		if (videoId) {
			return `https://img.youtube.com/vi/${videoId}/0.jpg`;
		}
	};

	const isValidYouTubeLink = () => {
		const youtubeRegex =
			/^(https?:\/\/)?(www\.)?(youtube\.com\/(embed\/|v\/|watch\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

		return youtubeRegex.test(youtube);
	};

	function extractVimeoVideoId(url) {
		const vimeoRegex = /(?:vimeo\.com\/|player\.vimeo\.com\/video\/)(\d+)/;

		const match = url.match(vimeoRegex);

		// Check if there is a match and return the video ID, or null if no match
		return match ? match[1] : null;
	}

	const vimeoThumbnail = async () => {
		const videoId = extractVimeoVideoId(vimeo);
		if (videoId) {
			await axios
				.get(`https://api.vimeo.com/videos/${videoId}/pictures`, {
					headers: {
						Authorization: "Bearer 0de8ca6ece6d912bded441ded7e5d491",
					},
				})
				.then((response) => {
					setVimeoThumbnail(response.data.data[0].base_link);
				})
				.catch(() => setVimeoThumbnail());
		}
	};

	const isValidVimeoLink = () => {
		const vimeoRegex = /(?:vimeo\.com\/|player\.vimeo\.com\/video\/)(\d+)/;

		return vimeoRegex.test(vimeo);
	};

	function extractTikTokVideoId() {
		const tiktokRegex = /\/video\/(\d+)/;
		const match = tiktok.match(tiktokRegex);

		// Check if there is a match and return the video ID, or null if no match
		return match ? match[1] : null;
	}

	useEffect(() => {
		if (vimeo?.length > 0) {
			if (isValidVimeoLink()) vimeoThumbnail();
		}
	}, [vimeo]);

	return (
		<>
			{youtube?.length > 0 && (
				<Box position="relative" onClick={() => setVideoModal("youtube")}>
					<Box
						src={youtubeThumbnail()}
						component="img"
						sx={styles.youtubeThumbnail}
						onLoad={() => {
							setImagesLoaded({ ...imagesLoaded, vimeo: true });
						}}
					/>
					{isValidYouTubeLink() && imagesLoaded.vimeo && (
						<Youtube style={styles.youtube} />
					)}
				</Box>
			)}

			{vimeo?.length > 0 && (
				<Box position="relative" onClick={() => setVideoModal("vimeo")}>
					<Box
						src={vimeoThumbnailImage}
						component="img"
						sx={styles.youtubeThumbnail}
						onLoad={() => {
							setImagesLoaded({ ...imagesLoaded, vimeo: true });
						}}
					/>
					{vimeoThumbnailImage?.length > 0 && imagesLoaded.vimeo && (
						<Vimeo style={styles.vimeo} />
					)}
				</Box>
			)}

			{tiktok?.length > 0 && (
				<Box position="relative" onClick={() => setVideoModal("tiktok")}>
					<Box sx={styles.tiktokThumbnail} />
					<TikTokThumb style={styles.youtube} />
				</Box>
			)}

			<VideoModal
				modal={videoModal}
				setModal={setVideoModal}
				videoId={
					videoModal === "youtube"
						? extractYouTubeVideoId(youtube)
						: videoModal === "vimeo"
						? extractVimeoVideoId(vimeo)
						: videoModal === "tiktok"
						? extractTikTokVideoId()
						: null
				}
			/>
		</>
	);
};

export default VideoTemplate;
