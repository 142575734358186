import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useAtom } from "jotai";
import { cartAtom } from "../../../../data/store";
import { Clear } from "@mui/icons-material";
import { useState } from "react";
import FormInput from "../../../../components/forms/FormInput";

const styles = {
  headers: {
    px: 2,
    mb: "18px",
    "& label": {
      color: "#1E1E1F",
      fontSize: 16,
      fontWeight: 500,
    },
  },
  cartItems: {
    bgcolor: "#fff",
    borderRadius: "10px",
    py: "10px",
    px: 2,
    border: "1px solid #E8EEF5",
    "& img": {
      width: 80,
      height: 80,
      borderRadius: "10px",
    },
    "& label": {
      color: "#767D90",
      fontSize: 18,
    },
    "& h3": {
      color: "#000",
      fontSize: 20,
      fontWeight: 500,
      lineHeight: "20px",
    },
    "& h4": {
      color: "#135099",
      fontSize: 20,
      fontWeight: 500,
      lineHeight: "20px",
      mr: 2,
    },
  },
  select: {
    width: 120,
    height: 40,
    pr: 1.25,
    pl: 2.25,
    borderRadius: "10px",
    bgcolor: "#FFF",
    "& fieldset": {
      border: "1px solid #E8EEF5",
      borderColor: "#E8EEF5",
    },
    "&:hover fieldset": {
      borderColor: "#656DFC",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#656DFC",
    },
    "& .MuiOutlinedInput-input": {
      py: 1.25,
      px: 2,
    },
  },
  rightContainer: {
    bgcolor: "#fff",
    borderRadius: "10px",
    px: "26px",
    py: "21px",
    "& h3": {
      color: "#000",
      fontSize: 20,
      fontWeight: 600,
    },
    "& h4": {
      color: "#1E1E1F",
      fontSize: 16,
      fontWeight: 500,
    },
    "& label": {
      color: "#4B4B4B",
      fontSize: 14,
    },
    "& span": {
      color: "#135099",
      fontSize: 14,
      fontWeight: 500,
      cursor: "pointer",
    },
    "& p": {
      color: "#4B4B4B",
      fontSize: 16,
    },
  },
  divider: {
    color: "#EEE",
    my: 2,
  },
  submitBtn: {
    width: "100%",
    bgcolor: "#135099",
    color: "#fff",
    mt: 2,
    textTransform: "capitalize",
    "&:hover": {
      bgcolor: "#135099",
    },
  },
  couponBtn: {
    bgcolor: "#135099",
    color: "#fff",
    borderRadius: "10px",
    height: 38,
    width: 120,
    textTransform: "capitalize",
    "&:hover": {
      bgcolor: "#135099",
    },
  },
  input: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "transparent",
      minHeight: 42,
      borderRadius: 0,
      p: 0,
      "& fieldset": {
        border: "1px solid #EEE",
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
          {
            borderColor: "#EEE",
          },
      },
      "&:hover fieldset": {
        borderColor: "#EEE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#EEE",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #EEE",
      borderRadius: "10px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#EEE",
      borderWidth: "1px !important",
    },
    "& input": {
      height: "30px",
      // background: "rgba(226, 227, 228, 0.2)",
      // border: "1px solid #E2E3E4",
      borderRadius: "10px",
      padding: "6px 20px",
      "&::placeholder": {
        color: "#B4B1B6",
        fontWeight: 400,
        opacity: 1,
        fontSize: 15,
      },
    },
  },
};

const AddToCartStep = ({ currentStep, setCurrentStep }) => {
  const [cart, setCart] = useAtom(cartAtom);
  const [isCoupon, setIsCoupon] = useState(false);
  const [coupon, setCoupon] = useState("");

  const subtotal = cart.reduce((acc, item) => acc + item.price, 0);

  const handleCheckout = () => {
    setCurrentStep(currentStep + 1);
  };

  return (
    <>
      <Box mt={5}>
        <Grid container spacing={2}>
          <Grid item md={8} sm={12}>
            <Stack direction="row" sx={styles.headers}>
              <Typography component="label" mr="50px">
                Image
              </Typography>
              <Typography component="label" flex={1}>
                Card Details
              </Typography>
              <Typography component="label" width={150} mr="10px">
                Quantity
              </Typography>
              <Typography component="label" mr="30px">
                Total Price
              </Typography>
            </Stack>

            {cart.map((item, index) => (
              <Stack
                direction="row"
                sx={styles.cartItems}
                key={index}
                alignItems="center"
                spacing="20px"
              >
                <Box component="img" src={item.image} alt="cart item" />
                <Stack spacing={1} flex={1}>
                  <Typography component="label">{item?.category}</Typography>
                  <Typography component="h3">{item.name}</Typography>
                </Stack>

                <Box width={150}>
                  <Select
                    sx={styles.select}
                    value={item.quantity}
                    // onChange={(e) => setQuantity(e.target.value)}
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                  </Select>
                </Box>

                <Typography component="h4">${item.price}</Typography>

                <Clear sx={{ width: 30, height: 30 }} />
              </Stack>
            ))}
          </Grid>

          <Grid item md={4} sm={12}>
            <Box sx={styles.rightContainer}>
              <Typography component="h3">Order Summary</Typography>

              <Divider sx={styles.divider} />

              <Stack direction="row" justifyContent="space-between">
                <Typography component="label">Sub Total:</Typography>
                <Typography component="h4">${subtotal.toFixed(2)}</Typography>
              </Stack>

              <Stack direction="row" justifyContent="space-between" mt={3}>
                <Typography component="label">Shipping:</Typography>
                <Typography component="h4">Via DHL</Typography>
              </Stack>

              <Divider sx={styles.divider} />

              <Stack direction="row" justifyContent="space-between">
                <Typography component="label">Total:</Typography>
                <Typography component="h4">${subtotal.toFixed(2)}</Typography>
              </Stack>

              <Divider sx={styles.divider} />

              {!isCoupon ? (
                <Typography component="span" onClick={() => setIsCoupon(true)}>
                  Have a coupon?
                </Typography>
              ) : (
                <TextField
                  placeholder="Coupon code"
                  value={coupon}
                  onChange={(e) => setCoupon(e.target.value)}
                  sx={styles.input}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button variant="contained" sx={styles.couponBtn}>
                          Apply
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              )}

              <Typography component="p">
                Shipping, taxes, and discounts added at checkout.
              </Typography>

              <Button
                variant="contained"
                sx={styles.submitBtn}
                onClick={handleCheckout}
              >
                Proceed to Checkout
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AddToCartStep;
