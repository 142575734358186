import { useState } from "react";
import PageTitle from "../../../components/common/PageTitle";
import { Box, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { get } from "../../../server";
import { PAGES } from "../../../data/constants/apiRoutes";
import Loader from "../../../components/Loader";
import { useQuery } from "react-query";
import queryNames from "../../../data/constants/queryNames";

const styles = {
  mainWrapper: {
    backgroundColor: "#F9FAFC",
    p: 4,
    minHeight: "100vh",
  },
  wrapper: {
    bgcolor: "#fff",
    borderRadius: "20px",
    p: 3,
    mt: 2,
  },
};

const PublicTermsAndConditions = () => {
  const [termsAndConditions, setTermsAndConditions] = useState({});

  const { enqueueSnackbar } = useSnackbar();

  const fetchPages = async () => {
    let { status, message, data } = await get(PAGES);
    if (status) {
      const termsData = data?.find(
        (item) => item?.pageType === "term_condition"
      );

      setTermsAndConditions({
        ...termsAndConditions,
        title: termsData?.title || "Terms & Conditions",
        pageType: termsData?.pageType || "term_condition",
        description: termsData?.description || "",
      });
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const allPagesQuery = useQuery(queryNames.PAGE, fetchPages, {
    refetchOnWindowFocus: false,
  });

  if (allPagesQuery.isFetching) {
    return <Loader />;
  }

  return (
    <Box sx={styles.mainWrapper}>
      <PageTitle title="Terms & Conditions" />
      <Box sx={styles.wrapper}>
        <Typography
          dangerouslySetInnerHTML={{ __html: termsAndConditions?.description }}
        />
      </Box>
    </Box>
  );
};

export default PublicTermsAndConditions;
