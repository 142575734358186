const styles = {
  btn: {
    px: 2,
    height: "50px",
    whiteSpace: "nowrap",
    minWidth: 150,
    textTransform: "capitalize",
    fontWeight: 600,
    borderRadius: "10px",
  },
  addMemberBtn: {
    bgcolor: "#E8EEF5",
    color: "#135099",
  },
  addTeamBtn: {
    bgcolor: "#135099",
    color: "#fff",
  },

  gridWrapper: {
    display: "flex",
    justifyContent: { sm: "flex-start", xs: "center" },
    flexWrap: "wrap",
    gap: "30px",
    pt: 2,
    // borderTop: "1px solid #EBEBEE",
    // marginTop: "20px",
    // py: "20px",
  },
  gridViewBorderWrapper: {
    borderTop: "1px solid #EBEBEE",
    marginTop: "20px",
    py: "20px",
  },
  totalMemberWrapper: {
    borderTop: "1px solid #EBEBEE",
    marginTop: "20px",
    pt: "20px",
    "& p": {
      color: "#4B4B4B",
      fontSize: 16,
    },
  },
  card: {
    position: "relative",
    background: "#fff",
    cursor: "pointer",
    borderRadius: 4,
    width: 200,
    minHeight: 190,
    px: 1,
    textAlign: "center",
    "& h3": {
      color: "#000",
      fontSize: 16,
      fontWeight: 400,
      textAlign: "center",
      mt: 1,
      maxWidth: 180,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& p": {
      color: "#4B4B4B",
      textAlign: "center",
      fontSize: 14,
      wordBreak: "break-word",
    },
  },
  menuBtn: {
    position: "absolute",
    top: 15,
    right: 15,
    p: "5px",
    background: "rgba(255, 255, 255, 0.3)",
    backdropFilter: "blur(20px)",
    "& svg": {
      width: 20,
      height: 20,
    },
  },
  menu: {
    overflow: "visible",
    mt: 1,
    borderRadius: "10px",
    minWidth: 120,
    boxShadow: " 0 2px 2px rgba(0,0,0,0.3)",
  },
  coverImgWrapper: {
    height: 140,
    borderRadius: "16px 16px 0px 0px",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "16px 16px 0px 0px",
    },
  },
  coverImgSkeleton: {
    height: 140,
    borderRadius: "16px 16px 0px 0px",
    bgcolor: "#eee",
  },
  profileWrapper: {
    textAlign: "center",
    mt: 3,
    "& img": {
      //   width: "100%",
      //   height: "100%",
      width: 70,
      height: 70,
      objectFit: "cover",
      border: "1px solid #eee",
      borderRadius: "50%",
    },
    "& svg": {
      width: "100%",
      height: 70,
    },
  },
  orderPrintBtn: {
    mt: 2,
    mb: 3,
    bgcolor: "#135099",
    textTransform: "capitalize",
    fontSize: "12px",
    fontWeight: 600,
    width: 160,
    height: 36,
    borderRadius: 3,
    "&:hover": {
      bgcolor: "#E8EEF5",
      color: "#135099",
    },
  },
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // minHeight: "calc(100vh - 64px)",

    // width: "100%",
    mt: 5,
    "& h1": {
      fontSize: 24,
      color: "#000",
      fontWeight: 500,
    },
  },
  addBtn: {
    bgcolor: "#135099",
    color: "#fff",
  },
  removeBtn: {
    mt: 3,
    bgcolor: "#E8EEF5",
    color: "#000000",
    "&:hover": {
      bgcolor: "#E8EEF5",
    },
  },
  noCard: {
    position: "absolute",
    top: 15,
    left: 15,
    background: "rgba(255, 255, 255, 0.3)",
    backdropFilter: "blur(20px)",
    fill: "#5e5e5e",
    cursor: "pointer",
  },
};
export default styles;
