import React from "react";
import DoneIcon from "@mui/icons-material/Done";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

export const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 15,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#0F4590",
      transition: "background-color 0.3s ease", // Add a smooth transition
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#0F4590",
      transition: "background-color 0.3s ease", // Add a smooth transition
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#EBEBEE",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#F8F8F8",
  border: "1px solid #EBEBEE",
  zIndex: 1,
  color: "#fff",
  width: 40,
  height: 40,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  "& path": {
    fill: "#0F4590",
  },
  ...(ownerState.active && {
    backgroundColor: "#0F4590",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    "& path": {
      fill: "#FFF",
    },
  }),
  ...(ownerState.completed && {
    backgroundColor: "#0F4590",
    "& path": {
      fill: "#FFF",
    },
  }),
}));

export const ColorlibStepIcon = (props) => {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {active || completed ? <DoneIcon /> : null}
    </ColorlibStepIconRoot>
  );
};
