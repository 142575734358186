import { useSnackbar } from "notistack";

const useValidateImageSize = () => {
  const { enqueueSnackbar } = useSnackbar();

  const validateImageSize = (file) => {
    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB

    if (file.size > maxSizeInBytes) {
      enqueueSnackbar(
        "The selected image size exceeds the maximum limit of 10MB.",
        { variant: "error" }
      );
      return false;
    }

    return true;
  };

  return { validateImageSize };
};

export default useValidateImageSize;
