import { useState } from "react";
import PageTitle from "../../../components/common/PageTitle";
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import FormInput from "../../../components/forms/FormInput";
import CkEditor from "../../../components/forms/CkEditor";
import LoadingButton from "../../../components/button/LoadingButton";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { validator } from "../../../utils/helpers/validator";
import { CategorySchema } from "../../../utils/schemas/categorySchema";
import { useMutation, useQuery } from "react-query";
import { get, post } from "../../../server";
import {
  CATEGORY_CREATE,
  CATEGORY_DETAIL,
  CATEGORY_UPDATE,
} from "../../../data/constants/apiRoutes";
import routes from "../../../data/constants/routes";
import queryNames from "../../../data/constants/queryNames";
import LoadingOverlay from "../../../components/overlay/LoadingOverlay";

const styles = {
  container: {
    py: "30px",
    px: 5,
    bgcolor: "#fff",
    borderRadius: "20px",
  },
  saveBtn: {
    bgcolor: "#135099",
    color: "#fff",
    borderRadius: "10px",
    textTransform: "capitalize",
    fontSize: 16,
    fontWeight: 500,
    width: 200,
    mt: 3,
    height: 50,
    "&:hover": {
      bgcolor: "#135099",
    },
  },
  radioGroup: {
    flexDirection: "row",
    mt: "30px",
    gap: 3,
  },
  characters: {
    color: "#B4B1B6",
    textAlign: "end",
    mt: 1,
  },
};

const initialState = {
  name: "",
  description: "",
  is_show: 1,
};

const AddEditCategory = () => {
  const [category, setCategory] = useState(initialState);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { categoryId } = useParams();

  const [errors, setErrors] = useState({});

  const isId = categoryId !== null && typeof categoryId !== "undefined";

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategory({
      ...category,
      [name]: value,
    });
  };

  const handleDescriptionChange = (data) => {
    setCategory((prevProduct) => ({
      ...prevProduct,
      description: data,
    }));
  };

  const handlePublishedChange = (e) => {
    const published = e.target.value === "true"; // Convert the string to a boolean
    setCategory({
      ...category,
      is_show: published ? 1 : 0,
    });
  };

  const validateForm = () => {
    const newErrors = validator(category, CategorySchema);

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) return true;
    return false;
  };

  const fetchCategory = async () => {
    const { status, message, data } = await get(
      `${CATEGORY_DETAIL}/${categoryId}`
    );
    if (status === true) {
      setCategory({
        ...data,
        is_show: data.isShow,
        description: data.description || "",
      });
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const categoryQuery = useQuery(
    [queryNames.CATEGORIES, categoryId],
    fetchCategory,
    {
      enabled: isId,
      refetchOnWindowFocus: false,
    }
  );

  const handleSaveCategory = async () => {
    const { status, message, data } = await post(
      isId ? `${CATEGORY_UPDATE}/${categoryId}` : CATEGORY_CREATE,
      category
    );
    if (status === true) {
      enqueueSnackbar(message, { variant: "success" });
      navigate(routes.ADMIN_CATEGORY);
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const mutation = useMutation(handleSaveCategory);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    mutation.mutate();
  };

  return (
    <>
      <PageTitle title={isId ? "Edit Category" : "Add Category"} />

      <LoadingOverlay loading={categoryQuery.isLoading} />

      <Box sx={styles.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <FormInput
              label="Category Name"
              name="name"
              placeholder="Category name"
              value={category.name}
              onChange={handleChange}
              error={errors.name}
              helperText={errors.name || ""}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <Box>
              <CkEditor
                label="Description"
                placeholder="Description"
                value={category.description}
                name="description"
                handleChange={handleDescriptionChange}
                error={errors.description}
              />
              <Typography
                sx={styles.characters}
              >{`${category.description.length}/4000`}</Typography>
            </Box>
          </Grid>
        </Grid>

        <RadioGroup
          name="published"
          value={category.is_show ? "true" : "false"}
          onChange={handlePublishedChange}
          sx={styles.radioGroup}
        >
          <FormControlLabel
            value="true"
            control={<Radio />}
            label="Published"
          />
          <FormControlLabel value="false" control={<Radio />} label="Hidden" />
        </RadioGroup>
      </Box>

      <LoadingButton
        variant="contained"
        sx={styles.saveBtn}
        onClick={handleSubmit}
        isLoading={mutation.isLoading}
      >
        Save Category
      </LoadingButton>
    </>
  );
};

export default AddEditCategory;
