import { useState } from "react";

import { Avatar, Stack, Tooltip, Typography, IconButton } from "@mui/material";
import PageTitle from "../../../components/common/PageTitle";

import {
  TEAM_LISTING,
  TEAM_CHANGE_STATUS,
} from "../../../data/constants/apiRoutes";
import { get, put } from "../../../server";
import queryNames from "../../../data/constants/queryNames";
import DataGridListing from "../../../components/listing/DataGridListing";
import { Edit, RemoveRedEyeOutlined } from "@mui/icons-material";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import routes from "../../../data/constants/routes";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import BoxModal from "../../../components/modal/BoxModal";

const styles = {
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { md: 360, xs: 310 },
    // height: 280,
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 15,
    p: 2,
    "& button": {
      width: 120,
      boxShadow: "none",
      fontWeight: 600,
    },
  },
  iconBox: {
    width: 60,
    height: 60,
    borderRadius: "50%",
    textAlign: "center",
    border: "3px solid #E53935",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  crossIcon: {
    width: 40,
    height: 40,
    color: "#E53935",
  },
  closeIconBtnWrapper: {
    textAlign: "end",
  },
  closeIconBtn: {
    textAlign: "end",
    width: "40px !important",
    height: 40,
  },
  headerText: {
    fontSize: 18,
    fontWeight: 600,
    flexGrow: 1,
    textAlign: "center",
    color: "#636363",
  },
  confirmTitle: {
    color: "#838383",
    marginInline: "10px",
    fontSize: 14,
  },
  cancelBtn: {
    bgcolor: "#E53935",
    color: "#ffffff",
    height: 45,
    textTransform: "capitalize",
    "&:hover": { bgcolor: "#E53935" },
  },
  confirmBtn: {
    bgcolor: "#D3A23B",
    color: "#ffffff",
    height: 45,
    textTransform: "capitalize",
    width: "100%",
    "&:hover": {
      bgcolor: "#D3A23B",
    },
  },

  btnsWrapper: {
    mt: 4,
    mb: 2,
  },
  title: {
    textTransform: "capitalize",
  },
  activeIcon: {
    fill: "#43b055",
  },
  deactiveIcon: {
    fill: "#edbf04",
  },
};

const TeamsListing = () => {
  const [isSearch, setIsSearch] = useState(false);
  const [isHandleChangeSearch, setIsHandleChangeSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [searchedValue, setSearchedValue] = useState("");
  const [id, setId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const client = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setIsHandleChangeSearch(true);
    if (e.target.value.length < 1) {
      setIsHandleChangeSearch(false);
      setIsSearch(false);
      setSearchedValue("");
    }
  };

  const handleSearchClick = () => {
    setIsSearch(true);
    setSearchedValue(search);
  };

  const handleClearSearch = () => {
    setIsSearch(false);
    setSearch("");
    setSearchedValue("");
    setIsHandleChangeSearch(false);
  };

  const handleStatusChange = (id, status) => {
    setShowModal(status);
    setId(id);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setId(null);
  };

  const fetchOrders = async (pageNo, limit, searchParam, isSearchParam) => {
    let url;

    url = new URL(TEAM_LISTING);
    url.searchParams.append("page", pageNo + 1);
    url.searchParams.append("limit", limit);
    if (isSearch) {
      url.searchParams.append("search", search);
    }

    return await get(url.toString());
  };

  const changeStatus = async () => {
    const { status, message } = await put(`${TEAM_CHANGE_STATUS}/${id}`, {
      status: showModal,
    });

    if (status) {
      enqueueSnackbar(message, { variant: "success" });

      handleCloseModal();
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const statusMutation = useMutation(changeStatus, {
    onSuccess: () => {
      client.invalidateQueries([queryNames.ADMIN_TEAMS]);
    },
  });

  const handleConfirm = () => {
    statusMutation.mutate();
  };

  const columns = [
    {
      field: "logo",
      headerName: "Team Logo",
      sortable: false,

      renderCell: (params) => {
        return (
          <>
            <Avatar
              src={params.row.logo}
              sx={{ mr: 1.5, width: 40, height: 40 }}
            >
              <Typography sx={styles.title}>
                {params?.row?.name?.charAt(0)}
              </Typography>
            </Avatar>
          </>
        );
      },
    },

    {
      field: "name",
      headerName: "Team Name",
      flex: 1,
      renderCell: (params) => {
        return <Typography sx={styles.title}>{params?.row?.name}</Typography>;
      },
    },
    {
      field: "teamOwneName",
      headerName: "Team Owner",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography sx={styles.title}>
            {params?.row?.ownerData &&
              `${params?.row?.ownerData?.first_name} ${params?.row?.ownerData?.last_name}`}
          </Typography>
        );
      },
    },
    {
      field: "teamOwneEmail",
      headerName: "Team Owner Email",
      flex: 1,
      valueGetter: (params) =>
        params?.row?.ownerData && params?.row?.ownerData?.email,
    },

    {
      field: "members",
      headerName: "Members",

      valueGetter: (params) =>
        params?.row?.members && params?.row?.members?.length + 1,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      flex: 1,
      valueGetter: (params) =>
        params?.row?.status == "active" ? "Active" : "Inactive",
    },
    {
      field: "action",
      headerName: "Action",
      width: 160,
      sortable: false,

      renderCell: (params) => {
        return (
          <>
            <Stack direction="row" alignItems="center" gap={1}>
              <Tooltip title="Edit Team" placement="top">
                <IconButton
                  disableRipple
                  onClick={() =>
                    navigate(`${routes.ADMIN_EDIT_TEAM}/${params?.row?.id}`)
                  }
                  size="small"
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip title="View Detail" placement="top">
                <IconButton
                  disableRipple
                  size="small"
                  onClick={() =>
                    navigate(`${routes.ADMIN_TEAM_DETAIL}/${params?.row?.id}`)
                  }
                >
                  <RemoveRedEyeOutlined />
                </IconButton>
              </Tooltip>
              {params?.row?.status === "active" ? (
                <Tooltip title="Deactivate" placement="top">
                  <IconButton
                    disableRipple
                    onClick={() =>
                      handleStatusChange(params.row.id, "inactive")
                    }
                    size="small"
                  >
                    <ToggleOffIcon sx={styles.deactiveIcon} />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Activate" placement="top">
                  <IconButton
                    disableRipple
                    onClick={() => handleStatusChange(params.row.id, "active")}
                    size="small"
                  >
                    <ToggleOnIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </>
        );
      },
    },
  ];
  return (
    <>
      <PageTitle
        title="Teams"
        searchInput
        placeholder="Search..."
        search={search}
        handleSearchChange={handleSearchChange}
        isSearch={isSearch}
        handleClearSearch={handleClearSearch}
        handleSearchClick={handleSearchClick}
        isHandleChangeSearch={isHandleChangeSearch}
      />
      <DataGridListing
        columns={columns}
        fetchRecords={fetchOrders}
        queryName={queryNames.ADMIN_TEAMS}
        search={searchedValue}
        isSearch={isSearch}
      />
      <BoxModal
        header="Are you sure?"
        title={
          showModal === "delete"
            ? "Do you really want to delete this record. This process cannot be undone."
            : showModal === "active"
            ? "Do you really want to activate this record."
            : "Do you really want to deactivate this record."
        }
        open={showModal === "active" ? "confirm" : showModal}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirm}
        confirmButton={showModal === "delete" ? "Delete" : "Confirm"}
        confirmButtonColor={showModal == "active" ? "#43a047" : "#E53935"}
        isLoading={statusMutation.isLoading}
      />
    </>
  );
};

export default TeamsListing;
