import React, { useState } from "react";
import SplashForm from "../../../components/auth/SplashForm";
import routes from "../../../data/constants/routes";
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import EmailSVG from "../../../assets/svg/Email.svg"

const VerifyEmail = () => {
	return (
		<>
			<SplashForm
				title="Verify your Email"
				btnText="Verify your Email"
                tagline="Please check your email for a link to verify your email address"
                subTagline="Once verified, you’ll be able to continue."
				linkTaglinePlainText="Don’t receive an email? "
				linkTaglineText="Resend"
				// linkTaglineHref={routes.SIGNUP}
                showImage={true}
                image={EmailSVG}
				// handleSubmit={handleSubmit}
				// isLoading={mutation.isLoading}
			/>
		</>
	);
};

export default VerifyEmail;
