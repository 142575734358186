const styles = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    "& h1": {
        textAlign: "center",
        color: "#656DFC"
    },
    "& h2": {
        textAlign: "center"
    },
    "& h4": {
        color: "#727070"
    },
    "& button": {
        contentAlign: "center",
        mt: 2,
        width: 200,
        height: 50,
        fontSize: 18,
        fontWeight: 600
    }
}

export default styles;
